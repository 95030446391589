import React, { Component } from "react";
import {
  createProject,
  cutpieceToSegements,
  exportResultForWastePackages,
  getProject,
  getProjects,
  removeCutpiece,
  removeCutpieces,
  removeSegment,
  removeSegments,
  removeWastePackage,
  removeWastePackages,
  updateProjectSettings,
  wastepackageToSegments,
  wastepackageToSegmentsZIP,
} from "../api";
import { LoadingContext } from "../context/LoadingContext";
import { NotificationContext } from "../context/NotificationContext";
import { ProjectContext } from "../context/ProjectContext";
import { validate } from "../Utility/ResponseConsumer";
import ProjectHome from "./ProjectHome";
import ProjectManager from "./ProjectManager";
import JSZip from "jszip";
import FileSaver from "file-saver";
class ProjectView extends Component {
  static contextType = NotificationContext;
  constructor(props) {
    super(props);
    this.fetchProject = this.fetchProject.bind(this);
    this.deselectProject = this.deselectProject.bind(this);
    this.refreshCurrentProject = this.refreshCurrentProject.bind(this);
    this.fetchProjects = this.fetchProjects.bind(this);
    this.removeWastePackage = this.removeWastePackage.bind(this);
    this.removeWastePackages = this.removeWastePackages.bind(this);
    this.removeCutpiece = this.removeCutpiece.bind(this);
    this.removeCutpieces = this.removeCutpieces.bind(this);
    this.removeSegment = this.removeSegment.bind(this);
    this.removeSegments = this.removeSegments.bind(this);
    this.createProject = this.createProject.bind(this);
    this.exportCutpieceSegments = this.exportCutpieceSegments.bind(this);
    this.exportWastepackageSegments = this.exportWastepackageSegments.bind(this);
    this.exportWastePackageResult = this.exportWastePackageResult.bind(this);
    this.updateProjectSettings = this.updateProjectSettings.bind(this);
    this.exportWastepackageSegmentsAsZip = this.exportWastepackageSegmentsAsZip.bind(this);
  }
  state = { selectedProject: null, error: false };
  componentDidMount() {
    if (!this.state.error) {
      this.fetchProjects();
    }
  }
  refreshCurrentProject(project) {
    this.setState({ selectedProject: project });
  }
  deselectProject() {
    this.fetchProjects(() => this.setState({ selectedProject: null }));
  }
  fetchProject(id, callback) {
    getProject(id, (status, body) => {
      if (status === 200) {
        this.setState({ selectedProject: body });

        callback();
      } else {
        if (this.props.notification) {
          this.props.notification.add("Fehler beim Laden des Projektes!", true);
          callback();
        }
      }
    });
  }
  fetchProjects(callback) {
    getProjects((status, body) => {
      if (status === 200) {
        this.setState({ projects: body }, callback);
      } else {
        if (this.props.notification) {
          this.props.notification.add("Fehler beim Laden der Projekte!", true);
        }
      }
    });
  }
  removeWastePackage(id) {
    removeWastePackage(this.state.selectedProject.id, id, (status, body) => {
      if (status === 200) {
        this.setState({ selectedProject: body });
      } else {
        this.context.add(body.message, true);
      }
    });
  }
  removeWastePackages(ids) {
    removeWastePackages(this.state.selectedProject.id, ids, (status, body) => {
      if (status === 200) {
        this.setState({ selectedProject: body });

        this.context.add("Abfallgebinde erfolgreich gelöscht");
      } else {
        this.context.add(body.message, true);
      }
    });
  }
  removeCutpiece(id) {
    removeCutpiece(this.state.selectedProject.id, id, (status, body) => {
      if (status === 200) {
        this.setState({ selectedProject: body });
      } else {
        this.context.add(body.message, true);
      }
    });
  }
  removeCutpieces(ids) {
    removeCutpieces(this.state.selectedProject.id, ids, (status, body) => {
      if (status === 200) {
        this.setState({ selectedProject: body });
        this.context.add("Schnittstücke erfolgreich gelöscht");
      } else {
        this.context.add(body.message, true);
      }
    });
  }
  removeSegment(id) {
    removeSegment(this.state.selectedProject.id, id, (status, body) => {
      if (status === 200) {
        this.setState({ selectedProject: body });
      } else {
        this.context.add(body.message, true);
      }
    });
  }
  removeSegments(ids) {
    removeSegments(this.state.selectedProject.id, ids, (status, body) => {
      if (status === 200) {
        this.setState({ selectedProject: body });

        this.context.add("Segmente erfolgreich gelöscht");
      } else {
        this.context.add(body.message, true);
      }
    });
  }
  exportCutpieceSegments(cps) {
    cutpieceToSegements(
      this.state.selectedProject.id,
      cps,
      validate(
        (x) => {
          let csvContent = "data:text/csv;charset=utf-8," + x;

          var encodedUri = encodeURI(csvContent);
          var link = document.createElement("a");
          link.setAttribute("href", encodedUri);
          link.setAttribute("download", "Segmente_Schnittstuecke.csv");
          document.body.appendChild(link); // Required for FF
          link.click(); // This will download the data file named "my_data.csv".
        },
        (error) => this.context.add(error.message, true),
      ),
    );
  }
  exportWastepackageSegments(wps, withoutHypen) {
    wastepackageToSegments(
      this.state.selectedProject.id,
      wps,
      withoutHypen,
      validate(
        (x) => {
          let csvContent = "data:text/csv;charset=utf-8," + x;

          var encodedUri = encodeURI(csvContent);
          var link = document.createElement("a");
          link.setAttribute("href", encodedUri);
          link.setAttribute("download", "Segmente_Abfallgebinde.csv");
          document.body.appendChild(link); // Required for FF
          link.click(); // This will download the data file named "my_data.csv".
        },
        (error) => this.context.add(error.message, true),
      ),
    );
  }
  exportWastepackageSegmentsAsZip(wps, withoutHypen) {
    wastepackageToSegmentsZIP(
      this.state.selectedProject.id,
      wps,
      withoutHypen,
      validate(
        (x) => {
          const zip = new JSZip();
          for (let y of Object.keys(x)) {
            zip.file(y + ".csv", x[y]);
          }
          zip.generateAsync({ type: "blob" }).then(function (content) {
            FileSaver.saveAs(content, "Abfallgebinde_Segmentexport.zip");
          });
        },
        (error) => this.context.add(error.message, true),
      ),
    );
  }
  exportWastePackageResult(wps, cutoff, exportWithoutHyphen) {
    exportResultForWastePackages(
      this.state.selectedProject.id,
      wps,
      cutoff,
      exportWithoutHyphen,
      validate(
        (x) => {
          const zip = new JSZip();
          for (let y of Object.keys(x)) {
            zip.file(y + ".csv", x[y]);
          }
          zip.generateAsync({ type: "blob" }).then(function (content) {
            FileSaver.saveAs(content, "Ergebnisse.zip");
          });
        },
        (error) => this.context.add(error.message, true),
      ),
    );
  }
  createProject(name, desc) {
    createProject(
      name,
      desc,
      validate(
        (body) => {
          this.state.projects.push(body);
          this.setState({ selectedProject: body });
        },
        (error) => {
          this.context.add(error.message, true);
        },
      ),
    );
  }
  updateProjectSettings(id, settings, callback = () => {}) {
    updateProjectSettings(
      id,
      settings,
      validate(
        (body) => {
          this.setState({ selectedProject: body });
          callback();
        },
        (error) => {
          this.context.add(error.message, true);
          callback();
        },
      ),
    );
  }
  render() {
    let component;
    if (this.state.selectedProject) {
      component = <ProjectHome></ProjectHome>;
    } else {
      component = (
        <>
          <LoadingContext.Consumer>
            {(ctx) => (
              <ProjectManager
                setSelected={(id) => {
                  ctx.toggle();
                  this.fetchProject(id, ctx.toggle);
                }}
              ></ProjectManager>
            )}
          </LoadingContext.Consumer>
        </>
      );
    }
    return (
      <ProjectContext.Provider
        value={{
          projects: this.state.projects,
          project: this.state.selectedProject,
          create: this.createProject,
          refresh: this.refreshCurrentProject,
          deselect: this.deselectProject,
          removeWastePackage: this.removeWastePackage,
          removeWastePackages: this.removeWastePackages,
          removeCutpiece: this.removeCutpiece,
          removeCutpieces: this.removeCutpieces,
          removeSegment: this.removeSegment,
          removeSegments: this.removeSegments,
          exportCutpieceSegments: this.exportCutpieceSegments,
          exportWastepackageSegments: this.exportWastepackageSegments,
          exportWastePackageResult: this.exportWastePackageResult,
          updateProjectSettings: this.updateProjectSettings,
          exportWastepackageSegmentsAsZip: this.exportWastepackageSegmentsAsZip,
        }}
      >
        {component}
      </ProjectContext.Provider>
    );
  }
}

export default ProjectView;
