import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { ConfirmDialogContext } from "../context/ConfirmDialogContext";
import { ProjectContext } from "../context/ProjectContext";
import SButton from "../styledComponents/SButton";
import Expandable from "../Utility/Expandable";
import { getTable10Nuclides } from "../api";
import { validate } from "../Utility/ResponseConsumer";
import { calcSumForTable10, calcSumCriticality, calcSumExpo, calcSumThermal, isTable10Nuklid, specialCalcSumExpo, specialCalcSumThermal } from "../Utility/Utility";
import WastePackageWarning from "./WastePackageWarning";

class WastePackageDetailView extends Component {
  static contextType = ProjectContext;
  constructor(props) {
    super(props);
    this.state = {
      table10: [],allActivities: []
    }
    

    this.hyphenRef = React.createRef();
    this.exportData = this.exportData.bind(this);
  }

  state = {};

  exportData() {
    if (this.props.container.activities.nuclideActivities) {
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += "Nuklid;Aktivitaet [Bq];Aktivitaet [Bq/kg]\r\n";
      this.props.container.activities.nuclideActivities.forEach((x) => {
        let nuclide = x.nuclide;
        if (this.hyphenRef.current.checked) {
          nuclide = nuclide.replace("-", "");
        }
        let row = [nuclide, x.value.getPretty(2), (x.value / this.props.container.mass).getPretty(2)].join(";");
        csvContent += row + "\r\n";
      });
      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", this.props.container.name + "_Aktivitaeten.csv");
      document.body.appendChild(link); // Required for FF
      link.click(); // This will download the data file named "my_data.csv".
    }
  }

  componentDidMount() {
    this.createNewActivitieList()
  }


  createNewActivitieList() {
    console.log(this.props.container)

    const combinedList = [];
 
    for (let item of this.props.container.activities.nuclideActivities) {
      
      const nuclide = item.nuclide;
      
      const value = item.value
     
      const valueFromList2 = this.props.container.activitiesMass.nuclideActivities.find(element => element.nuclide === nuclide);
      const valueFromList3 = undefined;
      if (!valueFromList2 && !valueFromList3){
        combinedList.push({
          nuclide: nuclide,
          value: 0,
          mass: 0,
          surface: 0
        });
      }
      else if (valueFromList2 && !valueFromList3) {
        combinedList.push({
          nuclide: nuclide,
          value: value,
          mass: valueFromList2.value,
          surface: 0
        });
      }
      else if (!valueFromList2 && valueFromList3) {
        combinedList.push({
          nuclide: nuclide,
          value: value,
          mass: 0,
          surface: valueFromList3.value
        });
      }
      else if (valueFromList2 && valueFromList3) {
        combinedList.push({
          nuclide: nuclide,
          value: value,
          mass: valueFromList2.value,
          surface: valueFromList3.value
        });
      }
    }
    
    this.setState({allActivities: combinedList})

      
  }
  render() {
   
    const warningComponent1 = <WastePackageWarning type={3} />


    return (
      <div className="p-2 flex flex-col gap-2">
        <h1 className="font-bold text-lg">{this.props.container ? this.props.container.name : ""}</h1>
        <div className="flex justify-between">
          <SButton onClick={this.props.goBack} className="w-fit">
            Zurück
          </SButton>
          <ConfirmDialogContext.Consumer>
            {(ctx) => (
              <SButton
                onClick={() => {
                  ctx.toggle(
                    "Wollen Sie das Abfallgebinde " + this.props.container.name + " unwiederruflich löschen?",
                    () => {
                      this.context.removeWastePackage(this.props.container.id);
                      ctx.toggle();
                      this.props.goBack();
                    },
                    () => {
                      ctx.toggle();
                    },
                  );
                }}
                className="w-fit"
              >
                <FontAwesomeIcon size="lg" className="text-red-600 p-1" icon={faTrash}></FontAwesomeIcon>
              </SButton>
            )}
          </ConfirmDialogContext.Consumer>
        </div>
        <div>
          {this.props.container.error ? (
            <p className="bg-yellow-200 w-fit p-2 rounded-lg border border-black font-semibold">
              {this.props.container.error ? this.props.container.error.errMsg : null}
            </p>
          ) : null}
          {this.props.container.incidentError ? (
            <p className="bg-yellow-200 w-fit p-2 rounded-lg border border-black font-semibold">
              {this.props.container.incidentError ? this.props.container.incidentError.errMsg : null}
            </p>
          ) : null}
          {this.props.container.thermalInfluenceError ? (
            <p className="bg-yellow-200 w-fit p-2 rounded-lg border border-black font-semibold">
              {this.props.container.thermalInfluenceError ? this.props.container.thermalInfluenceError.errMsg : null}
            </p>
          ) : null}{" "}
          {this.props.container.criticalError ? (
            <p className="bg-yellow-200 w-fit p-2 rounded-lg border border-black font-semibold">
              {this.props.container.criticalError ? this.props.container.criticalError.errMsg : null}
            </p>
          ) : null}
          {this.props.container.guranteedError ? (
            <p className="bg-yellow-200 w-fit p-2 rounded-lg border border-black font-semibold">
              {this.props.container.guranteedError ? this.props.container.guranteedError.errMsg : null}
            </p>
          ) : null}
        </div>
        <table className="d-table">
          <tbody className="d-tbody">
            <tr className="d-tr">
              <td className="d-td font-semibold text-left pl-5">ID-Abfallgebinde</td>
              <td className="d-td ">{this.props.container.name}</td>
            </tr>
            <tr className="d-tr">
              <td className="d-td font-semibold text-left pl-5">Abfallbehälterklasse</td>
              <td className="d-td">{this.props.container.container.wasteContainerClass}</td>
            </tr>
            <tr className="d-tr">
              <td className="d-td font-semibold text-left pl-5">Abfallproduktgruppe</td>
              <td className="d-td">{this.props.container.wasteGroup}</td>
            </tr>
            <tr className="d-tr">
              <td className="d-td font-semibold text-left pl-5">störfallfest</td>
              <td className="d-td ">{this.props.container.failureresistant ? "Ja" : "Nein"}</td>
            </tr>
            <tr className="d-tr">
              <td className="d-td font-semibold text-left pl-5">Abfallmasse [kg]</td>
              <td className="d-td italic">{this.props.container.mass.getPretty(3)}</td>
            </tr>
            <tr className="d-tr">
              <td className="d-td font-semibold text-left pl-5">Abfalloberfläche [cm²]</td>
              <td className="d-td italic">{this.props.container.surface.getPretty(3)}</td>
            </tr>
            <tr className="d-tr">
              <td className="d-td font-semibold text-left pl-5">Störfallsummenwert</td>
              <td className="d-td italic">{this.props.container.incidentValues ? calcSumExpo(this.props.container, 0, this.props.table10.table10).getPretty(3) : "---"}</td>
            </tr>
            <tr className="d-tr">
              <td className="d-td font-semibold text-left pl-5">Störfallsummenwert mit Abschneidekriterium</td>
              <td className="d-td italic">
                {this.props.container.incidentValues
                  ? calcSumExpo(this.props.container, this.props.container.failureresistant ? 0.1 : 0.01, this.props.table10.table10).getPretty(3)
                  : "---"}
              </td>
            </tr>
            <tr className="d-tr">
              <td className="d-td font-semibold text-left pl-5">Ausschöpfung Störfallsummenwert</td>
              <td className="d-td italic">
                {this.props.container.incidentValues
                  ? (calcSumExpo(this.props.container) * (this.props.container.failureresistant ? 1 : 10)).getPretty(3)
                  : "---"}
              </td>
            </tr>
            <tr className="d-tr">
              <td className="d-td font-semibold text-left pl-5">Summenwert thermische Beeinflussung</td>
              <td className="d-td italic">{this.props.container.thermalValues ? calcSumThermal(this.props.container, 0, this.props.table10.table10).getPretty(3) : "---"}</td>
            </tr>
            <tr className="d-tr">
              <td className="d-td font-semibold text-left pl-5">Summenwert thermische Beeinflussung mit Abschneidekriterium</td>
              <td className="d-td italic">
                {this.props.container.thermalValues
                  ? calcSumThermal(this.props.container, this.props.container.failureresistant ? 0.1 : 0.01, this.props.table10.table10).getPretty(3)
                  : "---"}
              </td>
            </tr>
            <tr className="d-tr">
              <td className="d-td font-semibold text-left pl-5">Summenwert Kritikalität</td>
              <td className="d-td italic">{this.props.container.criticalityValues ? calcSumCriticality(this.props.container).getPretty(3) : "---"}</td>
            </tr>
            <tr className="d-tr">
              <td className="d-td font-semibold text-left pl-5">Summenwert Kritikalität mit Abschneidekriterium</td>
              <td className="d-td italic">
                {this.props.container.criticalityValues
                  ? calcSumCriticality(this.props.container, this.props.container.failureresistant ? 0.1 : 0.01).getPretty(3)
                  : "---"}
              </td>
            </tr>
            <tr className="d-tr">
              <td className="d-td font-semibold text-left pl-5">Gesamtaktivität [Bq]</td>
              <td className="d-td italic">{this.props.container.activities ? this.props.container.activities.sumAct.getPretty(2) : "---"}</td>
            </tr>
          </tbody>
        </table>
        <Expandable className="bg-gray-200" text={<p className="font-semibold p-2">nuklidspezif. Aktivitäten</p>}>
          <div className="p-2 flex gap-1">
            <SButton onClick={this.exportData}>Daten exportieren</SButton>
            <div className="flex gap-1">
              <input ref={this.hyphenRef} type="checkbox" />
              <label>Nuklide ohne Bindestrich</label>
            </div>
          </div>

          <table className="d-table">
            <thead className="d-thead">
              <tr className="d-tr">
                <th className="d-th">Nuklid</th>
                <th className="d-th">Aktivität [Bq]</th>
                <th className="d-th">Aktivität [Bq/kg]</th>
                <th className="d-th">Aktivität [Bq/cm²]</th>
              </tr>
            </thead>
            <tbody className="d-tbody">
              {this.state.allActivities.lengt !== 0 ?
                 this.state.allActivities.map((x) => {

                  return (
                    <tr key={x.nuclide} className="d-tr">
                      <td className="d-td">{x.nuclide}</td>
                      <td className="d-td italic">{x.value === 0 ? "---" :x.value.getPretty(2)}</td>
                      <td className="d-td italic">{x.mass === 0 ? "---" :x.mass.getPretty(2)}</td>
                      <td className="d-td italic">{x.surface === 0 ? "---" :x.surface.getPretty(2)}</td>
                    </tr>
                  );
                })
                : null}
            </tbody>
          </table>
        </Expandable>
        <Expandable text={<p className="font-semibold p-2">Schnittstücke</p>}>
          <table className="d-table">
            <thead>
              <tr className="d-tr">
                <th className="d-th">Schnittstück</th>
                <th className="d-th">Masse [kg]</th>
                <th className="d-th">Oberfläche [cm²]</th>
              </tr>
            </thead>
            <tbody className="d-tbody">
              {this.props.container.cutPieces.map((cp) => {
                let real = this.context.project.cutPieces.find((x) => x.id === cp);
                return (
                  <tr className="d-tr" key={cp}>
                    <td className="d-td ">{real.name}</td>
                    <td className="d-td ">{real.mass.toFixed(2).replaceAll(".", ",")}</td>
                    <td className="d-td ">{real.surface.toFixed(2).replaceAll(".", ",")}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Expandable>
        <Expandable text={<p className="font-semibold p-2">Störfall</p>}>
          <table className="d-table">
            <thead className="d-thead">
              <tr className="d-tr">
                <th className="d-th">Nuklid</th>
                <th className="d-th">Aktivität [Bq]</th>
                <th className="d-th">Ausschöpfung am Störfallsummenwert</th>
              </tr>
            </thead>
            <tbody className="d-tbody">
              {this.props.container.activities
                ? this.props.container.activities.nuclideActivities.map((x) => {
                  return (
                    <tr key={x.nuclide} className="d-tr">

                      <td className={"d-td"}>{x.nuclide}</td>
                      <td className={"d-td italic"}>{x.value.getPretty(2)}</td>
                      <td className={calcSumForTable10(x, this.props.table10.table10, this.props.container.incidentValues[x.nuclide]) > 1 && isTable10Nuklid(x, this.props.table10.table10) ? " bg-red-200 italic justify-center flex flex-row" : " italic justify-center flex flex-row"}><p>
                        {this.props.container.incidentValues && this.props.container.incidentValues[x.nuclide]
                          ? this.props.container.incidentValues[x.nuclide].getPretty(2)
                          : "---"}</p><span>{isTable10Nuklid(x, this.props.table10.table10) && this.props.container.incidentValues && this.props.container.incidentValues[x.nuclide] ? <WastePackageWarning type={3} wert={calcSumForTable10(x, this.props.table10.table10, this.props.container.incidentValues[x.nuclide]).getPretty(2)} /> : null}</span>
                      </td>
                    </tr>
                  );
                })
                : null}
            </tbody>
          </table>
        </Expandable>
        <Expandable text={<p className="font-semibold p-2">Thermische Beeinflussung</p>}>
          <table className="d-table">
            <thead className="d-thead">
              <tr className="d-tr">
                <th className="d-th">Nuklid</th>
                <th className="d-th">Aktivität [Bq]</th>
                <th className="d-th">Ausschöpfung des Aktivitätsgrenzwertes</th>
              </tr>
            </thead>
            <tbody className="d-tbody">
              {this.props.container.activities
                ? this.props.container.activities.nuclideActivities.map((x) => {
                  return (
                    <tr key={x.nuclide} className="d-tr">
                      <td className={"d-td"}>{x.nuclide}</td>
                      <td className={"d-td italic"}>{x.value.getPretty(2)}</td>

                      <td className={calcSumForTable10(x, this.props.table10.table10, this.props.container.thermalValues[x.nuclide]) > 1 && isTable10Nuklid(x, this.props.table10.table10) ? "d-td bg-red-200 italic justify-center flex flex-row" : "d-td italic flex justify-center flex-row"}><p>
                        {this.props.container.thermalValues && this.props.container.thermalValues[x.nuclide]
                          ? this.props.container.thermalValues[x.nuclide].getPretty(2)
                          : "---"}</p><span>{isTable10Nuklid(x, this.props.table10.table10) && this.props.container.thermalValues && this.props.container.thermalValues[x.nuclide] ? <WastePackageWarning type={3} wert={calcSumForTable10(x, this.props.table10.table10, this.props.container.thermalValues[x.nuclide]).getPretty(2)} /> : null}</span>
                      </td>
                    </tr>
                  );
                })
                : null}
            </tbody>
          </table>
        </Expandable>
        <Expandable text={<p className="font-semibold p-2">Kritikalität</p>}>
          <table className="d-table">
            <thead className="d-thead">
              <tr className="d-tr">
                <th className="d-th">Nuklid</th>
                <th className="d-th">Aktivität [Bq]</th>
                <th className="d-th">Ausschöpfung des Aktivitätsgrenzwertes</th>
              </tr>
            </thead>
            <tbody className="d-tbody">
              {this.props.container.criticalityValues
                ? Object.keys(this.props.container.criticalityValues).map((x) => {
                  let obj = this.props.container.activities.nuclideActivities.find((y) => y.nuclide === x);
                  return (
                    <tr key={x} className="d-tr">
                      <td className="d-td">{x}</td>
                      <td className="d-td italic">{obj ? obj.value.getPretty(2) : "---"}</td>
                      <td className="d-td italic">
                        {this.props.container.criticalityValues && this.props.container.criticalityValues[x]
                          ? this.props.container.criticalityValues[x].getPretty(2)
                          : "---"}
                      </td>
                    </tr>
                  );
                })
                : null}
            </tbody>
          </table>
        </Expandable>
        <Expandable text={<p className="font-semibold p-2">Garantiewerte</p>}>
          <table className="d-table">
            <thead className="d-thead">
              <tr className="d-tr">
                <th className="d-th">Nuklid</th>
                <th className="d-th">Aktivität [Bq]</th>
                <th className="d-th">Ausschöpfung des Garantiewertes</th>
              </tr>
            </thead>
            <tbody className="d-tbody">
              {this.props.container.activities
                ? this.props.container.activities.nuclideActivities.map((x) => {
                  if (this.props.container.guranteedValues &&
                    this.props.container.guranteedValues[x.nuclide] &&
                    this.props.container.guranteedValues[x.nuclide].getPretty) {
                    return (
                      <tr key={x.nuclide} className="d-tr">
                        <td className="d-td">{x.nuclide}</td>
                        <td className="d-td italic">{x ? x.value.getPretty(2) : "-"}</td>
                        <td className="d-td italic">
                          {this.props.container.guranteedValues[x.nuclide].getPretty(2)
                          }
                        </td>
                      </tr>
                    );
                  }

                })
                : null}
            </tbody>
          </table>
        </Expandable>
      </div>
    );
  }
}

export default WastePackageDetailView;
