import React, { Component } from "react";
class SettingsHeader extends Component {
  constructor(props) {
    super(props);
  }
  state = {};
  render() {
    return (
      <>
        <h2 className="font-semibold">{this.props.children}</h2>
        <hr className="border-black"></hr>
      </>
    );
  }
}

export default SettingsHeader;
