import { faDownload, faKey, faLock, faLockOpen, faRefresh, faPencil, faUserSecret, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { UserContext } from "../context/UserContext";
import SButton from "../styledComponents/SButton";
import "./adminpanel.css";
import { changeUserState, refreshStatus, getBackup, getBackups, isBackupRunning, resetPassword, restore, startBackup, renameUser, deleteUser, refreshAccesstoken } from "../api";
import { validate } from "../Utility/ResponseConsumer";
import ConfirmDialog from "../utilityComponents/ConfirmDialog";
import Table from "../utilityComponents/Table";
import { getStringCompare, getStringFilter, passwordValid } from "../Utility/Utility";
import { icon } from "@fortawesome/fontawesome-svg-core";
import { calcSumThermal } from './../Utility/Utility';

class AdminPanel extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.refreshStatus = this.refreshStatus.bind(this)
    this.isUserDataComplete = this.isUserDataComplete.bind(this);
    this.createUser = this.createUser.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.refreshStatus = this.refreshStatus.bind(this);
    this.openBackupDialog = this.openBackupDialog.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    this.renameUser = this.renameUser.bind(this);
    this.startBackup = this.startBackup.bind(this);
  }
  state = {
    firstname: "",
    lastname: "",
    username: "",
    password: "",
    showDialog: false,
    resetPassword: "",
    showUserDialog: false,
    newUsername: "",
    tempRole: "",
    tempLocked: false,
    newUsernamePassword: "",
    resetUserId: -1,
    backupStatus: "UNKNOWN",
    showBackupDialog: false,
    backups: [],
    btnDisabled: true,
  };
  componentDidMount() {
    this.refreshStatus();
  }
  createUser(evt) {
    evt.preventDefault();
    let user = { email: this.state.username, password: this.state.password };
    this.context.add(user);
  }
  deleteUser(userid) {
    this.setState({ isModal: false })

    deleteUser(
      userid,
      validate(
        (body) => {
          this.context.toggleUserState(userid);
          if (this.props.notification) {
            this.props.notification.add("Nutzer erfolgreich gelöscht");
            let position = 0
            this.context.user.forEach((element, index) => {
              if (element.id === userid) {
                position = index
              }
            });
            this.context.user.splice(position, position)
          }




        },
        (error) => {
          if (this.props.notification) {
            this.props.notification.add(error.message, true);
          }
        },
      ),
    )
  }
  filterusers(userMap) {

    let admins = []
    let noAdmins = []
    userMap.forEach(user => {
      if (user.role === "ADMIN") {
        admins.push(user)
      } else {
        noAdmins.push(user)
      }
    })

    noAdmins.forEach(user => {
      admins.push(user)
    })


    return admins
  }

  renameUser(evt) {
    evt.preventDefault();

    renameUser(
      this.state.resetUserId,
      this.state.newUsername,
      validate(
        (body) => {
          if (this.props.notification) {
            this.props.notification.add("Nutzername erfolgreich überschrieben");
            let user = { username: this.state.newUsername, id: this.state.resetUserId, locked: this.state.tempLocked, role: this.state.tempRole };
            let position = 0;

            this.context.user.forEach((element, index) => {
              if (element.id === this.state.resetUserId) {
                position = index
              }

            });
            this.context.user[position] = user

          }

          this.setState({ showUserDialog: false, newUserName: "", resetUserId: -1 });

        },
        (error) => {
          if (this.props.notification) {
            this.props.notification.add(error.message, true);
          }
        },
      ),
    )
  }
  openUserDialog(x) {
    this.setState({ resetUserId: x.id, showUserDialog: true, newUsernamePassword: changeUserState.password, tempRole: x.role, tempLocked: x.locked });
  }
  resetPassword() {
    resetPassword(
      this.state.resetUserId,
      this.state.resetPassword,
      validate(
        (body) => {
          if (this.props.notification) {
            this.props.notification.add("Passwort erfolgreich überschrieben");
          }
          this.setState({ showDialog: false, resetPassword: "", resetUserId: -1 });
        },
        (error) => {
          if (this.props.notification) {
            this.props.notification.add(error.message, true);
          }
        },
      ),
    );
  }
  openDialog(id) {
    this.setState({ resetUserId: id, showDialog: true });
  }
  openBackupDialog() {
    this.setState({ showBackupDialog: true });
  }
  startBackup() {
    startBackup(
      validate(
        () => {
          this.setState({ backupStatus: true });
        },
        () => { },
      ),
    );
  }
  downloadBackup(name) {

    refreshAccesstoken(
      validate(
        () => {
          fetch('/api/backup/' + name)
            .then(response => {
              const filename = response.headers.get('Content-Disposition').split('filename=')[1];
              response.blob().then(blob => {
                console.log(blob)
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = filename;
                a.click();
              });
            })
        },
        () => { },
      ),
    )
  }
  refreshStatus() {
    isBackupRunning(
      validate(
        (body) => {
          this.setState({ backupStatus: body });
        },
        (error) => {
          this.setState({ backupStatus: null });
        },
      ),
    );
    getBackups(
      validate(
        (body) => {
          this.setState({ backups: body });
        },
        (error) => { },
      ),
    );
  }
  renderModeal(username, idToDelete) {

    this.setState({ isModal: true, tempIdToDelet: idToDelete, tempUsername: username })



  }
  closeModal() {
    this.setState({ isModal: false })
  }
  updateUserState(id) {
    changeUserState(
      id,
      validate(
        (body) => {
          this.context.toggleUserState(id);
          if (this.props.notification) {
            this.props.notification.add("Status erfolgreich überschrieben");
          }
        },
        (error) => {
          if (this.props.notification) {
            this.props.notification.add(error.message, true);
          }
        },
      ),
    );
  }
  isUserDataComplete() {
    return this.state.username !== "" && this.state.password !== "";
  }
  render() {
    let pwValid = passwordValid(this.state.resetPassword);
    let status = { css: "bg-gray-400", text: "Status unbekannt" };
    let action = null;
    switch (this.state.backupStatus) {
      case false:
        status = { css: "bg-green-400", text: "Sicherung abgeschlossen" };
        action = <SButton onClick={this.startBackup}>Sicherung starten</SButton>;
        break;
      case true:
        status = { css: "bg-yellow-400", text: "Sicherung läuft" };
        action = <SButton>Sicherung abbrechen</SButton>;
        break;
    }
    return (

      <div className="p-2 flex   flex-wrap gap-y-2">
        {this.state.isModal ?
          <div>
            <div className=" absolute top-1/2 left-1/2 w-auto h-auto z-10 ">

              <div className="modal-content bg-white p-4 rounded-lg shadow-lg z-40">
                <div className="modal-header">
                  <h2 className="text-xl font-bold">{this.state.tempUsername} löschen?</h2>
                </div >
                <div className="modal-body flex  justify-evenly">
                  <SButton onClick={() => this.deleteUser(this.state.tempIdToDelet)}>Löschen</SButton>
                  <SButton onClick={() => this.closeModal()}>Zurück</SButton>
                </div>
                <div className="modal-footer">

                </div>
              </div>
            </div>
          </div>
          : null

        }
        <div className="flex-grow min-w-[50rem]">
          {this.state.showDialog ? (
            <ConfirmDialog
              disabled={!pwValid}
              header="Passwort zurücksetzen"
              text={
                <div className="flex gap-2 flex-col">
                  <div className="flex gap-2">
                    <label>Neues Passwort:</label>
                    <input
                      type="password"
                      value={this.state.resetPassword}
                      onChange={(evt) =>
                        this.setState({
                          resetPassword: evt.target.value,
                        })
                      }
                      className="row-input border-gray-400"
                    />
                  </div>
                  {this.state.resetPassword !== "" && !pwValid ? <p className="text-sm text-red-500">Passwort ungültig</p> : null}
                </div>
              }
              confirmText="Passwort zurücksetzen"
              cancelText="abbrechen"
              confirm={this.resetPassword}
              cancel={() => {
                this.setState({ showDialog: false, resetPassword: "", resetUserId: -1 });
              }}
            ></ConfirmDialog>
          ) : null}
          {this.state.showUserDialog ? (
            <ConfirmDialog
              header="Nutzername Überschreiben"
              text={
                <div className="flex gap-2 flex-col">
                  <div className="flex gap-2">
                    <label>Neuer Nutzername:</label>
                    <input
                      type="text"
                      value={this.state.newUsername}
                      onChange={(evt) =>
                        this.setState({
                          newUsername: evt.target.value,
                        })
                      }
                      className="row-input border-gray-400"
                    />
                  </div>
                  {this.state.newUsername === "" ? <p className="text-sm text-red-500">Nutzername ungültig</p> : null}
                </div>
              }
              confirmText="Nutzername überschreiben"
              cancelText="abbrechen"
              confirm={this.renameUser}
              cancel={() => {
                this.setState({ showUserDialog: false, newUserName: "", resetUserId: -1 });
              }}></ConfirmDialog>
          ) : null}
          <UserContext.Consumer>
            {(ctx) => (
              <Table
                disableSelect={true}
                disableEdit={true}
                header={[

                  { text: "Nutzername/Login", filter: (filter, x) => x.toLocaleUpperCase().includes(filter.toLocaleUpperCase()) },
                  "Passwort",
                  "Sperren",
                  "Löschen",
                ]}
                rows={this.filterusers(ctx.user).map((x) => [

                  <div className="flex justify-center items-center">{x.role === "ADMIN" ? <FontAwesomeIcon className="mr-4" icon={faUserSecret}></FontAwesomeIcon> : null}<p>{x.username}</p> {x.role !== "ADMIN" ? <FontAwesomeIcon className="ml-4" icon={faPencil} onClick={() => this.openUserDialog(x)}></FontAwesomeIcon> : null}</div>,
                  <SButton onClick={() => this.openDialog(x.id)}>
                    <FontAwesomeIcon icon={faKey}></FontAwesomeIcon>
                  </SButton>,
                  <SButton onClick={() => this.updateUserState(x.id)} className={"disabled:opacity-25"} disabled={x.role === "ADMIN" ? true : false}>
                    <span>{x.locked ? <FontAwesomeIcon icon={faLock}></FontAwesomeIcon> : <FontAwesomeIcon icon={faLockOpen}></FontAwesomeIcon>}</span>
                  </SButton>,
                  <SButton onClick={() => this.renderModeal(x.username, x.id)} className={"disabled:opacity-25"} disabled={x.role === "ADMIN" ? true : false}>
                    <span><FontAwesomeIcon icon={faTrashCan}></FontAwesomeIcon></span>
                  </SButton>,
                ])}
              ></Table>
            )}
          </UserContext.Consumer>
        </div>
        <div className="flex-grow flex  flex-col justify-center items-center">
          <h2 className="font-semibold">Benutzer anlegen</h2>
          <form className="p-3 max-w-[fit-content] flex flex-col border-2 rounded-lg bg-white bg-opacity-60 gap-2  ">
            <div className="row">
              <label htmlFor="username">Benutzername</label>
              <input value={this.state.username} onChange={(evt) => this.setState({ username: evt.target.value })} className="row-input" id="username" />
            </div>
            <div className="row">
              <label htmlFor="password">Passwort</label>
              <input
                value={this.state.password}
                onChange={(evt) => this.setState({ password: evt.target.value })}
                className="row-input"
                type="password"
                id="password"
              />
            </div>

            <SButton onClick={this.createUser} disabled={!this.isUserDataComplete()} className="w-fit self-center">
              Erstellen
            </SButton>
          </form>
        </div>
        <div className="bg-white flex-grow min-w-[50rem] bg-opacity-40 border-2 rounded-md  p-2">
          {this.state.showBackupDialog ? (
            <ConfirmDialog
              header="Sicherung erstellen"
              text="Sind Sie sicher das Sie eine neue Sicherung anlegen wollen? Dabei wird die letzte manuelle Sicherung durch die neue UNWIEDERRUFLICH ERSETZT!"
              confirmText="Sicherung starten"
              cancelText="abbrechen"
              confirm={this.startBackup}
              cancel={() => {
                this.setState({ showBackupDialog: false });
              }}
            ></ConfirmDialog>
          ) : null}
          <h2 className="font-semibold">Sicherungen der Software</h2>
          <div className="w-fit min-w-[20rem] p-1 rounded-md border-2 bg-gray-100 bg-opacity-40 flex flex-col gap-1">
            <span className="flex justify-between">
              <p className="font-semibold">Aktueller Status:</p>
              <SButton onClick={this.refreshStatus}>
                <FontAwesomeIcon icon={faRefresh}></FontAwesomeIcon>
              </SButton>
            </span>
            <div className="flex gap-1 items-center">
              <div className={"w-4 h-4 " + status.css}></div>
              <p>{status.text}</p>
            </div>
            {action}
          </div>
          <form
            className="flex flex-col w-fit border border-black rounded-md p-2"
            onSubmit={(evt) => {
              evt.preventDefault();
              const formData = new FormData();
              const file = evt.target.backup.files[0];
              formData.append("file", file, file.name);
              restore(
                validate(
                  () => { },
                  () => { },
                ),
                formData,
              );
            }}
          >
            <label>Wiederherstellung</label>
            <input type="file" name="backup" />

            <div className="flex gap-2">
              <input value={this.state.btnDisabled} type="checkbox" onChange={(evt) => this.setState({ btnDisabled: !this.state.btnDisabled })} />
              <label>Ich bin mir bewusst das sämtliche Daten überschrieben werden und nicht wiederhergestellt werden können.</label>
            </div>
            <input
              disabled={this.state.btnDisabled}
              className="border border-gray-400 rounded-lg px-2 overflow-hidden bg-gray-300 hover:bg-orange-200 hover:disabled:bg-white disabled:bg-white "
              type="submit"
            />
          </form>
          <Table
          disableEdit={true}
            disableSelect="true"
            rows={this.state.backups.map((x) => [
              x.name,
              new Date(x.timeMilis).toLocaleString("de-DE"),
              //<a download={x.name} href={"/api/backup/" + x.name}>
              <a onClick={() => this.downloadBackup(x.name)}>
                <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
              </a>,
            ])}
            header={[
              { text: "Dateiname", filter: getStringFilter(), compare: getStringCompare() },
              { text: "Erstellzeitpunkt", filter: getStringFilter(), compare: getStringCompare() },
              "",
            ]}
          ></Table>
        </div>
      </div>
    );
  }
}

export default AdminPanel;
