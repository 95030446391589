import { faLock, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { login } from "./api";
import { NotificationContext } from "./context/NotificationContext";
class Login extends Component {
  static contextType = NotificationContext;
  constructor(props) {
    super(props);
    this.login = this.login.bind(this);
    this.isInputPresent = this.isInputPresent.bind(this);
    this.enterHandler = this.enterHandler.bind(this);
    this.loginResponseHandler = this.loginResponseHandler.bind(this);
  }
  state = { username: "", password: "" };
  login() {
    if (this.props.loginSuccessful) {
      login(this.state.username, this.state.password, this.loginResponseHandler);
    }
  }
  loginResponseHandler(status, body) {
    
    if (status !== 200) {
      this.context.add(body.message, true);
    } else {
      if (this.props.loginSuccessful) {
        this.props.loginSuccessful();
      }
    }
  }
  render() {
    return (
      <div className="w-full h-full svg-bg  flex justify-center items-center">
        <div className="flex flex-col gap-2 justify-center items-center    p-7 rounded-lg  bg-opacity-50 bg-white shadow-2xl drop-shadow-2xl ">
          <h1 className="font-bold text-2xl p-2">Verpackungsplanung by AiNT</h1>
          <div className="flex flex-col gap-2">
            <div className="flex gap-0 justify-between border border-gray-300 rounded-lg">
              <span className=" text-gray-500 border-r border-gray-300 p-2 bg-gray-50 rounded-l-lg">
                <FontAwesomeIcon icon={faUser}></FontAwesomeIcon>
              </span>
              <input
                value={this.state.username}
                onChange={(x) => {
                  this.setState({ username: x.target.value });
                }}
                onKeyDown={this.enterHandler}
                id="username"
                type="text"
                placeholder="Nutzername"
                className="rounded-none rounded-r-lg p-2"
              />
            </div>
            <div className="flex  justify-between border border-gray-300 rounded-lg">
              <span className="text-gray-500 border-r border-gray-300 p-2 bg-gray-50 rounded-l-lg">
                <FontAwesomeIcon icon={faLock}></FontAwesomeIcon>
              </span>
              <input
                value={this.state.password}
                onChange={(x) => this.setState({ password: x.target.value })}
                id="password"
                type="password"
                placeholder="Passwort"
                className="rounded-none rounded-r-lg p-2"
                onKeyDown={this.enterHandler}
              />
            </div>
            <div className="flex  justify-center border-gray-300 rounded-lg">
              <button
                disabled={!this.isInputPresent()}
                onClick={this.login}
                className=" bg-white bg-opacity-60 hover:bg-opacity-80 text-gray-600 font-bold  w-fit px-6 py-1 rounded-xl disabled:bg-opacity-30 "
              >
                Login
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
  enterHandler(event) {
    if (event.keyCode === 13) {
      event.preventDefault();
      if (this.isInputPresent()) {
        this.login();
      }
    }
  }
  isInputPresent() {
    return !(this.state.password === "" || this.state.username === "");
  }
}

export default Login;
