import React, { Children, Component, Fragment } from "react";
class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = { selected: this.props.selected };
  }
  render() {
    return (
      <div className="flex flex-col pt-5 px-2 gap-3 h-full min-w-[220px] bg-main border-t border-t-white">
        <>{this.props.logo}</>
        <>{this.props.title}</>
        {this.props.logo || this.props.title ? <hr /> : null}
        <div className="flex flex-col justify-between grow overflow-auto">
          <div className="flex flex-col py-1 px-2 gap-3 overflow-auto ">
            {Children.map(this.props.children[0].props.children, (child, index) => {
              return (
                <div
                  className={"hover:opacity-100 " + (this.state.selected === index ? "opacity-100" : "opacity-50")}
                  key={index}
                  onClick={() => {
                    if (this.props.onUpdate) {
                      this.props.onUpdate(child.props.id);
                    }
                    this.setState({ selected: index });
                  }}
                >
                  {child}
                </div>
              );
            })}
          </div>
          <div className="px-3 flex flex-col gap-3 pb-6">{this.props.children[1]}</div>
        </div>
      </div>
    );
  }
}

export default Sidebar;
