import React, { Component } from "react";
import { changePassword } from "../api";
import { AuthContext } from "../context/AuthContext";
import { NotificationContext } from "../context/NotificationContext";
import Setting from "../settings/Setting";
import SettingCategory from "../settings/SettingCategory";
import SettingsHeader from "../settings/SettingsHeader";
import SButton from "../styledComponents/SButton";
import SInput from "../styledComponents/SInput";
import SLabel from "../styledComponents/SLabel";
import { validate } from "../Utility/ResponseConsumer";
import { passwordValid, usernameValid } from "../Utility/Utility";
class UserSettings extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = { username: "Loading",pwd1: "", pwd2: "" };
  }
  componentDidMount() {
    this.setState({ username: this.context.user.username});
  }
  changePassword(notificationContext) {
    changePassword(
      this.state.pwd1,
      validate(
        (msg) => {
          notificationContext.add("Passwort wurde geändert!");
          this.setState({ pwd1: "", pwd2: "" });
        },
        (msg) => {
          notificationContext.add(msg, true);
        },
      ),
    );
  }
  render() {
    let pwEqual = this.state.pwd1 === this.state.pwd2;
    let regexMatch = true;
    let usernameMatch = usernameValid(this.state.username);
    if (pwEqual && this.state.pwd1 !== "") {
      regexMatch = passwordValid(this.state.pwd1);
    }
    return (
      <div className="flex flex-col gap-2 ml-6 mt-2">
        <h1>Nutzereinstellungen</h1>
        <div className="flex  gap-5">
          <NotificationContext.Consumer>
            {(ctx) => (
              <>
                {" "}
                <SettingCategory className="min-w-fit max-w-fit">
                  <SettingsHeader>Nutzerdaten</SettingsHeader>
                  <Setting>
                    <SLabel>Nutzername/Anmeldename</SLabel>
                    <SInput disabled={true} onChange={(evt) => this.setState({ username: evt.target.value })} value={this.state.username} />
                  </Setting>
                  {!usernameMatch ? <p className="text-sm text-red-500">Nutzername ungültig</p> : null}
                </SettingCategory>
                <SettingCategory className="min-w-fit max-w-fit">
                  <SettingsHeader>Passwort</SettingsHeader>
                  <Setting>
                    <SLabel>Neues Passwort</SLabel>
                    <SInput type="password" onChange={(evt) => this.setState({ pwd1: evt.target.value })} value={this.state.pwd1} />
                  </Setting>
                  <Setting>
                    <SLabel>Passwort wiederholen</SLabel>
                    <SInput type="password" onChange={(evt) => this.setState({ pwd2: evt.target.value })} value={this.state.pwd2} />
                  </Setting>
                  {!pwEqual ? <p className="text-sm text-red-500">Passwörter stimmen nicht überein!</p> : null}
                  {!regexMatch ? (
                    <span className="text-sm text-red-500">
                      <p>Passwort muss folgende Eigenschaften erfüllen:</p>
                      <ul className="list-disc pl-4">
                        <li>Mindestlänge von 8 Zeichen</li>
                        <li>Min. einen Großbuchstaben</li>
                        <li>Min. eine Zahl</li>
                        <li>Min. einen Kleinbuchstaben</li>
                        <li>Min. ein Sonderzeichen</li>
                      </ul>
                    </span>
                  ) : null}
                  <SButton onClick={() => this.changePassword(ctx)} disabled={!(pwEqual && regexMatch)}>
                    Passwort ändern
                  </SButton>
                </SettingCategory>
              </>
            )}
          </NotificationContext.Consumer>
        </div>
      </div>
    );
  }
}

export default UserSettings;
