import {faQuestionCircle, faArrowAltCircleLeft} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {Component} from "react";
import SButton from "../styledComponents/SButton";
import {faToggleOff} from "@fortawesome/free-solid-svg-icons";

class ConfirmDialog extends Component {
    constructor(props) {
        super(props);
    }

    state = {};

    render() {
        return (
            <div className="absolute flex w-screen h-screen top-0 left-0 justify-center items-center  z-20">
                <div
                    className="bg-gray-100 flex flex-col gap-2 rounded-lg border max-w-md border-gray-600 shadow-lg overflow-hidden">
                    <h1 className="font-bold bg-main text-white pl-2 py-1 ">
                        {this.props.header ? (
                            this.props.header
                        ) : (
                            <span className="flex gap-2 items-center justify-center ">


                <span>Aktion bestätigen</span>
                <FontAwesomeIcon icon={faQuestionCircle}></FontAwesomeIcon>

              </span>
                        )}
                    </h1>

                    <span className="text-center font-semibold px-2">{this.props.text}</span>
                    <div className="flex justify-around gap-5 border-t-2 border-gray-400 py-2">

                        <SButton
                            onClick={this.props.cancel ? this.props.cancel : null}>{this.props.cancelText ? this.props.cancelText : "Nein"}</SButton>
                        <SButton disabled={this.props.disabled ? this.props.disabled : false}
                                 onClick={this.props.confirm ? this.props.confirm : null}>
                            {this.props.confirmText ? this.props.confirmText : "Ja"}
                        </SButton>
                    </div>
                </div>
            </div>
        );
    }
}

export default ConfirmDialog;
