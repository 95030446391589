import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { LimitContext } from "../context/LimitContext";
import Expandable from "../Utility/Expandable";
const cTypes = ["Gussbehälter", "Container", "Betonbehälter"];
const types = ["Typ I", "Typ II", "Typ II KfK", "Typ III", "Typ IV", "Typ V", "Typ VI"];
const enrichments = [
  { label: "Uran Anreicherung <= 5%", value: "LESS_EQUAL_5_PERCENT" },
  { label: "Uran Anreicherung > 5%", value: "MORE_5_PERCENT" },
  { label: "Plutonium", value: "NONE" },
];
class ActivityLimitsView extends Component {
  static contextType = LimitContext;
  state = { filter: "" };
  render() {
    let incidentLimits = this.context.incident;
    let thermalLimits = this.context.thermal;
    let criticalityLimits = this.context.criticality;
    if (this.state.filter !== "") {
    }

    return (
      <div className="p-3 flex flex-col items-center gap-2">
        <h1 className="font-bold text-3xl">Aktivitätsgrenzwerte</h1>
        <div className="border-2 rounded-lg w-fit border-gray-400">
          <input type="text" className="rounded-l-lg p-2" onChange={(evt) => this.setState({ filter: evt.target.value })} />
          <span className="bg-gray-200 p-2 rounded-r-lg border-l border-gray-300">
            <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
          </span>
        </div>
        <Expandable className="p-2 flex flex-col gap-2" text={<h2 className="font-bold text-md p-2">Aktivitätsgrenzen - Störfall</h2>}>
          <Expandable className="p-2 flex flex-col gap-2" text={<h2 className="font-bold text-md p-2">Abfallbehälterklasse I</h2>}>
            <table className="d-table">
              <thead className="d-thead">
                <tr className="d-tr">
                  <th className="d-th">Nuklid</th>
                  <th className="d-th">Abfallproduktgruppe 1</th>
                  <th className="d-th">Abfallproduktgruppe 2</th>
                  <th className="d-th">Abfallproduktgruppe 3</th>
                  <th className="d-th">Abfallproduktgruppe 4</th>
                  <th className="d-th">Abfallproduktgruppe 5</th>
                  <th className="d-th">Abfallproduktgruppe 6</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(incidentLimits).map((nuclide, idx) => {
                  if (!nuclide.toLocaleUpperCase().includes(this.state.filter.toLocaleUpperCase())) {
                    return null;
                  }
                  return (
                    <tr className="d-tr" key={idx}>
                      <td className="d-td">{nuclide}</td>
                      {Object.keys(incidentLimits[nuclide]["1"]).map((wasteClass) => {
                        return (
                          <td key={wasteClass} className="d-td">
                            {incidentLimits[nuclide]["1"][wasteClass].getPretty(2)}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Expandable>
          <Expandable className="p-2 flex flex-col gap-2" text={<h2 className="font-bold text-md p-2">Abfallbehälterklasse II</h2>}>
            <table className="d-table">
              <thead className="d-thead">
                <tr className="d-tr">
                  <th className="d-th">Nuklid</th>
                  <th className="d-th">Abfallproduktgruppe 01-06</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(incidentLimits).map((nuclide, idx) => {
                  if (!nuclide.toLocaleUpperCase().includes(this.state.filter.toLocaleUpperCase())) {
                    return null;
                  }
                  return (
                    <tr className="d-tr" key={idx}>
                      <td className="d-td">{nuclide}</td>
                      <td className="d-td">{incidentLimits[nuclide]["2"]["1"].getPretty(2)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Expandable>
        </Expandable>
        <Expandable className="p-2 flex flex-col gap-2" text={<h2 className="font-bold text-md p-2">Aktivitätsgrenzen - Thermische Beeinflussung</h2>}>
          {cTypes.map((cType, idx) => (
            <Expandable key={idx} className="p-2 flex flex-col gap-2" text={<h2 className="font-bold text-md p-2">{cType}</h2>}>
              <table className="d-table">
                <thead className="d-thead">
                  <tr className="d-tr">
                    <th className="d-th">Nuklid</th>
                    {types.map((x, idx) =>
                      Object.keys(thermalLimits[Object.keys(thermalLimits)[0]][cType]).includes(x) ? (
                        <th key={idx} className="d-th">
                          {x}
                        </th>
                      ) : null,
                    )}
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(thermalLimits).map((nuclide, idx) => {
                    if (!nuclide.toLocaleUpperCase().includes(this.state.filter.toLocaleUpperCase())) {
                      return null;
                    }
                    return (
                      <tr className="d-tr" key={idx}>
                        <td className="d-td">{nuclide}</td>
                        {types.map((x, idx) =>
                          Object.keys(thermalLimits[nuclide][cType]).includes(x) ? (
                            <td key={idx} className="d-td">
                              {thermalLimits[nuclide][cType][x].getPretty(2)}
                            </td>
                          ) : null,
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Expandable>
          ))}
        </Expandable>
        <Expandable className="p-2 flex flex-col gap-2" text={<h2 className="font-bold text-md p-2">Aktivitätsgrenzen - Kritikalität</h2>}>
          {enrichments.map((enrichment, idx) => (
            <Expandable key={idx} className="p-2 flex flex-col gap-2" text={<h2 className="font-bold text-md p-2">{enrichment.label}</h2>}>
              {cTypes.map((cType, idx) => (
                <Expandable key={idx} className="p-2 flex flex-col gap-2" text={<h2 className="font-bold text-md p-2">{cType}</h2>}>
                  <table className="d-table">
                    <thead className="d-thead">
                      <tr className="d-tr">
                        <th className="d-th">Nuklid</th>
                        {types.map((x, idx) =>
                          Object.keys(criticalityLimits[Object.keys(criticalityLimits)[0]][cType]).includes(x) ? (
                            <th key={idx} className="d-th">
                              {x}
                            </th>
                          ) : null,
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(criticalityLimits).map((nuclide, idx) => {
                        if (!nuclide.toLocaleUpperCase().includes(this.state.filter.toLocaleUpperCase())) {
                          return null;
                        }

                        if (!criticalityLimits[nuclide][cType][types[0]][enrichment.value]) {
                          return null;
                        }
                        return (
                          <tr className="d-tr" key={idx}>
                            <td className="d-td">{nuclide}</td>
                            {types.map((x, idx) =>
                              Object.keys(criticalityLimits[nuclide][cType]).includes(x) ? (
                                <td key={idx} className="d-td">
                                  {criticalityLimits[nuclide][cType][x][enrichment.value].getPretty(2)}
                                </td>
                              ) : null,
                            )}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </Expandable>
              ))}
            </Expandable>
          ))}
        </Expandable>
        <Expandable className="p-2 flex flex-col gap-2" text={<h2 className="font-bold text-md p-2">Aktivitätsgrenzen - Garantiewerte</h2>}>

          <Expandable className="p-2 flex flex-col gap-2" text={<h2 className="font-bold text-md p-2">Verpackung ohne spezifizierte Dichtheit</h2>}>
            <table className="d-table">
              <thead className="d-thead">
                <tr className="d-tr">
                  <th className="d-th">Radio Nuklid</th>
                  <th className="d-th">Metalische Feststoffe</th>
                  <th className="d-th">Sonstige Abfallproduktgruppe</th>
                </tr>
              </thead>
              <tbody>
                <tr className="d-tr">
                  <td className="d-td">Tritium unspezifiziert</td>
                  <td className="d-td">---</td>
                  <td className="d-td">3,0E+09</td>
                </tr >
                <tr className="d-tr">
                  <td className="d-td">Tritium HTO: {"<"} 10^10 Bq</td>
                  <td className="d-td">---</td>
                  <td className="d-td">7,4E+10</td>
                </tr >
                <tr className="d-tr">
                  <td className="d-td">Tritium HTO: {">="} 10^10 Bq und {"<"} 10^12 Bq</td>
                  <td className="d-td">---</td>
                  <td className="d-td">4,2E+10</td>
                </tr>
                <tr className="d-tr">
                  <td className="d-td">Tritium HTO: {">="} 10^12 Bq</td>
                  <td className="d-td">---</td>
                  <td className="d-td">3,0E+09</td>
                </tr > 
                <tr className="d-tr">
                  <td className="d-td">Tritium als HT</td>
                  <td className="d-td">1,9E+11</td>
                  <td className="d-td">---</td>
                </tr>

                
                <tr className="d-tr !bg-slate-300">
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                </tr >
                <tr className="d-tr !bg-slate-300">
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                </tr >

                <tr className="d-tr">
                  <td className="d-td">C-14 unspezifiziert oder in flüchtiger Form</td>
                  <td className="d-td">8,4E+12</td>
                  <td className="d-td">1,8E+08</td>
                </tr >
                <tr className="d-tr">
                  <td className="d-td">C-14 Anteil flüchtiger Form: {">"} 1% {"<="} 10%</td>
                  <td className="d-td">---</td>
                  <td className="d-td">1,8E+09</td>
                </tr >
                <tr className="d-tr">
                  <td className="d-td">C-14 Anteil flüchtiger Form: {"<="} 1%</td>
                  <td className="d-td">---</td>
                  <td className="d-td">1,8E+10</td>
                </tr>

                <tr className="d-tr !bg-slate-300">
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                </tr >
                <tr className="d-tr !bg-slate-300">
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                </tr >

                <tr className="d-tr">
                  <td className="d-td">Kr-85</td>
                  <td className="d-td">---</td>
                  <td className="d-td">3,0E+10 </td>
                </tr>

                <tr className="d-tr !bg-slate-300">
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                </tr >
                <tr className="d-tr !bg-slate-300">
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                </tr >

                <tr className="d-tr">
                  <td className="d-td">I-129 unspezifiziert </td>
                  <td className="d-td">---</td>
                  <td className="d-td">1,9E+07</td>
                </tr >
                <tr className="d-tr">
                  <td className="d-td">I-129  auf silberhaltigen Filtern</td>
                  <td className="d-td">---</td>
                  <td className="d-td">1,9E+09</td>
                </tr>

                <tr className="d-tr !bg-slate-300">
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                </tr >
                <tr className="d-tr !bg-slate-300">
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                </tr >

                <tr className="d-tr">
                  <td className="d-td">Ra-226 unfixiert</td>
                  <td className="d-td">---</td>
                  <td className="d-td">9,0E+06</td>
                </tr>
                <tr className="d-tr">
                  <td className="d-td">Ra-226 fixiert</td>
                  <td className="d-td">---</td>
                  <td className="d-td">1,4E+08</td>
                </tr>

                <tr className="d-tr !bg-slate-300">
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                </tr >
                <tr className="d-tr !bg-slate-300">
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                </tr >

                <tr className="d-tr">
                  <td className="d-td">sonstige &#945;-Strahler sowie PU-241</td>
                  <td className="d-td">---</td>
                  <td className="d-td">1,9E+14</td>
                </tr>

                <tr className="d-tr !bg-slate-300">
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                </tr >
                <tr className="d-tr !bg-slate-300">
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                </tr >

                <tr className="d-tr">
                  <td className="d-td">sonstige &#946;-/&#947;-Strahler außer Pu-241 Massenanteil des Wassers: {"<"} 1% </td>
                  <td className="d-td">---</td>
                  <td className="d-td">3,7E+15</td>
                </tr>
                <tr className="d-tr">
                  <td className="d-td">sonstige &#946;-/&#947;-Strahler außer Pu-241 Massenanteil des Wassers: {">="} 1% </td>
                  <td className="d-td">---</td>
                  <td className="d-td">3,7E+13</td>
                </tr>
              </tbody>
            </table>
          </Expandable>
          <Expandable className="p-2 flex flex-col gap-2" text={<h2 className="font-bold text-md p-2">Verpackung mit spezifizierte Dichtheit</h2>}>
            <Expandable className="p-2 flex flex-col gap-2" text={<h2 className="font-bold text-md p-2">0,01</h2>}>
            <table className="d-table">
              <thead className="d-thead">
                <tr className="d-tr">
                  <th className="d-th">Radio Nuklid</th>
                  <th className="d-th">Metalische Feststoffe</th>
                  <th className="d-th">Sonstige Abfallproduktgruppe</th>
                </tr>
              </thead>
              <tbody>
                <tr className="d-tr">
                  <td className="d-td">Tritium unspezifiziert</td>
                  <td className="d-td">---</td>
                  <td className="d-td">3,3E+09</td>
                </tr >
                <tr className="d-tr">
                  <td className="d-td">Tritium als HTO mit einer Gesamtaktivität im Abfallprodukt ohne Tritiumaktivität von: {"<"} 10^10 Bq</td>
                  <td className="d-td">---</td>
                  <td className="d-td">4,2E+12</td>
                </tr >
                <tr className="d-tr">
                  <td className="d-td">Tritium als HTO mit einer Gesamtaktivität im Abfallprodukt ohne Tritiumaktivität von: {">="} 10^10 Bq und {"<"} 10^12 Bq</td>
                  <td className="d-td">---</td>
                  <td className="d-td">9,4E+10</td>
                </tr>
                <tr className="d-tr">
                  <td className="d-td">Tritium als HTO mit einer Gesamtaktivität im Abfallprodukt ohne Tritiumaktivität von: {">="} 10^12 Bq</td>
                  <td className="d-td">---</td>
                  <td className="d-td">3,3E+09</td>
                </tr > 
                <tr className="d-tr">
                  <td className="d-td">Tritium als HT</td>
                  <td className="d-td">1,9E+11</td>
                  <td className="d-td">---</td>
                </tr>

                <tr className="d-tr !bg-slate-300">
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                </tr >
                <tr className="d-tr !bg-slate-300">
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                </tr >

                <tr className="d-tr">
                  <td className="d-td">C-14 unspezifiziert oder in flüchtiger Form</td>
                  <td className="d-td">9,2E+12</td>
                  <td className="d-td">2,0E+08</td>
                </tr >
                <tr className="d-tr">
                  <td className="d-td">C-14 Anteil flüchtiger Form: {">"} 1% {"<="} 10%</td>
                  <td className="d-td">---</td>
                  <td className="d-td">2,0E+09</td>
                </tr >
                <tr className="d-tr">
                  <td className="d-td">C-14 Anteil flüchtiger Form: {"<="} 1%</td>
                  <td className="d-td">---</td>
                  <td className="d-td">2,0E+10</td>
                </tr>

                <tr className="d-tr !bg-slate-300">
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                </tr >
                <tr className="d-tr !bg-slate-300">
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                </tr >

                <tr className="d-tr">
                  <td className="d-td">Kr-85</td>
                  <td className="d-td">---</td>
                  <td className="d-td">3,0E+10 </td>
                </tr>

                <tr className="d-tr !bg-slate-300">
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                </tr >
                <tr className="d-tr !bg-slate-300">
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                </tr >

                <tr className="d-tr">
                  <td className="d-td">I-129 unspezifiziert </td>
                  <td className="d-td">---</td>
                  <td className="d-td">1,9E+09</td>
                </tr >
                <tr className="d-tr">
                  <td className="d-td">I-129  auf silberhaltigen Filtern aus der Abgasreinigung in Wiederaufarbeitungsanlagen </td>
                  <td className="d-td">---</td>
                  <td className="d-td">1,9E+11</td>
                </tr>

                <tr className="d-tr !bg-slate-300">
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                </tr >
                <tr className="d-tr !bg-slate-300">
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                </tr >

                <tr className="d-tr">
                  <td className="d-td">Ra-226 unfixiert</td>
                  <td className="d-td">---</td>
                  <td className="d-td">4,8E+10</td>
                </tr>
                <tr className="d-tr">
                  <td className="d-td">Ra-226 fixiert</td>
                  <td className="d-td">---</td>
                  <td className="d-td">4,8E+10</td>
                </tr>

                <tr className="d-tr !bg-slate-300">
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                </tr >
                <tr className="d-tr !bg-slate-300">
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                </tr >

                <tr className="d-tr">
                  <td className="d-td">sonstige &#945;-Strahler sowie PU-241</td>
                  <td className="d-td">---</td>
                  <td className="d-td">1,9E+16</td>
                </tr>

                <tr className="d-tr !bg-slate-300">
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                </tr >
                <tr className="d-tr !bg-slate-300">
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                </tr >

                <tr className="d-tr">
                  <td className="d-td">sonstige &#946;-/&#947;-Strahler außer Pu-241 mit einem Massenanteil des Wassers bzw. der Restfeuchte im Abfallprodukt von: {"<"} 1% </td>
                  <td className="d-td">---</td>
                  <td className="d-td">3,7E+17</td>
                </tr>
                <tr className="d-tr">
                  <td className="d-td">sonstige &#946;-/&#947;-Strahler außer Pu-241 mit einem Massenanteil des Wassers bzw. der Restfeuchte im Abfallprodukt von: {">="} 1% </td>
                  <td className="d-td">---</td>
                  <td className="d-td">3,7E+15</td>
                </tr>
              </tbody>
            </table>
            </Expandable>
            <Expandable className="p-2 flex flex-col gap-2" text={<h2 className="font-bold text-md p-2">0,001</h2>}>
            <table className="d-table">
              <thead className="d-thead">
                <tr className="d-tr">
                  <th className="d-th">Radio Nuklid</th>
                  <th className="d-th">Metalische Feststoffe</th>
                  <th className="d-th">Sonstige Abfallproduktgruppe</th>
                </tr>
              </thead>
              <tbody>
                <tr className="d-tr">
                  <td className="d-td">Tritium unspezifiziert</td>
                  <td className="d-td">---</td>
                  <td className="d-td">3,3E+09</td>
                </tr >
                <tr className="d-tr">
                  <td className="d-td">Tritium als HTO mit einer Gesamtaktivität im Abfallprodukt ohne Tritiumaktivität von: {"<"} 10^10 Bq</td>
                  <td className="d-td">---</td>
                  <td className="d-td">8,4E+12</td>
                </tr >
                <tr className="d-tr">
                  <td className="d-td">Tritium als HTO mit einer Gesamtaktivität im Abfallprodukt ohne Tritiumaktivität von: {">="} 10^10 Bq und {"<"} 10^12 Bq</td>
                  <td className="d-td">---</td>
                  <td className="d-td">9,5E+10</td>
                </tr>
                <tr className="d-tr">
                  <td className="d-td">Tritium als HTO mit einer Gesamtaktivität im Abfallprodukt ohne Tritiumaktivität von: {">="} 10^12 Bq</td>
                  <td className="d-td">---</td>
                  <td className="d-td">3,3E+09</td>
                </tr > 
                <tr className="d-tr">
                  <td className="d-td">Tritium als HT</td>
                  <td className="d-td">1,9E+11</td>
                  <td className="d-td">---</td>
                </tr>

                <tr className="d-tr !bg-slate-300">
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                </tr >
                <tr className="d-tr !bg-slate-300">
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                </tr >

                <tr className="d-tr">
                  <td className="d-td">C-14 unspezifiziert oder in flüchtiger Form</td>
                  <td className="d-td">9,2E+12</td>
                  <td className="d-td">2,0E+08</td>
                </tr >
                <tr className="d-tr">
                  <td className="d-td">C-14 Anteil flüchtiger Form: {">"} 1% {"<="} 10%</td>
                  <td className="d-td">---</td>
                  <td className="d-td">2,0E+09</td>
                </tr >
                <tr className="d-tr">
                  <td className="d-td">C-14 Anteil flüchtiger Form: {"<="} 1%</td>
                  <td className="d-td">---</td>
                  <td className="d-td">2,0E+10</td>
                </tr>

                <tr className="d-tr !bg-slate-300">
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                </tr >
                <tr className="d-tr !bg-slate-300">
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                </tr >

                <tr className="d-tr">
                  <td className="d-td">Kr-85</td>
                  <td className="d-td">---</td>
                  <td className="d-td">3,0E+10 </td>
                </tr>

                <tr className="d-tr !bg-slate-300">
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                </tr >
                <tr className="d-tr !bg-slate-300">
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                </tr >

                <tr className="d-tr">
                  <td className="d-td">I-129 unspezifiziert </td>
                  <td className="d-td">---</td>
                  <td className="d-td">1,9E+10</td>
                </tr >
                <tr className="d-tr">
                  <td className="d-td">I-129  auf silberhaltigen Filtern aus der Abgasreinigung in Wiederaufarbeitungsanlagen </td>
                  <td className="d-td">---</td>
                  <td className="d-td">1,9E+12</td>
                </tr>

                <tr className="d-tr !bg-slate-300">
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                </tr >
                <tr className="d-tr !bg-slate-300">
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                </tr >

                <tr className="d-tr">
                  <td className="d-td">Ra-226 unfixiert</td>
                  <td className="d-td">---</td>
                  <td className="d-td">4,8E+11</td>
                </tr>
                <tr className="d-tr">
                  <td className="d-td">Ra-226 fixiert</td>
                  <td className="d-td">---</td>
                  <td className="d-td">4,8E+11</td>
                </tr>

                <tr className="d-tr !bg-slate-300">
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                </tr >
                <tr className="d-tr !bg-slate-300">
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                </tr >

                <tr className="d-tr">
                  <td className="d-td">sonstige &#945;-Strahler sowie PU-241</td>
                  <td className="d-td">---</td>
                  <td className="d-td">1,9E+16</td>
                </tr>

                <tr className="d-tr !bg-slate-300">
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                </tr >
                <tr className="d-tr !bg-slate-300">
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                </tr >

                <tr className="d-tr">
                  <td className="d-td">sonstige &#946;-/&#947;-Strahler außer Pu-241 mit einem Massenanteil des Wassers bzw. der Restfeuchte im Abfallprodukt von: {"<"} 1% </td>
                  <td className="d-td">---</td>
                  <td className="d-td">3,7E+17</td>
                </tr>
                <tr className="d-tr">
                  <td className="d-td">sonstige &#946;-/&#947;-Strahler außer Pu-241 mit einem Massenanteil des Wassers bzw. der Restfeuchte im Abfallprodukt von: {">="} 1% </td>
                  <td className="d-td">---</td>
                  <td className="d-td">3,7E+15</td>
                </tr>
              </tbody>
            </table>
            </Expandable>
            <Expandable className="p-2 flex flex-col gap-2" text={<h2 className="font-bold text-md p-2">0,0001</h2>}>
            <table className="d-table">
              <thead className="d-thead">
                <tr className="d-tr">
                  <th className="d-th">Radio Nuklid</th>
                  <th className="d-th">Metalische Feststoffe</th>
                  <th className="d-th">Sonstige Abfallproduktgruppe</th>
                </tr>
              </thead>
              <tbody>
                <tr className="d-tr">
                  <td className="d-td">Tritium unspezifiziert</td>
                  <td className="d-td">---</td>
                  <td className="d-td">3,3E+09</td>
                </tr >
                <tr className="d-tr">
                  <td className="d-td">Tritium HTO: {"<"} 10^10 Bq</td>
                  <td className="d-td">---</td>
                  <td className="d-td">9,3E+12</td>
                </tr >
                <tr className="d-tr">
                  <td className="d-td">Tritium als HTO: {">="} 10^10 Bq und {"<"} 10^12 Bq</td>
                  <td className="d-td">---</td>
                  <td className="d-td">9,5E+10</td>
                </tr>
                <tr className="d-tr">
                  <td className="d-td">Tritium als HTO: {">="} 10^12 Bq</td>
                  <td className="d-td">---</td>
                  <td className="d-td">3,3E+09</td>
                </tr > 
                <tr className="d-tr">
                  <td className="d-td">Tritium als HT</td>
                  <td className="d-td">1,9E+11</td>
                  <td className="d-td">---</td>
                </tr>

                <tr className="d-tr !bg-slate-300">
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                </tr >
                <tr className="d-tr !bg-slate-300">
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                </tr >

                <tr className="d-tr">
                  <td className="d-td">C-14 unspezifiziert oder in flüchtiger Form</td>
                  <td className="d-td">9,2E+12</td>
                  <td className="d-td">2,0E+08</td>
                </tr >
                <tr className="d-tr">
                  <td className="d-td">C-14 Anteil flüchtiger Form: {">"} 1% {"<="} 10%</td>
                  <td className="d-td">---</td>
                  <td className="d-td">2,0E+09</td>
                </tr >
                <tr className="d-tr">
                  <td className="d-td">C-14 Anteil flüchtiger Form: {"<="} 1%</td>
                  <td className="d-td">---</td>
                  <td className="d-td">2,0E+10</td>
                </tr>

                <tr className="d-tr !bg-slate-300">
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                </tr >
                <tr className="d-tr !bg-slate-300">
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                </tr >

                <tr className="d-tr">
                  <td className="d-td">Kr-85</td>
                  <td className="d-td">---</td>
                  <td className="d-td">3,0E+10 </td>
                </tr>

                <tr className="d-tr !bg-slate-300">
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                </tr >
                <tr className="d-tr !bg-slate-300">
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                </tr >

                <tr className="d-tr">
                  <td className="d-td">I-129 unspezifiziert </td>
                  <td className="d-td">---</td>
                  <td className="d-td">1,9E+11</td>
                </tr >
                <tr className="d-tr">
                  <td className="d-td">I-129  auf silberhaltigen Filtern aus der Abgasreinigung in Wiederaufarbeitungsanlagen </td>
                  <td className="d-td">---</td>
                  <td className="d-td">1,9E+13</td>
                </tr>

                <tr className="d-tr !bg-slate-300">
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                </tr >
                <tr className="d-tr !bg-slate-300">
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                </tr >

                <tr className="d-tr">
                  <td className="d-td">Ra-226 unfixiert</td>
                  <td className="d-td">---</td>
                  <td className="d-td">4,8E+12</td>
                </tr>
                <tr className="d-tr">
                  <td className="d-td">Ra-226 fixiert</td>
                  <td className="d-td">---</td>
                  <td className="d-td">4,8E+12</td>
                </tr>

                <tr className="d-tr !bg-slate-300">
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                </tr >
                <tr className="d-tr !bg-slate-300">
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                </tr >

                <tr className="d-tr">
                  <td className="d-td">sonstige &#945;-Strahler sowie PU-241</td>
                  <td className="d-td">---</td>
                  <td className="d-td">1,9E+16</td>
                </tr>

                <tr className="d-tr !bg-slate-300">
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                </tr >
                <tr className="d-tr !bg-slate-300">
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                  <td className="d-td"></td>
                </tr >

                <tr className="d-tr">
                  <td className="d-td">sonstige &#946;-/&#947;-Strahler außer Pu-241 mit einem Massenanteil des Wassers bzw. der Restfeuchte im Abfallprodukt von: {"<"} 1% </td>
                  <td className="d-td">---</td>
                  <td className="d-td">3,7E+17</td>
                </tr>
                <tr className="d-tr">
                  <td className="d-td">sonstige &#946;-/&#947;-Strahler außer Pu-241 mit einem Massenanteil des Wassers bzw. der Restfeuchte im Abfallprodukt von: {">="} 1% </td>
                  <td className="d-td">---</td>
                  <td className="d-td">3,7E+15</td>
                </tr>
              </tbody>
            </table>
            </Expandable>
          </Expandable>

        </Expandable>
      </div>
    );
  }
}

export default ActivityLimitsView;
