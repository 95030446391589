import React, { Component } from "react";
import { NotificationContext } from "../context/NotificationContext";
import Alert from "./Alert";
import Notification from "./Notification";
class NotificationManager extends Component {
  constructor(props) {
    super(props);
    this.close = this.close.bind(this);
    this.show = this.show.bind(this);
    this.add = this.add.bind(this);
  }
  componentDidCatch(error) {
    this.add(error.message, true);
  }
  state = {
    notifications: [],
  };
  close(error) {
    error.shown = false;
    this.setState({});
  }
  add(msg, isError) {
    let errObj = { msg: msg, shown: false, isError: isError };
    this.state.notifications.push(errObj);
    //necessary for fade in
    setTimeout(() => {
      this.show(errObj);
    }, 100);
    let time = isError ? 60000 : 4500;
    setTimeout(() => {
      this.close(errObj);
    }, time);
    this.setState({ errors: this.state.errors });
  }
  show(error) {
    error.shown = true;
    this.setState({});
  }
  render() {
    return (
      <NotificationContext.Provider value={{ add: this.add }}>
        <div className="absolute flex flex-col gap-2 pr-5 items-end right-0 overflow-x-hidden bottom-10">
          {this.state.notifications.map((n, idx) => {
            if (n.isError) {
              return <Alert error={n} key={idx} close={this.close}></Alert>;
            }
            return <Notification key={idx} close={this.close} message={n}></Notification>;
          })}
        </div>
        {this.props.children}
      </NotificationContext.Provider>
    );
  }
}

export default NotificationManager;
