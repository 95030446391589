import { faPlus, faRightFromBracket, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { ProjectContext } from "../context/ProjectContext";
import ProjectCard from "./ProjectCard";
import ProjectModal from "./ProjectModal";

class ProjectManager extends Component {
  static contextType = ProjectContext;
  constructor(props) {
    super(props);

    this.selectProject = this.selectProject.bind(this);
  }
  state = { showModal: false };
  selectProject(p) {
    if (this.props.setSelected) {
      this.props.setSelected(p.id);
    }
  }

  render() {
    return (
      <div className="h-full flex items-center  flex-col gap-4 p-5">
        <h1 className="font-bold text-3xl  ">Projektübersicht</h1>
        <div className="flex gap-5 items-center">
          <div className=" flex  border border-gray-400 rounded-xl">
            <input type="text" className="rounded-l-xl" />
            <span className="border-r p-2 bg-gray-50 border-gray-300 rounded-r-xl">
              <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
            </span>
          </div>
          <button
            onClick={() => this.setState({ showModal: true })}
            className="p-2 rounded-xl font-bold flex justify-center items-center text-white bg-blue-500 border border-gray-500"
          >
            <FontAwesomeIcon icon={faPlus} />
          </button>
        </div>
        <div className="flex flex-wrap  gap-x-10 gap-y-5 w-full justify-center  p-10">
          {this.context.projects
            ? this.context.projects.sort((a, b) => {
              const nameA = a.name.toUpperCase(); // Nicht case-sensitive sortieren
              const nameB = b.name.toUpperCase();
              
              if (nameA < nameB) {
                return -1;
              } else if (nameA > nameB) {
                return 1;
              } else {
                return 0; // Die Namen sind gleich
              }
            }).map((p) => (
                <ProjectCard key={p.id} onClick={() => this.selectProject(p)}>
                  <div className="flex flex-col justify-center items-center max-w-xs">
                    <h2 title={p.name} className="font-bold">
                      {p.name.length > 25 ? p.name.slice(0, 25) + "..." : p.name}{" "}
                    </h2>
                    <p title={p.description}>
                      {p.description ? (p.description.length > 25 ? p.description.slice(0, 25) + "..." : p.description) : "Keine Beschreibung"}{" "}
                    </p>
                  </div>
                </ProjectCard>
              ))
            : null}
        </div>
        {this.state.showModal ? (
          <ProjectModal
            onClose={() => {
              this.setState({ showModal: false });
            }}
            onSubmit={(name, desc) => {
              this.setState({ showModal: false });

              this.context.create(name, desc);
            }}
          ></ProjectModal>
        ) : null}
      </div>
    );
  }
}

export default ProjectManager;
