import { faInfoCircle, faTrash, faQuestionCircle, faX, faAd, faAdd } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { addContainer, deleteAllContainer, deleteContainer } from "../api";
import { ContainerContext } from "../context/ContainerContext";
import { Parser } from "../Utility/Parser";
import InfoModal from "../Utility/InfoModal";
import Table from "../utilityComponents/Table";
import { validate } from "../Utility/ResponseConsumer";
import { getStringCompare, getStringFilter } from "../Utility/Utility";
import { ConfirmDialogContext } from "../context/ConfirmDialogContext";
import SButton from "../styledComponents/SButton";
class ConatinerView extends Component {
  static contextType = ContainerContext;
  static contextType2 = ConfirmDialogContext;
  constructor(props) {
    super(props);
    this.fileChange = this.fileChange.bind(this);
    this.addContainer = this.addContainer.bind(this)
    this.EditContainer = this.EditContainer.bind(this)

  }
  state = {
    editContainer: new Object
  };


  EditContainer(row) {
    
    this.setState({editContainer:this.context.container[row]})
    console.log(this.context.container[row])
  }

  fileChange(evt) {
    if (evt.target.files.length > 0) {

      let wastePackagesToSave = [];
      let promises = [];


      for (let file of evt.target.files) {
        let promise = new Promise(async (resolve, reject) => {
          Parser.parseContainer(file, (container) => {
            if (container == null) {
              reject(file.name);
            } else {
              wastePackagesToSave.push(container);
              resolve();
            }
          });
        })
        promises.push(promise);
      }
      const proccesFiles = async () => {
        await Promise.all(promises);
      };
      proccesFiles()
        .then(() => {
          let alreadyExsists = this.context.container.filter((x) => {
            return (

              wastePackagesToSave[0].filter((y) => {

                if (y.name === x.name) {
                  return true
                }

                return false;
              }).length > 0
            );
          });

          if (alreadyExsists.length > 0) {
            this.setState({ showModal: true, alreadyExsists: alreadyExsists, wastePackagesToSave: wastePackagesToSave })
          } else {
            this.addContainer(wastePackagesToSave);
          }
        }).catch((fileName) => {


          if (this.props.notification) {
            this.context.add("Abfallbehälter: " + fileName + " konnte nicht geladen werden!", true);
          }
        });
    }
  }

  addContainer(toSave, isUpdate) {
    if(Array.isArray(toSave)){
      toSave = toSave[0]
    }else{
      delete toSave.id
      toSave.containerType = toSave.containerType.name
      toSave.type = toSave.type.name
      toSave = [toSave]
    }
    this.setState({ showModal: false })
    addContainer(toSave, validate(
      (body) => {
        this.setState({editContainer: new Object})
        this.context.refresh(body);
        if (this.props.notification) {
          this.props.notification.add("Upload erfolgreich");
        }
      },
      (error) => {
        if (this.props.notification) {
          this.setState({editContainer: new Object})
          this.props.notification.add(error.message ? error.message : "Endlagerbehälter-Datei ungültig!", true);
        }
      },
    ),);
  }
  deleteContainer(id) {
    deleteContainer(
      id,
      validate(
        (body) => {
          this.context.refresh();
        },
        (error) => {
          if (this.props.notification) {
            this.props.notification.add(error.message ? error.message : "Abfallbehälter-Datei ungültig!", true);
          }
        },
      ),
    );
  }
  render() {
    return (

      <div className="p-2 flex flex-col gap-2">
        {this.state.editContainer.id !== undefined ?
          <div className="absolute flex w-screen h-screen top-0 left-0 justify-center items-center  z-20">
            <div
              className="bg-gray-100 flex flex-col gap-2 rounded-lg border max-w-md border-gray-600 shadow-lg overflow-hidden">
              <h1 className="font-bold bg-main text-white pl-2 py-1 ">
                {this.props.header ? (
                  this.props.header
                ) : (
                  <span className="flex gap-2 items-center justify-center ">


                    <span>Aktion bestätigen</span>
                    <FontAwesomeIcon icon={faQuestionCircle}></FontAwesomeIcon>

                  </span>
                )}
              </h1>

              <span className="text-center font-semibold px-2"><span style={{ display: "flex", flexDirection: "column" }}>
                
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "start", margin: "1rem", }}>
                  <p>Abfallbehälterklasse:</p>
                  <select value={this.state.editContainer.wasteContainerClass} style={{ marginLeft: "1rem", width: "100%", backgroundColor: "lightgrey", textAlign: "center", borderRadius: "4px" }}
                    onChange={(event) => {
                      this.setState(prevState => ({
                        editContainer: {
                          ...prevState.editContainer,
                          wasteContainerClass: Number.parseInt(event.target.value)
                        }
                      }
                      ))
                    }}>
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                  </select>
                </div>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "start", margin: "1rem", }}>
                  <p>Behältertyp:</p>
                  <select value={this.state.editContainer.containerType.name} style={{ marginLeft: "1rem", width: "100%", backgroundColor: "lightgrey", textAlign: "center", borderRadius: "4px" }}
                    onChange={(event) => {
                      this.setState(prevState => ({
                        editContainer: {
                          ...prevState.editContainer,
                          containerType: {name: event.target.value}
                        }
                      }
                      ))
                    }}>
                    <option value={"Gussbehälter"}>Gussbehälter</option>
                    <option value={"Betonbehälter"}>Betonbehälter</option>
                    <option value={"Container"}>Container</option>
                  </select>
                </div>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "start", margin: "1rem", }}>
                  <p>Typ:</p>
                  <select value={this.state.editContainer.type.name} style={{ marginLeft: "1rem", width: "100%", backgroundColor: "lightgrey", textAlign: "center", borderRadius: "4px" }}
                    onChange={(event) => {
                      this.setState(prevState => ({
                        editContainer: {
                          ...prevState.editContainer,
                          type: {name: event.target.value}
                        }
                      }
                      ))
                    }}>
                    <option value={"Typ I"}>Typ I</option>
                    <option value={"Typ II"}>Typ II</option>
                    <option value={"Typ II KfK"}>Typ II KfK</option>
                    { this.state.editContainer.containerType.id === 1 || this.state.editContainer.containerType.id === 3 ? <option value={"Typ III"}>Typ III</option>: null}
                    { this.state.editContainer.containerType.id === 3 ?<option value={"Typ IV"}>Typ IV</option>:null}
                    { this.state.editContainer.containerType.id === 3 ?<option value={"Typ V"}>Typ V</option>:null}
                    { this.state.editContainer.containerType.id === 3 ?<option value={"Typ VI"}>Typ VI</option>:null}
                  </select>
                </div>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", margin: "1rem", }}>
                  <p>Beschreibung:</p>
                  <textarea type="text"
                  onChange={(event) => {
                    this.setState(prevState => ({
                      editContainer: {
                        ...prevState.editContainer,
                        description: event.target.value
                      }
                    }
                    ))
                  }}
                  ></textarea>
                </div>

                <p></p>
                <p>
                  Sollen diese Daten <span className="font-bold">GESPEICHERT</span> werden?
                </p>
              </span></span>
              <div className="flex justify-around gap-5 border-t-2 border-gray-400 py-2">

                <SButton
                  onClick={() => {
                    this.setState({ editContainer: new Object })
                  }}>{this.props.cancelText ? this.props.cancelText : "Nein"}</SButton>
                <SButton disabled={this.props.disabled ? this.props.disabled : false}
                  onClick={
                    () => {
                    
                      this.addContainer(this.state.editContainer);
                    }
                  }>
                  {this.props.confirmText ? this.props.confirmText : "Ja"}
                </SButton>
              </div>
            </div>
          </div>
          : null
        }
        {this.state.showModal ? <div className="absolute flex w-screen h-screen top-0 left-0 justify-center items-center  z-20">
          <div
            className="bg-gray-100 flex flex-col gap-2 rounded-lg border max-w-md border-gray-600 shadow-lg overflow-hidden">
            <h1 className="font-bold bg-main text-white pl-2 py-1 ">
              {this.props.header ? (
                this.props.header
              ) : (
                <span className="flex gap-2 items-center justify-center ">


                  <span>Aktion bestätigen</span>
                  <FontAwesomeIcon icon={faQuestionCircle}></FontAwesomeIcon>

                </span>
              )}
            </h1>

            <span className="text-center font-semibold px-2"><span>
              <p>Entsprechende Daten für folgende Abfallbehälter sind bereits vorhanden: </p>
              <p>{this.state.alreadyExsists.map((x) => x.name + " ")}</p>
              <p>
                Sollen diese Daten <span className="font-bold">ÜBERSCHRIEBEN</span> werden?
              </p>
            </span></span>
            <div className="flex justify-around gap-5 border-t-2 border-gray-400 py-2">

              <SButton
                onClick={() => this.setState({ showModal: false })}>{this.props.cancelText ? this.props.cancelText : "Nein"}</SButton>
              <SButton disabled={this.props.disabled ? this.props.disabled : false}
                onClick={() => this.addContainer(this.state.wastePackagesToSave)}>
                {this.props.confirmText ? this.props.confirmText : "Ja"}
              </SButton>
            </div>
          </div>
        </div> : null}

        <div className="flex gap-2 align-middle items-center">
          <label htmlFor="upload" className={"border border-gray-400 rounded-lg px-2 bg-gray-300 hover:bg-orange-200 "}>
            CSV hochladen
          </label>
          <input className="hidden" id="upload" type="file" onChange={this.fileChange} />
          <FontAwesomeIcon
            size="lg"
            onClick={() => this.setState({ showHelp: true })}
            className="text-gray-500 hover:text-opacity-70"
            icon={faInfoCircle}
          ></FontAwesomeIcon>
        </div>
        <ConfirmDialogContext.Consumer>
          {(ctx) => (
            <Table
            disableEdit={true}
            EditAbfallsgebinde={this.EditContainer}
              rows={this.context.container.map((x) => [
                x.name ? x.name : "-",
                x.wasteContainerClass ? x.wasteContainerClass : "-",
                x.containerType ? x.containerType.name : "-",
                x.type ? x.type.name : "-",
                x.description ? <p title={x.description}>{x.description.length > 40 ? x.description.slice(0, 40) + "..." : x.description}</p> : "-",
              ])}
              options={[
                {
                  label: <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>,
                  onClick: (rows) => {
                    let names = rows.map((x) => x[0]);
                    let ids = this.context.container.filter((x) => {
                      return names.includes(x.name);
                    });
                    ids = ids.map((x) => x.id);
                    ctx.toggle(
                      "Wollen Sie die augewählten Abfallbehälter unwiederruflich löschen?",
                      () => {
                        deleteAllContainer(
                          ids,
                          validate(
                            () => {
                              this.context.refresh();
                              if (this.props.notification) {

                                this.props.notification.add("Abfallbehälter wurden gelöscht!");
                              }
                            },
                            (error) => {
                              if (this.props.notification) {
                                this.props.notification.add(error.message ? error.message : "Abfallbehälter konnten nicht gelöscht werden!", true);
                              }
                            },
                          ),
                        );
                        ctx.toggle();
                      },
                      () => {
                        ctx.toggle();
                      },
                    );
                  },
                },
              ]}
              header={[
                { text: "Bezeichnung", filter: getStringFilter(), compare: getStringCompare() },
                "Abfallbehälterklasse",
                "Behältertyp",
                "Typ",
                "Beschreibung",
              ]}
            ></Table>
          )}
        </ConfirmDialogContext.Consumer>
        {this.state.showHelp ? (
          <InfoModal close={() => this.setState({ showHelp: false })}>
            <h1>Beispiel für eine Abfallbehälter Datei</h1>
            <Table
              disableSearch={true}
              disableSelect={true}
              header={["Bezeichnung", "Abfallbehälterklasse", "Behältertyp", "Typ", "Beschreibung"]}
              rows={[
                [
                  "KC_I",
                  "1",
                  "Container",
                  "Typ II",
                  "Meine Beschreibung",
                ],
                [
                  "MOSAIK_120_2",
                  "2",
                  "Gussbehälter",
                  "Typ II",
                  "Meine Beschreibung",
                ],
                ["...", "...", "...", "...", "..."],
              ]}
            ></Table>
          </InfoModal>
        ) : null}
      </div>
    );
  }
}

export default ConatinerView;
