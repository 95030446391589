import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import SButton from "../styledComponents/SButton";
class ProjectModal extends Component {
  constructor(props) {
    super(props);
  }
  state = { name: "", desc: "" };
  render() {
    return (
      <div className="top-0 flex absolute justify-center items-center w-full h-full backdrop-blur-sm">
        <div className="relative p-5 bg-gray-300 bg-opacity-90 rounded-lg border-2 border-gray-500 flex gap-2 flex-col justify-center">
          <h2 className="text-lg font-extrabold">Projekt erstellen</h2>
          <div className="flex justify-between ">
            <label className="font-semibold">Name</label>
            <input value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} className="rounded-lg"></input>
          </div>
          <div className="flex justify-between gap-2">
            <label className="font-semibold">Beschreibung</label>
            <textarea value={this.state.desc} onChange={(e) => this.setState({ desc: e.target.value })} className="rounded-lg"></textarea>
          </div>
          <SButton
            disabled={this.state.name === ""}
            onClick={() => {
              if (this.props.onSubmit) {
                this.props.onSubmit(this.state.name, this.state.desc);
              }
            }}
          >
            Erstellen
          </SButton>
          <button
            onClick={() => {
              if (this.props.onClose) {
                this.props.onClose();
              }
            }}
            className="absolute top-2 right-6"
          >
            <FontAwesomeIcon className="text-red-500" size="lg" icon={faClose}></FontAwesomeIcon>
          </button>
        </div>
      </div>
    );
  }
}

export default ProjectModal;
