import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Main from "./Main";
import NotificationManager from "./utilityComponents/NotificationManager";

Number.prototype.getPretty = function (digits = 2) {
  let string = this.toExponential(digits);
  if (string.length === 7) {
    string = string.substring(0, string.length - 1) + "0" + string[6];
  }
  string = string.replace("e", "E");
  string = string.replace(".", ",");
  return string;
};
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <NotificationManager>
      <>
        <Main />
      </>
    </NotificationManager>
  </React.StrictMode>,
);
