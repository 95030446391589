import {Component} from "react";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTriangleExclamation} from "@fortawesome/free-solid-svg-icons";

class WastePackageWarning extends Component {
    constructor(props) {

        super(props);
        this.state = {
            isHovered: false,
        };
    }

    handleMouseEnter = () => {
        this.setState({isHovered: true});
    };

    handleMouseLeave = () => {
        this.setState({isHovered: false});
    };

    render() {
        const {isHovered} = this.state;
        let text = ""
        if(this.props.type === 3){
text = "Einzelnuklidausschöpfung mit um Faktor 1E-04 reduziertem grenzwert beträgt: " + this.props.wert
        }else if(this.props.type === 1){
            text = "Zur Berechnung des Störfallsummenwertes werden die Aktivitätsgrenzwerte der APG 06 verwendet"
        }
        else {
            text = "Zur Berechnung des ausgeschöpften Störfallsummenwertes werden die Aktivitätsgrenzwerte der APG 06 verwendet"
        }
        return (
            <div className="flex justify-center relative">
                <FontAwesomeIcon
                    icon={faTriangleExclamation}
                    onMouseEnter={this.handleMouseEnter}
                    onMouseLeave={this.handleMouseLeave}
                    width={"20px"}
                    size="lg"
                    className="text-gray-500 cursor-pointer hover:text-gray-400 ml-2 -mr-7"
                />
                {isHovered &&
                
            
                    <p className="absolute bg-gray-50 w-52 ml-80 -mt-4 rounded-2xl block ">
                        {text}</p>}
            </div>
        );
    }
}

export default WastePackageWarning;