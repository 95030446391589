import React, { Component } from "react";
import getId from "../Utility/IDGenerator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faX } from "@fortawesome/free-solid-svg-icons";
class SCheckbox extends Component {
  constructor(props) {
    super(props);
    this.id = getId();
  }
  state = {};
  render() {
    return (
      <>
        <label htmlFor={this.id}>
          {/*this.props.checked? 
          <input type="checkbox" className={" text-xl w-4 max-h-xl "} checked={true}></input>:<input type="checkbox" className={" text-xl w-4 max-h-xl "} checked={false}></input>
        }
          
          {/*<FontAwesomeIcon
            className={" text-xl w-4 max-h-xl " + (this.props.checked ? "text-green-600" : "text-red-600")}
            icon={this.props.checked ? faCheck : faX}
    ></FontAwesomeIcon>*/}
        </label>
        <input {...this.props} id={this.id} className="" type="checkbox" />
      </>
    );
  }
}

export default SCheckbox;
