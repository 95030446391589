import { faTrashAlt, faTrashCan} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import Table from "./Table";
import { ProjectContext } from "../context/ProjectContext";
import { UserContext } from "../context/UserContext";
import { faUndo, faX } from "@fortawesome/free-solid-svg-icons";
import SButton from "../styledComponents/SButton";
import { updateMember, changeProjectOwner } from "../api";
import { validate } from "../Utility/ResponseConsumer";
import getId from './../Utility/IDGenerator';
class MemberManager extends Component {
  static contextType = ProjectContext;
  constructor(props) {
    super(props);
    this.addUser = this.addUser.bind(this);
    this.doNotAdd = this.doNotAdd.bind(this);
    this.removeUser = this.removeUser.bind(this);
    this.save = this.save.bind(this);
    this.changeOwner = this.changeOwner.bind(this);
  }
  state = { filter: "", userToAdd: [], userToRemove: [], showOptions: false, tempOwner: "" };
  filterUser(filter, users) {
   
    if (filter === "") {
      return [];
    }
    let res = users.filter((u) => {
      if (this.context.project.member.includes(u.id)) {
        return false;
      }
      if (this.state.userToAdd.includes(u)) {
        return false;
      }

      if (u.username) {
        return u.username.toUpperCase().includes(filter.toUpperCase()) ;
      } else {
        return false;
      }
    });
    return res;
  }

  save() {
    let tmp = this.context.project.member.filter((x) => !this.state.userToRemove.includes(x));
    let addIds = this.state.userToAdd.map((x) => x.id);
    
    tmp.push(...addIds);
  
    //TODO 
    if(this.context.project.tempOwner !== this.context.project.owner){
      changeProjectOwner(this.context.project.id, this.context.project.tempOwner, 
        validate(
          (p) => {
            this.context.project.owner = this.context.project.tempOwner  
            this.context.refresh(p);
            
            this.setState({ userToAdd: [], userToRemove: [] });
            if (this.props.notification) {
              this.props.notification.add("Änderungen erfolgreich übernommen!");
            }
          },
          (error) => {
            if (this.props.notification) {
              this.context.project.tempOwner = this.context.project.owner
              this.context.refresh(this.component.project)
              this.props.notification.add(error.message, true);
            }
          },
        )
        )
    }

    updateMember(
      this.context.project.id,
      tmp,
      validate(
        
        (p) => {
          
          this.context.refresh(p);
          this.setState({ userToAdd: [], userToRemove: [] });
          if (this.props.notification) {
            this.props.notification.add("Änderungen erfolgreich übernommen!");
          }
        },
        (error) => {
          if (this.props.notification) {
            this.props.notification.add(error.message, true);
          }
        },
      ),
    );

    

  }
  removeUser(user) {
    this.state.userToRemove.push(user);
    this.setState({ userToRemove: this.state.userToRemove });
  }
  addUser(user) {
    this.state.userToAdd.push(user);
    this.setState({ userToAdd: this.state.userToAdd, showOptions: false });
  }
  doNotAdd(user) {
    this.setState({ userToAdd: this.state.userToAdd.filter((x) => x !== user) });
  }
  doNotRemove(user) {
    this.setState({ userToRemove: this.state.userToRemove.filter((x) => x !== user) });
  }
  changeOwner(event){
    this.context.project.tempOwner = event.target.value
    this.context.refresh(this.context.project)
    
  }
  
  render() {
    let { user } = this.props;
    if (!user) {
      user = [];
    }
    let member = this.context.project.member;
    
    if (this.context.project.tempOwner === undefined){
      this.context.project.tempOwner = this.context.project.owner
    }
    return (
      <UserContext.Consumer>
        {(ctx) => {
          let rows = member.map((m) => {
            {
              ctx.tempOwner = this.context.project.owner
              let real = ctx.user.find((x) => x.id === m);
              if (real) {
           
                
                if (this.state.userToRemove.includes(m)) {
                  
                  return [
                    <span className="line-through font-bold style text-red-700 w-full">{real.username}</span>,
                    <div>
                  <label >
                  <input
                    className=" bg-red-700"
                    type="radio"
                    value={real.id}
                    checked={parseInt(this.context.project.tempOwner) === real.id }
                    onChange={this.changeOwner}
                    disabled
                  />
                 
                </label>
                </div>,
                <div>{real.id !== this.context.project.owner ? <FontAwesomeIcon className=" w-4 h-4  relative" icon={faUndo} onClick={() => this.doNotRemove(real.id)}></FontAwesomeIcon>: <span></span>}</div>,
              
                  ];
                }
                return [
                  real.username,
                  <div>
                  <label >
                  <input
                    type="radio"
                    value={real.id}
                    checked={parseInt(this.context.project.tempOwner) === real.id }
                    onChange={this.changeOwner}
                  />
                 
                </label>
                </div>,
                
                 <div>{real.id !== this.context.project.owner ? <FontAwesomeIcon className=" w-4 h-4  relative"  icon={faTrashCan} onClick={() => this.removeUser(real.id)}></FontAwesomeIcon>: <span></span>}</div>,
              
                ];
              } else {
                return ["Loading", "Loading"];
              }
            }
          });
          this.state.userToAdd.forEach((x) =>
            rows.push([x.username, "MEMBER", <FontAwesomeIcon onClick={() => this.doNotAdd(x)} icon={faX}></FontAwesomeIcon>]),
          );
          let filtredUser = this.filterUser(this.state.filter, ctx.user);
          return (
            <div className="flex flex-col">
              <div className="flex flex-col overflow-y-visible h-8  ">
                <input
                  onFocus={() => this.setState({ showOptions: true })}
                  className={" rounded-t-md  outline-none px-1 border border-black"}
                  placeholder="Benutzer suchen ..."
                  onChange={(evt) => {
                    this.setState({ filter: evt.target.value });
                  }}
                ></input>
                {this.state.showOptions && filtredUser.length > 0 ? (
                  <div className="bg-gray-300 z-30 w-full  border border-t-0 border-black rounded-b-md  ">
                    {filtredUser.map((user, idx) => {
                      return (
                        <p key={idx} onClick={() => this.addUser(user)} className="hover:bg-blue-500 hover:cursor-pointer">
                          {user.username}
                        </p>
                      );
                    })}{" "}
                  </div>
                ) : null}
              </div>
              <div className="w-full flex flex-col  gap-2">
                <Table disableEdit={true} header={["Nutzername", "Besitzer", ""]} disableSearch={true} disableSelect={true} rows={rows}></Table>
                <SButton onClick={this.save}>Speichern</SButton>
              </div>
            </div>
          );
        }}
      </UserContext.Consumer>
    );
  }
}
export default MemberManager;
