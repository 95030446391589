import { faE, faInfoCircle, faS, faTrash, faZ, faSquareCheck, faCheck, faAdd, faX, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { addWastePackage, getTable10Nuclides, getContainer } from "../api";
import { validate } from "../Utility/ResponseConsumer";
import InfoModal from "../Utility/InfoModal";
import { Parser } from "../Utility/Parser";
import { calcSumExpo } from "../Utility/Utility";
import Table from "../utilityComponents/Table";
import WastePackageDetailView from "./WastePackageDetailView";
import WastePackageWarning from "./WastePackageWarning";
import { ConfirmDialogContext } from "../context/ConfirmDialogContext";
import { LoadingContext } from "../context/LoadingContext";
import ConfirmDialog from "../utilityComponents/ConfirmDialog";
import SInput from "../styledComponents/SInput";
import ReactDOM from 'react-dom';

import { icon } from '@fortawesome/fontawesome-svg-core';
import { WastePackage } from './../data/WastePackage';
import SButton from "../styledComponents/SButton";
const header = [
  {
    text: "ID-Abfallgebinde",
    compare: (a, b) => {
      return a.props.children.toString().localeCompare(b.props.children);
    },
    filter: (filter, x) => x.props.children.toLocaleUpperCase().includes(filter.toLocaleUpperCase()),
  },
  {
    text: "Abfallbehälter",
    compare: (a, b) => {
      return a.props.children.toString().localeCompare(b.props.children);
    },
    filter: (filter, x) => x.props.children.toLocaleUpperCase().includes(filter.toLocaleUpperCase()),
  },
  "Abfallproduktgruppe",
  "störfallfest",
  "Schnittprodukte",
  {
    text: "Gesamtaktivität [Bq]",
    compare: (a, b) => {
      let c = parseFloat(a.props.children.props.children.replace(",", "."));
      let d = parseFloat(b.props.children.props.children.replace(",", "."));
      return c - d;
    },
  }, {
    text: "Störfallsummenwert", compare: (a, b) => {

      let c = parseFloat(a.props.children.props.children[0].props.children.replace(",", "."));
      let d = parseFloat(b.props.children.props.children[0].props.children.replace(",", "."));
      return c - d;
    },
  }, {
    text: "Ausschöpfung Störfallsummenwert", compare: (a, b) => {
      let c = parseFloat(a.props.children.props.children[0].props.children.replace(",", "."));
      let d = parseFloat(b.props.children.props.children[0].props.children.replace(",", "."));
      return c - d;
    },
  }, "Info",];

class WastePackgeView extends Component {
  static contextType = LoadingContext;
  constructor(props) {
    super(props);
    this.fileChange = this.fileChange.bind(this);
    this.removeSelected = this.removeSelected.bind(this);
    this.addWastePackage = this.addWastePackage.bind(this)
    this.EditAbfallsgebinde = this.EditAbfallsgebinde.bind(this)
  }
  state = {
    selected: null,
    filter: "",
    cutoff: "0.1",
    resultExport: { show: false, exportWithoutHyphen: false, cutoff: "0.1", customCutoff: 0.01, disabled: false },
    exportWithoutHyphen: false,
    editWastepackageRow: new Object,
    selectedRows: [],
    table10: []
  };
  componentDidMount() {
    getContainer(validate((body) => {
      this.setState({ container: body })
    }))

    let list = []
    getTable10Nuclides(validate((body) => {
      body.forEach(nuclide => {
        list.push(nuclide.name)
      })
    }),
    );

    this.setState({ table10: list })
  }

  getCutpieceName(id) {

    const foundObject = this.props.pContext.project.cutPieces.find((obj) => obj.id === id);

    // Wenn ein Objekt mit der angegebenen ID gefunden wurde, den Namen zurückgeben, ansonsten null

    return foundObject ? foundObject.name : null;
  }

  EditAbfallsgebinde(row) {
    
    this.state.editWastepackageRow = this.props.pContext.project.wastePackages[row]
    this.setState({ selectedCutpiece: this.props.pContext.project.cutPieces[0].id })
  }


  fileChange(evt) {
    if (evt.target.files.length > 0) {
      let wastePackagesToSave = [];
      let promises = [];

      this.context.toggle();
      for (let file of evt.target.files) {
        let promise = new Promise(async (resolve, reject) => {
          Parser.parseWastePackages(file, (wastePackage) => {
            if (wastePackage == null) {
              reject(file.name);
            } else {
              wastePackagesToSave.push(wastePackage);
              resolve();
            }
          });
        })
        promises.push(promise);
      }
      const proccesFiles = async () => {
        await Promise.all(promises);
      };
      proccesFiles()
        .then(() => {
          let alreadyExsists = this.props.pContext.project.wastePackages.filter((x) => {
            return (

              wastePackagesToSave[0].filter((y) => {

                if (y.name === x.name) {
                  return true
                }

                return false;
              }).length > 0
            );
          });

          if (alreadyExsists.length > 0 && this.props.confirmContext) {

            this.context.toggle();

            this.props.confirmContext.toggle(
              <span>
                <p>Entsprechende Daten für folgende Abfallgebinde sind bereits vorhanden: </p>
                <p>{alreadyExsists.map((x) => x.name + " ")}</p>
                <p>
                  Sollen diese Daten <span className="font-bold">ÜBERSCHRIEBEN</span> werden?
                </p>
              </span>,
              () => {
                this.props.confirmContext.toggle();
                this.context.toggle();
                this.addWastePackage(wastePackagesToSave);
              },
              () => {
                this.props.confirmContext.toggle();
              },
            );
          } else {
            this.addWastePackage(wastePackagesToSave);
          }
        })
        .catch((fileName) => {
          this.context.toggle();
          if (this.props.notification) {
            this.props.notification.add("Abfallgebindedatei: " + fileName + " konnte nicht geladen werden!", true);
          }
        });
    }
  }

  addWastePackage(toSave) {
   
    this.setState({ editWastepackageRow: new Object })
    if(Array.isArray(toSave)){
      toSave = toSave[0]
    }else{

      let stringCutpieces = []
      let temp = this.props.pContext.project.cutPieces
      delete toSave.mass
      delete toSave.activities
      delete toSave.criticalError
      delete toSave.criticalityValues
      delete toSave.error
      delete toSave.guranteedError
      delete toSave.guranteedValues
      delete toSave.incidentError
      delete toSave.incidentValues
      delete toSave.thermalInfluenceError
      delete toSave.thermalValues
      delete toSave.id
    
      toSave.cutPieces.forEach(cutpiece => {
         stringCutpieces.push({name: temp.find( obj => obj.id === cutpiece).name}) 
      })
      toSave.container = {name: toSave.container.name}
      toSave.cutPieces = stringCutpieces
      toSave = [toSave]
    }
    addWastePackage(this.props.pContext.project.id, toSave, (status, body) => {
      if (status !== 200) {
        if (this.props.notification) {
          this.props.notification.add(body.message, true);
        }
      } else {
        if (this.props.pContext) {
          this.props.pContext.refresh(body);
        }
        if (this.props.notification) {
          this.props.notification.add("Upload erfolgreich!");
        }
      }
      this.context.toggle();
    });
  }


  setSelected(c) {
    c.activities.nuclideActivities = c.activities.nuclideActivities.sort((a, b) => a.nuclide.localeCompare(b.nuclide));
    this.setState({ selected: c });
  }

  removeSelected() {

    this.setState({ selected: null });
  }

  cutpiecesNames(cps) {
    let string = "";
    for (let cp of cps) {
      if (string.length > 15) {
        string += "...";
        break;
      }
  
      let c = this.props.pContext.project.cutPieces.find((y) => y.id === cp);
      if(c !== undefined){
        string += c.name;
      }
     
      string += " ";
    }
    return <p>{string}</p>;
  }

  

  handleMouseEnter = () => {
    this.setState({ isHovered: true });
  };

  handleMouseLeave = () => {
    this.setState({ isHovered: false });
  };

  render() {
    const warningComponent1 = <WastePackageWarning type={1} />
    const warningComponent2 = <WastePackageWarning type={2} />
    if (!this.state.selected) {
      let container = [];
      if (this.props.pContext) {
        container = this.props.pContext.project.wastePackages;

      }
      if (this.state.filter !== "") {
        container = container.filter((c) => c.id.toLocaleUpperCase().includes(this.state.filter.toLocaleUpperCase()));
      }

      return (
        <div className="flex flex-col gap-4 p-2">
          {this.state.editWastepackageRow.id !== undefined ?
            <div className="absolute flex w-screen h-screen top-0 left-0 justify-center items-center  z-20">
              <div
                className="bg-gray-100 flex flex-col gap-2 rounded-lg border max-w-md border-gray-600 shadow-lg overflow-hidden">
                <h1 className="font-bold bg-main text-white pl-2 py-1 ">
                  {this.props.header ? (
                    this.props.header
                  ) : (
                    <span className="flex gap-2 items-center justify-center ">


                      <span>Aktion bestätigen</span>
                      <FontAwesomeIcon icon={faQuestionCircle}></FontAwesomeIcon>

                    </span>
                  )}
                </h1>

                <span className="text-center font-semibold px-2"><span style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ display: "flex", flexDirection: "row", justifyContent: "start", margin: "1rem", }}>
                    <p>Abfallbehälter:</p>
                    <select value={this.state.editWastepackageRow.container.name} style={{ marginLeft: "1rem", width: "100%", backgroundColor: "lightgrey", textAlign: "center", borderRadius: "4px" }}
                      onChange={(event) => {
                        console.log(event.target.value)
                        this.setState(prevState => ({
                          editWastepackageRow: {
                            ...prevState.editWastepackageRow,
                            container: {
                              name: event.target.value
                            }
                          }
                        }
                        ))
                      }}>
                      {this.state.container !== undefined ? this.state.container.map(cont => {
                        return <option key={cont.id} value={cont.name}>{cont.name}</option>
                      }) : null}
                    </select>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row", justifyContent: "start", margin: "1rem", }}>
                    <p>Abfallproduktgruppe:</p>
                    <select value={this.state.editWastepackageRow.wasteGroup} style={{ marginLeft: "1rem", width: "100%", backgroundColor: "lightgrey", textAlign: "center", borderRadius: "4px" }}
                      onChange={(event) => {
                        this.setState(prevState => ({
                          editWastepackageRow: {
                            ...prevState.editWastepackageRow,
                            wasteGroup: Number.parseInt(event.target.value)
                          }
                        }
                        ))
                      }}>
                      <option value={1}>1</option>
                      <option value={2}>2</option>
                      <option value={3}>3</option>
                      <option value={4}>4</option>
                      <option value={5}>5</option>
                      <option value={6}>6</option>
                    </select>
                  </div>
                  <div style={{ display: "flex", flexDirection: "column", justifyContent: "start", }}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "start", margin: "1rem", alignItems: "center" }}>
                      <p>Schnittprodukte:</p>
                      <select style={{ marginLeft: "1rem", width: "100%", backgroundColor: "lightgrey", textAlign: "center", borderRadius: "4px" }}
                        onChange={(event) => {
                          this.setState({ selectedCutpiece: event.target.value })
                        }}>
                        {this.props.pContext.project.cutPieces.map(cutpiece => {
                          return <option key={cutpiece.id} value={cutpiece.id}>{cutpiece.name}</option>
                        })}
                      </select><FontAwesomeIcon onClick={() => {
                        this.setState(prevState => ({
                          editWastepackageRow: {
                            ...prevState.editWastepackageRow,
                            cutPieces: [...prevState.editWastepackageRow.cutPieces, Number.parseInt(this.state.selectedCutpiece)]
                          }
                        }
                        ))

                      }} className="ml-2" icon={faAdd}></FontAwesomeIcon>
                      {/*TODO FontAwesomeIcon hinzufügen mit chekch und uncheck auch unten*/}
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "start", margin: "1rem", maxHeight: "25vh", overflow: "auto"}}>
                      {this.state.editWastepackageRow.cutPieces !== undefined ? this.state.editWastepackageRow.cutPieces.map((cutpiece, index) => {
                        
                        return (<div key={index} style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}><p>{ this.props.pContext.project.cutPieces.find((obj) => obj.id === cutpiece).name}</p>
                          <FontAwesomeIcon className="ml-2" icon={faX}
                          onClick={() => {
                            let removed = false;
                            this.setState(prevState => ({
                              editWastepackageRow: {
                                ...prevState.editWastepackageRow,
                                cutPieces: prevState.editWastepackageRow.cutPieces.filter(
                                  (id => {
                                    if (!removed && id === cutpiece) {
                                      removed = true; 
                                      return false; 
                                    }
                                    return true; 
                                  })
                                )
                              }
                            }
                            ))
                          }}
                          ></FontAwesomeIcon></div>)

                      }) : null} </div>
                  </div>

                  <div style={{ display: "flex", flexDirection: "row", justifyContent: "start", margin: "1rem" }}>
                    <p>Störfallfest : </p>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly", marginLeft: "1rem" }}>
                      <p style={{ marginLeft: "1rem" }}>ja</p>
                      <input style={{ marginLeft: "1rem" }} type="Radio" checked={this.state.editWastepackageRow.failureresistant === true}
                      onChange={ () => {
                        this.setState(
                          prevState => ({
                            editWastepackageRow: {
                              ...prevState.editWastepackageRow,
                              failureresistant: true
                            }
                          }))
                      }}
                      ></input>
                      <p style={{ marginLeft: "1rem" }}>Nein</p>
                      <input style={{ marginLeft: "1rem" }} type="Radio" checked={this.state.editWastepackageRow.failureresistant === false}
                        onChange={() => {
                          this.setState(
                            prevState => ({
                              editWastepackageRow: {
                                ...prevState.editWastepackageRow,
                                failureresistant: false
                              }
                            }))}
                        }

                      ></input>
                    </div>
                  </div>
                  <p></p>
                  <p>
                    Sollen diese Daten <span className="font-bold">GESPEICHERT</span> werden?
                  </p>
                </span></span>
                <div className="flex justify-around gap-5 border-t-2 border-gray-400 py-2">

                  <SButton
                    onClick={() => {

                      this.setState({ editWastepackageRow: new Object })
                    }}>{this.props.cancelText ? this.props.cancelText : "Nein"}</SButton>
                  <SButton disabled={this.props.disabled ? this.props.disabled : false}
                    onClick={
                      () => {
                      
                        this.context.toggle();
                        this.addWastePackage(this.state.editWastepackageRow);
                      }
                    }>
                    {this.props.confirmText ? this.props.confirmText : "Ja"}
                  </SButton>
                </div>
              </div>
            </div>
            : null
          }
          <h1 className="text-lg font-bold">Abfallgebinde</h1>
          <div className="flex gap-2 items-center">
            <label htmlFor="upload"
              className={"border rounded-lg border-gray-400 px-2 bg-gray-300 hover:bg-orange-200 "}>
              CSV hochladen
            </label>
            <input className="hidden" id="upload" type="file" onChange={this.fileChange} />
            <FontAwesomeIcon
              size="lg"
              onClick={() => this.setState({ showHelp: true })}
              className="text-gray-500 hover:text-opacity-70"
              icon={faInfoCircle}
            ></FontAwesomeIcon>
          </div>
          <ConfirmDialogContext.Consumer>

            {(ctx) => (<Table
              header={header}
              EditAbfallsgebinde={this.EditAbfallsgebinde}
              rows={container.map((container) => {
                return [container.name, container.container.name, container.wasteGroup, container.failureresistant ? "Ja" : "Nein", this.cutpiecesNames(container.cutPieces),
                <p className="italic">{container.activities && !container.error ? container.activities.sumAct.getPretty(2) : "---"}</p>,
                <div className={"flex justify-center"}>

                  <p className="italic " >{container.incidentValues && !container.error ? calcSumExpo(container).toFixed(3).replace(".", ",") : "---"}</p>

                  {container.failureresistant && container.container.wasteContainerClass === 1 && container.wasteGroup !== 6 ? warningComponent1 : null}
                </div>, <div className={"flex justify-center "}>

                  <p className="italic ">{container.incidentValues && !container.error ? (calcSumExpo(container) * (container.failureresistant ? 1 : 10)).toFixed(3).replace(".", ",") : "---"}</p>


                  {container.failureresistant && container.container.wasteContainerClass === 1 && container.wasteGroup !== 6 ? warningComponent2 : null}
                </div>, <FontAwesomeIcon
                  onClick={() => this.setSelected(container)}
                  size="lg"
                  className="text-gray-500 cursor-pointer hover:text-gray-400"
                  icon={faInfoCircle}
                ></FontAwesomeIcon>,].map((x) => (<span
                  className={"block w-full " + (container.error || container.incidentError || container.thermalInfluenceError || container.guranteedError || container.criticalError ? "bg-yellow-100" : " ")}
                >
                  {x}
                </span>
                ));
              })}
              options={[
                {
                  label: <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>,
                  onClick: (rows) => {
                    let names = rows.map((x) => x[0].props.children);
                    let ids = this.props.pContext.project.wastePackages.filter((x) => {
                      return names.includes(x.name);
                    });
                    ids = ids.map((x) => x.id);
                    ctx.toggle(
                      "Wollen Sie die augewählten Abfallgebinde unwiederruflich löschen?",
                      () => {
                        this.props.pContext.removeWastePackages(ids);
                        ctx.toggle();
                      },
                      () => {
                        ctx.toggle();
                      },
                    );
                  },
                },
                {
                  label: <FontAwesomeIcon title="Export der Aktivitäten auf Segmente runtergebrochen" icon={faS}></FontAwesomeIcon>,
                  onClick: (rows) => {

                    var callback = (exportWithoutHyphen) => {


                      let names = rows.map((x) => x[0].props.children);
                      let ids = this.props.pContext.project.wastePackages.filter((x) => {
                        return names.includes(x.name);
                      });
                      ids = ids.map((x) => x.id);
                      this.props.pContext.exportWastepackageSegments(ids, exportWithoutHyphen);

                    };
                    callback = callback.bind(this);

                    ctx.toggle(
                      <form>
                        <div className="form-row">
                          <label htmlFor="nuc_checkbox">Nuklide ohne Bindestrich </label>
                          <input
                            defaultChecked={this.state.exportWithoutHyphen}
                            onChange={() => {
                              this.setState({ exportWithoutHyphen: !this.state.exportWithoutHyphen });
                            }}
                            className="w-100"
                            id="nuc_checkbox"
                            type="checkbox"
                          />
                        </div>
                      </form>,
                      () => {
                        ctx.toggle();

                        callback(this.state.exportWithoutHyphen);
                      },
                      () => {
                        ctx.toggle();
                      },
                      "Export",
                      "Abbrechen",
                    );
                  },
                },
                {
                  label: <FontAwesomeIcon title="Export der Abfallgebinde auf Segmentebene als ZIP-Datei" icon={faZ}></FontAwesomeIcon>,
                  onClick: (rows) => {
                    var callback = (exportWithoutHyphen) => {
                      let names = rows.map((x) => x[0].props.children);
                      let ids = this.props.pContext.project.wastePackages.filter((x) => {
                        return names.includes(x.name);
                      });
                      ids = ids.map((x) => x.id);
                      this.props.pContext.exportWastepackageSegmentsAsZip(ids, exportWithoutHyphen);
                    };
                    callback = callback.bind(this);
                    ctx.toggle(
                      <form>
                        <div className="form-row">
                          <label htmlFor="nuc_checkbox">Nuklide ohne Bindestrich </label>
                          <input
                            defaultChecked={this.state.exportWithoutHyphen}
                            onChange={() => {
                              this.setState({ exportWithoutHyphen: !this.state.exportWithoutHyphen });
                            }}
                            className="w-100"
                            id="nuc_checkbox"
                            type="checkbox"
                          />
                        </div>
                      </form>,
                      () => {
                        ctx.toggle();

                        callback(this.state.exportWithoutHyphen);
                      },
                      () => {
                        ctx.toggle();
                      },
                      "Export",
                      "Abbrechen",
                    );
                  },
                },
                {
                  label: <FontAwesomeIcon title="Export der Ergebnisdaten" icon={faE}></FontAwesomeIcon>,

                  onClick: (rows) => {


                    this.setState((prevState) => ({

                      selectedRows: rows,
                      resultExport: {
                        ...prevState.resultExport,
                        show: true,
                      },

                    }));

                  },
                },
              ]}
            ></Table>
            )}
          </ConfirmDialogContext.Consumer>
          {
            this.state.showHelp ? (
              <InfoModal close={() => this.setState({ showHelp: false })}>
                <h1>Beispiel für eine Abfallgebinde-datei</h1>
                <Table

                  disableEdit={true}
                  disableSearch={true}
                  disableSelect={true}
                  header={["ID-Abfallgebinde", "Abfallbehälter", "Abfallproduktgruppe", "störfallfest", "Schnittstück No.1 ", "Schnittstück No.2", "Schnittstück No. ..."]}
                  rows={[
                    [
                      "1",
                      "KC II",
                      "1",
                      "Ja",
                      "Schnittstück 1",
                      "Schnittstück 5",
                      "Schnittstück ...",
                    ],
                    [
                      "2",
                      "KC II",
                      "1",
                      "Nein",
                      "Schnittstück 7",
                      "Schnittstück 3",
                      "Schnittstück ...",
                    ],
                    ["...", "...", "...", "...", "...", "...", "..."],
                  ]}
                ></Table>
              </InfoModal>
            ) : null
          }
          {
            this.state.resultExport.show ? (
              <ConfirmDialog

                disabled={this.state.resultExport.disabled}
                text={
                  <form>
                    <div className="form-row">
                      <label htmlFor="cutoff">Abschneidekriterium: </label>
                      {this.state.resultExport.cutoff !== "custom" ? (
                        <select
                          defaultValue={this.state.resultExport.cutoff}
                          onChange={(e) => {
                            this.setState((prevState) => ({
                              resultExport: {
                                ...prevState.resultExport,
                                cutoff: e.target.value,
                              },
                            }));
                          }}
                          className="w-100"
                          id="cutoff"
                        >
                          <option value={"0.1"}>0,1</option>
                          <option value={"0.01"}>0,01</option>
                          <option value={"custom"}>benutzerdefiniert</option>
                        </select>
                      ) : (
                        <div>
                          <SInput
                            type="text"
                            value={this.state.resultExport.customCutoff}
                            onChange={(e) => {
                              let val = e.target.value;
                              val = val.replace(/,/g, ".");
                              val = Number(val);

                              if (isNaN(val)) {
                                this.setState((prevState) => ({
                                  resultExport: {
                                    ...prevState.resultExport,
                                    customCutoff: e.target.value,
                                    disabled: true,
                                  },
                                }));
                              } else {
                                this.setState((prevState) => ({
                                  resultExport: {
                                    ...prevState.resultExport,
                                    customCutoff: e.target.value,
                                    disabled: false,
                                  },
                                }));
                              }
                            }}
                          />
                          <label>Bq</label>
                        </div>
                      )}
                    </div>
                    <div className="form-row">
                      <label htmlFor="nuc_checkbox">Nuklide ohne Bindestrich </label>
                      <input
                        defaultChecked={this.state.resultExport.exportWithoutHyphen}
                        onChange={() => {
                          this.setState((prevState) => ({
                            resultExport: {
                              ...prevState.resultExport,
                              exportWithoutHyphen: !prevState.resultExport.exportWithoutHyphen,
                            },
                          }));
                        }}
                        className="w-100"
                        id="nuc_checkbox"
                        type="checkbox"
                      />
                    </div>
                  </form>
                }
                confirm={() => {
                  this.setState((prevState) => ({
                    resultExport: {
                      ...prevState.resultExport,
                      show: false,
                    },
                  }));

                  let names = this.state.selectedRows.map((x) => x[0].props.children);
                  let ids = this.props.pContext.project.wastePackages.filter((x) => {
                    return names.includes(x.name);
                  });
                  ids = ids.map((x) => x.id);
                  let cutoff = this.state.resultExport.cutoff !== "custom" ? this.state.resultExport.cutoff : this.state.resultExport.customCutoff;
                  cutoff = cutoff.replace(/,/g, ".");
                  this.props.pContext.exportWastePackageResult(ids, cutoff, this.state.resultExport.exportWithoutHyphen);
                }}
                cancel={() => {
                  this.setState((prevState) => ({
                    resultExport: {
                      ...prevState.resultExport,
                      show: false,
                    },
                  }));
                }}
                confirmText={"Export"}
                cancelText={"Abbrechen"}
              ></ConfirmDialog>
            ) : null
          }

        </div >
      );
    } else {
      return <WastePackageDetailView table10={this.state} goBack={this.removeSelected} container={this.state.selected}></WastePackageDetailView>;
    }
  }
}

export default WastePackgeView;
