import React, { Component } from "react";
import SButton from "../styledComponents/SButton";
class Option extends Component {
  state = {};
  render() {
    return (
      <SButton
        {...this.props}
        className={"bg-inherit flex gap-2 items-center w-fit text-white  border-0 font-mono uppercase hover:opacity-75 hover:bg-inherit hover:text-orange-400"}
      >
        {this.props.children}
      </SButton>
    );
  }
}

export default Option;
