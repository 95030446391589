import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Transition } from "@headlessui/react";
import { faXmarkCircle } from "@fortawesome/free-regular-svg-icons";
import React, { Component, Fragment } from "react";
class Alert extends Component {
  state = {};
  render() {
    return (
      <Transition show={this.props.error.shown} as={Fragment}>
        <Transition.Child
          show={this.props.error.shown.toString()}
          as={"div"}
          enter="transform transition ease-in-out duration-500"
          enterFrom="translate-x-[150%]"
          enterTo="translate-x-0"
          leave="transform transition ease-in-out duration-500 sm:duration-700"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-[150%]"
        >
          <div className=" bg-white shadow-xl w-fit border border-gray-400 border-l-red-600 border-l-8 p-3 gap-2 rounded-lg  flex items-center transition-all ease-in-out ">
            <FontAwesomeIcon className="text-red-600 text-2xl" icon={faXmarkCircle}></FontAwesomeIcon>
            <article className="flex flex-col grow">
              <p className="font-semibold">Fehler!</p>
              <p className="bg-inherit">{this.props.error.msg}</p>
            </article>

            <button
              onClick={() => {
                this.props.close(this.props.error);
              }}
            >
              X
            </button>
          </div>
        </Transition.Child>
      </Transition>
    );
  }
}

export default Alert;
