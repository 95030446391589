import React, { Component } from "react";
class NotFound extends Component {
  state = {};
  render() {
    return (
      <div className="flex h-full w-full justify-center items-center flex-col">
        <p className="p-2 inline-flex bg-gray-50 bg-opacity-60 rounded-lg">Dieses Feature ist aktuell noch nicht implementiert.</p>
      </div>
    );
  }
}

export default NotFound;
