import React, { Component } from "react";
import Navbar from "./Navbar";
class Menu extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let { options } = this.props;
    if (!options) {
      options = [];
    }
    return (
      <div className="h-screen flex flex-col">
        <Navbar logo={<img className="h-12" src="/logo.svg"></img>}>{options}</Navbar>
        <div className="overflow-y-auto svg-bg grow">{this.props.selected ? this.props.selected.component : null}</div>
      </div>
    );
  }
}

export default Menu;
