import React, { Component } from "react";
import { AuthContext } from "../context/AuthContext";
import "./form.css";

import DatePicker, { registerLocale } from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faX } from "@fortawesome/free-solid-svg-icons";
import SButton from "../styledComponents/SButton";
import "react-datepicker/dist/react-datepicker.css";
import de from "date-fns/locale/de";
registerLocale("de", de);

class ExampleForm extends Component {
  constructor(props) {
    super(props);
    this.calcMaxima = this.calcMaxima.bind(this);
    this.print = this.print.bind(this);
  }
  state = {
    date: new Date(),
    eichDate: new Date(),
    id: "",
    messbereich: "",
    abstandshalter: false,
    uOdl1: 0,
    uOdl2: 0,
    uOdl3: 0,
    uOdl4: 0,
    messobject: "ESM",
    bezeichnung: "",
    messId: "",
    gewicht: 0,
    nOdl1: 0,
    nOdl2: 0,
    nOdl3: 0,
    nOdl4: 0,
    maxima: 0,
    abgleich: false,
    kontrollwert: 0,
  };
  calcMaxima() {
    let o1 = 0,
      o2 = 0,
      o3 = 0,
      o4 = 0;
    try {
      o1 = Number.parseFloat(this.state.nOdl1);
    } catch (error) {
      console.error(error);
    }
    try {
      o2 = Number.parseFloat(this.state.nOdl2);
    } catch (error) {
      console.error(error);
    }
    try {
      o3 = Number.parseFloat(this.state.nOdl3);
    } catch (error) {
      console.error(error);
    }
    try {
      o4 = Number.parseFloat(this.state.nOdl4);
    } catch (error) {
      console.error(error);
    }
    this.setState({ maxima: Math.max(o1, o2, o3, o4) });
  }
  print() {
    var doc = document.getElementById("formtable");
    let ifr = document.body.appendChild(document.createElement("iframe"));
    ifr.name = "hidden-internal-frame";
    ifr.style.visibility = "hidden";
    ifr.innerHTML = doc.innerHTML;
    var ifDoc = null;
    if (ifr.contentWindow) {
      ifDoc = ifr.contentWindow;
    } else if (ifr.contentDocument.document) {
      ifDoc = ifr.contentDocument.document;
    } else {
      ifDoc = ifr.contentDocument;
    }
    ifr.onload = function () {
      ifr.contentWindow.focus();
      ifr.contentWindow.print();
      document.body.removeChild(ifr);
    };
    ifDoc.document.open();
    ifDoc.document.write("<html><head><title></title>");
    ifDoc.document.write('<link rel="stylesheet" href="test.css" type="text/css" />');
    ifDoc.document.write("</head><body >");
    ifDoc.document.write(doc.outerHTML);
    ifDoc.document.write("</body></html>");
    ifDoc.document.close();
  }
  render() {
    return (
      <div className="m-2 flex flex-col items-center">
        <table id="formtable" className="form-table">
          <tbody>
            <tr>
              <td colSpan={2}>Grundinformationen</td>
            </tr>
            <tr>
              <td>Durchführende(r) MitarbeiterIn</td>
              <td>
                <AuthContext.Consumer>
                  {(ctx) => {
                    return ctx.user ? ctx.user.username: null;
                  }}
                </AuthContext.Consumer>
              </td>
            </tr>
            <tr>
              <td>Messdatum, Uhrzeit</td>
              <td>
                <DatePicker
                  selected={this.state.date}
                  onChange={(date) => this.setState({ date: date })}
                  timeInputLabel="Uhrzeit:"
                  dateFormat="dd.MM.yyyy HH:mm "
                  locale="de"
                  showTimeInput
                />
              </td>
            </tr>
            <tr>
              <td colSpan={2}>Spezifikation ODL-Messgerät </td>
            </tr>
            <tr>
              <td>Identifikation (Typ, SN)</td>
              <td>
                <input value={this.state.id} onChange={(evt) => this.setState({ id: evt.target.value })}></input>
              </td>
            </tr>
            <tr>
              <td>Datum der letzten Eichung</td>
              <td>
                <DatePicker selected={this.state.eichDate} onChange={(date) => this.setState({ eichDate: date })} dateFormat="dd.MM.yyyy " locale="de" />
              </td>
            </tr>
            <tr>
              <td>Messbereich</td>
              <td>
                <input value={this.state.messbereich} onChange={(evt) => this.setState({ messbereich: evt.target.value })}></input>
              </td>
            </tr>
            <tr>
              <td>Abstandshalter</td>
              <td>
                <input value={this.state.abstandshalter} onChange={(evt) => this.setState({ abstandshalter: evt.target.value })} type="checkbox"></input>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>Untergrundmessung </td>
            </tr>
            <tr>
              <td>Untergrund-ODL [µSv/h] – Position 1</td>
              <td>
                <input value={this.state.uOdl1} onChange={(evt) => this.setState({ uOdl1: evt.target.value })}></input>
              </td>
            </tr>
            <tr>
              <td>Untergrund-ODL [µSv/h] – Position 2</td>
              <td>
                <input value={this.state.uOdl2} onChange={(evt) => this.setState({ uOdl2: evt.target.value })}></input>
              </td>
            </tr>
            <tr>
              <td>Untergrund-ODL [µSv/h] – Position 3</td>
              <td>
                <input value={this.state.uOdl3} onChange={(evt) => this.setState({ uOdl3: evt.target.value })}></input>
              </td>
            </tr>
            <tr>
              <td>Untergrund-ODL [µSv/h] – Position 4</td>
              <td>
                <input value={this.state.uOdl3} onChange={(evt) => this.setState({ uOdl3: evt.target.value })}></input>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>Spezifikation Messobjekt </td>
            </tr>
            <tr>
              <td>Messobjekt</td>
              <td>
                <select selected={this.state.messobject} onChange={(evt) => this.setState({ messobject: evt.target.value })}>
                  <option value={"ESM"}>ESM</option>
                  <option value={"ESK"}>ESK</option>
                  <option value={"KC"}>KC</option>
                  <option value={"MOSAIK"}>MOSAIK</option>
                  <option value={"200-l-Fass"}>200-l-Fass</option>
                  <option value={"Schnittstück"}>Schnittstück</option>
                </select>
              </td>
            </tr>
            <tr>
              <td>Bezeichnung</td>
              <td>
                <input value={this.state.bezeichnung} onChange={(evt) => this.setState({ bezeichnung: evt.target.value })}></input>
              </td>
            </tr>
            <tr>
              <td>Identifikationsnr. (ID)</td>
              <td>
                <input value={this.state.messId} onChange={(evt) => this.setState({ messId: evt.target.value })}></input>
              </td>
            </tr>
            <tr>
              <td>Gewicht [kg]</td>
              <td>
                <input value={this.state.gewicht} onChange={(evt) => this.setState({ gewicht: evt.target.value })}></input>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>ODL-Messung </td>
            </tr>

            <tr>
              <td>Netto-ODL [µSv/h] – Position 1</td>
              <td>
                <input
                  value={this.state.nOdl1}
                  onChange={(evt) => {
                    this.setState({ nOdl1: evt.target.value }, this.calcMaxima);
                  }}
                ></input>
              </td>
            </tr>
            <tr>
              <td>Netto-ODL [µSv/h] – Position 2</td>
              <td>
                <input
                  value={this.state.nOdl2}
                  onChange={(evt) => {
                    this.setState({ nOdl2: evt.target.value }, this.calcMaxima);
                  }}
                ></input>
              </td>
            </tr>
            <tr>
              <td>Netto-ODL [µSv/h] – Position 3</td>
              <td>
                <input
                  value={this.state.nOdl3}
                  onChange={(evt) => {
                    this.setState({ nOdl3: evt.target.value }, this.calcMaxima);
                  }}
                ></input>
              </td>
            </tr>
            <tr>
              <td>Netto-ODL [µSv/h] – Position 4</td>
              <td>
                <input
                  value={this.state.nOdl4}
                  onChange={(evt) => {
                    this.setState({ nOdl4: evt.target.value }, this.calcMaxima);
                  }}
                ></input>
              </td>
            </tr>
            <tr>
              <td>Maximalwert [µSv/h]</td>
              <td>
                <label>{this.state.maxima ? this.state.maxima : "es konnte kein Maxima bestimmt werden"}</label>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>Kontrollwertabgleich </td>
            </tr>

            <tr>
              <td>Ist Abgleich durchzuführen?</td>
              <td>
                <input value={this.state.abgleich} onChange={(evt) => this.setState({ abgleich: evt.target.value })} type="checkbox"></input>
              </td>
            </tr>
            <tr>
              <td>Kontrollwert [µSv/h]</td>
              <td>
                <input value={this.state.kontrollwert} onChange={(evt) => this.setState({ kontrollwert: Number.parseFloat(evt.target.value) })}></input>
              </td>
            </tr>
            <tr>
              <td>Kontrollwert [µSv/h] eingehalten?</td>
              <td>
                <FontAwesomeIcon size="lg" icon={this.state.kontrollwert > this.state.maxima ? faCheck : faX}></FontAwesomeIcon>
              </td>
            </tr>
            <tr>
              <td id="img-td" colSpan={2}>
                {this.state.messobject === "KC" ? <img className="w-full" width={400} src="/example.png"></img> : "KEIN KC"}
              </td>
            </tr>
          </tbody>
        </table>
        <SButton onClick={this.print}>PDF runterladen</SButton>
      </div>
    );
  }
}

export default ExampleForm;
