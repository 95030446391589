function validate(ok, error) {
  return (status, body) => {
    
    if (status === 200) {
      
      ok(body);
    } else {
      error(body);
    }
  };
}
export { validate };
