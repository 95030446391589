import React, { Component } from "react";
class SInput extends Component {
  constructor(props) {
    super(props);
  }
  state = {};
  render() {
    const { className, ...rest } = this.props;
    return <input className={"border border-gray-500 rounded-md px-1 text-center " + className} {...rest} />;
  }
}

export default SInput;
