import React, { Component } from "react";
class SettingCategory extends Component {
  constructor(props) {
    super(props);
  }
  state = {};
  render() {
    const { className, ...rest } = this.props;
    return (
      <div className={"border-2 flex flex-col rounded-lg border-gray-400 gap-2 p-2 bg-white bg-opacity-50   " + className} {...rest}>
        {this.props.children}
      </div>
    );
  }
}

export default SettingCategory;
