import { faCheck, faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Transition } from "@headlessui/react";
import React, { Component, Fragment } from "react";
class Notification extends Component {
  constructor(props) {
    super(props);
  }
  state = {};
  render() {
    return (
      <Transition show={this.props.message.shown} as={Fragment}>
        <Transition.Child
          show={this.props.message.shown.toString()}
          as={"div"}
          enter="transform transition ease-in-out duration-500"
          enterFrom="translate-x-[150%]"
          enterTo="translate-x-0"
          leave="transform transition ease-in-out duration-500 sm:duration-700"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-[150%]"
        >
          <div className=" z-50 bg-white shadow-xl w-fit border border-gray-400 border-l-green-600 border-l-8 p-3 gap-2 rounded-lg  flex items-center transition-all ease-in-out ">
            <FontAwesomeIcon className="text-green-600 text-2xl" icon={faCheck}></FontAwesomeIcon>

            <p className="bg-inherit">{this.props.message.msg}</p>

            <button
              onClick={() => {
                this.props.close(this.props.message);
              }}
            >
              X
            </button>
          </div>
        </Transition.Child>
      </Transition>
    );
  }
}

export default Notification;
