import React, { Component } from "react";
import { EnrichmentContext } from "../context/EnrichmentContext";
class EnrichmentSelect extends Component {
  constructor(props) {
    super(props);
  }
  state = {};
  render() {
    return (
      <select
        {...this.props}
        value={this.props.value ? this.props.value : undefined}
        className={"p-1 rounded-md " + (this.props.className ? this.props.className : "")}
      >
        <EnrichmentContext.Consumer>
          {(ctx) =>
            ctx.map((x, idx) => (
              <option value={x} key={idx}>
                {x}
              </option>
            ))
          }
        </EnrichmentContext.Consumer>
      </select>
    );
  }
}

export default EnrichmentSelect;
