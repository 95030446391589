import React, { Component } from "react";
import SButton from "../styledComponents/SButton";
class InfoModal extends Component {
  constructor(props) {
    super(props);
    this.background = React.createRef();
    this.onBackgroundClick = this.onBackgroundClick.bind(this);
  }
  state = {};
  onBackgroundClick(evt) {
    if (evt.target === this.background.current) {
      this.props.close();
    }
  }
  render() {
    return (
      <div
        onClick={this.onBackgroundClick}
        ref={this.background}
        className="absolute overflow-scroll py-5  z-10 top-0 left-0 backdrop-blur-sm h-full w-full flex items-center justify-center"
      >
        <div className="bg-gray-200 overflow-scroll max-h-full  p-5 rounded-lg border-2 m-auto  border-gray-400 flex flex-col gap-2  items-center">
          <div>{this.props.children}</div>
          <SButton className="w-fit h-fit min-h-[2rem]" onClick={() => this.props.close()}>
            Schließen
          </SButton>
        </div>
      </div>
    );
  }
}

export default InfoModal;
