import React, { Component } from "react";
import CutPieceView from "../CutPieces/CutPieceView";
import Option from "../utilityComponents/Option";
import SegmentView from "../Segments/SegmentView";
import Sidebar from "../utilityComponents/Sidebar";
import { generateFromStrings, getNoLimitNuclides, getTabelle10 } from "../Utility/Data";
import NotFound from "../utilityComponents/404";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faListSquares } from "@fortawesome/free-solid-svg-icons";
import SettingView from "../settings/SettingView";
import ExampleForm from "../forms/ExampleForm";
import { ProjectContext } from "../context/ProjectContext";
import WastePackgeView from "../WastePackages/WastePackageView";
import { ConfirmDialogContext } from "../context/ConfirmDialogContext";
import { NotificationContext } from "../context/NotificationContext";
class ProjectHome extends Component {
  static contextType = ConfirmDialogContext;
  constructor(props) {
    super(props);
    this.state = {
      currentComponent: 1,
      segments: new Map(),
      cutPieces: new Map(),
      container: new Map(),
      limits: generateFromStrings(),
      noLimit: getNoLimitNuclides(),
    };
    this.updateComponent = this.updateComponent.bind(this);
    this.getAllNuclides = this.getAllNuclides.bind(this);
  }

  updateComponent(comp) {
    this.setState({ currentComponent: comp });
  }

  getComponent(pContext, notificationContext) {
    switch (this.state.currentComponent) {
      case 0:
        return <CutPieceView notification={notificationContext} pContext={pContext} confirmContext={this.context}></CutPieceView>;
      case 1:
        return <SegmentView notification={notificationContext} confirmContext={this.context} pContext={pContext}></SegmentView>;
      case 2:
        return <WastePackgeView notification={notificationContext} pContext={pContext} limits={this.state.limits} confirmContext={this.context}></WastePackgeView>;

      case 5:
        return <SettingView notification={notificationContext} pContext={pContext}></SettingView>;
      case 6:
        return <ExampleForm notification={notificationContext} pContext={pContext}></ExampleForm>;
      default:
        return <NotFound></NotFound>;
    }
  }

  getAllNuclides() {
    let nuc = [];
    for (let segement of Array.from(this.state.segments.values())) {
      for (let x of Array.from(segement.nuclides.get("31.12.2022").keys())) {
        if (!nuc.includes(x)) {
          nuc.push(x);
        }
      }
    }

    let nucWLimits = Array.from(this.state.limits.get(1).get(1).keys());
    let whatAbout = [];
    let t10 = getTabelle10();
    for (let nuclid of nuc) {
      if (nucWLimits.includes(nuclid) || this.state.noLimit.includes(nuclid) || t10.includes(nuclid)) {
        continue;
      }
      whatAbout.push(nuclid);
    }
    let whatAboutMap = new Map();
    for (let segement of Array.from(this.state.segments.values())) {
      let dMap = segement.nuclides.get("31.12.2022");
      for (let nuc of whatAbout) {
        let nuclid = dMap.get(nuc);
        if (!nuclid || (whatAboutMap.has(nuc) && whatAboutMap.get(nuc) < nuclid.value)) {
          continue;
        }
        whatAboutMap.set(nuc, nuclid.value);
      }
    }
  }
  render() {
    return (
      <div className="flex w-full h-full">
        <NotificationContext.Consumer>
          {(notification) => (
            <ProjectContext.Consumer>
              {(ctx) => (
                <>
                  <Sidebar
                  
                    onUpdate={this.updateComponent}
                    selected={1}
                    title={
                      <>
                        <h1 className="text-white text-lg text-center uppercase font-semibold">Projektverwaltung</h1>

                        <h2 title={ctx.project.name} className="text-white text-base text-center uppercase font-semibold">
                          {ctx.project.name.length > 25 ? ctx.project.name.slice(0, 25) + "..." : ctx.project.name}
                        </h2>
                        <h2 className="text-white text-base text-center uppercase">
                          Ref. Zeitpunkt: {ctx.project.refTimestamp ? new Date(ctx.project.refTimestamp).toLocaleDateString("de-DE") : "-"}
                        </h2>
                      </>
                    }
                  >
                    <>
                      <Option id={0}>Schnittprodukte</Option>
                      <Option id={1}>Aktivitäts-Segmente</Option>
                      <Option id={2}>Abfallgebinde</Option>
                      <Option id={5}>Einstellungen</Option>
                    </>

                    <>
                      <Option onClick={ctx.deselect}>
                        <FontAwesomeIcon icon={faListSquares}></FontAwesomeIcon>
                        <p>Projektauswahl</p>
                      </Option>
                    </>
                  </Sidebar>
                  <div className="overflow-y-auto grow h-full    ">{this.getComponent(ctx, notification)}</div>{" "}
                </>
              )}
            </ProjectContext.Consumer>
          )}
        </NotificationContext.Consumer>
      </div>
    );
  }
}

export default ProjectHome;
