export class WastePackage {
  constructor(name, container, wasteGroup, failureresistant, cutpieces) {
    this.name = name;
    this.container = { name: container };
    this.wasteGroup = Number.parseInt(wasteGroup);
    this.cutPieces = [];
    this.failureresistant = failureresistant;
    for (let cp of cutpieces) {
      this.cutPieces.push({ name: cp });
    }
  }
}
