import React, { Component } from "react";
class SettingLabel extends Component {
  constructor(props) {
    super(props);
  }
  state = {};
  render() {
    return <label className="px-2 whitespace-nowrap">{this.props.children}</label>;
  }
}

export default SettingLabel;
