import React, { Component } from "react";
import { Parser } from "../Utility/Parser";
import SButton from "../styledComponents/SButton";
import "./segments.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faInfoCircle, faTrash, faX } from "@fortawesome/free-solid-svg-icons";
import InfoModal from "../Utility/InfoModal";
import { addSegments, getCo60Segments, getSegment, removeSegments } from "../api";
import { LoadingContext } from "../context/LoadingContext";
import { ConfirmDialogContext } from "../context/ConfirmDialogContext";
import { validate } from "../Utility/ResponseConsumer";
import Table from "../utilityComponents/Table";
const header = [
  {
    text: "Name",
    filter: (filter, x) => x.toLocaleUpperCase().includes(filter.toLocaleUpperCase()),
    compare: (a, b) => {
      return a.toString().localeCompare(b);
    },
  },
  { text: "Erstell-Datum" },
  { text: "Masse in kg" },
  { text: "Oberfläche in cm²" },
  { text: "Verpackt" },
  { text: "Details" },
];
const co60Header = ["Segment", "Co-60 Aktivität [Bq/kg]", "Co-60 Aktivität [Bq/cm²]"];
class SegmentView extends Component {
  static contextType = LoadingContext;
  constructor(props) {
    super(props);
    this.fileChange = this.fileChange.bind(this);
    this.addSegments = this.addSegments.bind(this);
    this.getCo60 = this.getCo60.bind(this);
    this.exportCo60 = this.exportCo60.bind(this);
    this.toggleSelect = this.toggleSelect.bind(this);
  }
  state = { selectedSegment: null, selectedDate: 1, filter: "", sortBy: "name", asc: true, showHelp: false, showCo60: false, selected: [] };
  fileChange(evt) {
    if (evt.target.files.length > 0) {
      let segmentsToSave = [];
      let promises = [];
      this.context.toggle();
      for (let file of evt.target.files) {
        let promise = new Promise(async (resolve, reject) => {
          Parser.parseSegment(file, (segment) => {
            if (segment == null) {
              reject(file.name);
            } else {
              segmentsToSave.push(segment);
              resolve();
            }
          });
        });
        promises.push(promise);
      }
      const proccesFiles = async () => {
        await Promise.all(promises);
      };
      proccesFiles()
        .then(() => {
          let alreadyExsists = this.props.pContext.project.segments.filter((x) => {
            return (
              segmentsToSave.filter((y) => {
                if (y.name === x.name) {
                  for (let z of y.activities) {
                    if (x.dates.includes(z.date)) {
                      return true;
                    }
                  }
                }
                return false;
              }).length > 0
            );
          });
   
          if (alreadyExsists.length > 0 && this.props.confirmContext) {
            this.context.toggle();
            this.props.confirmContext.toggle(
              <span>
                <p>Entsprechende Daten für folgende Segmente sind bereits vorhanden: </p>
                <p>{alreadyExsists.map((x) => x.name + " ")}</p>
                <p>
                  Sollen diese Daten <span className="font-bold">ÜBERSCHRIEBEN</span> werden?
                </p>
              </span>,
              () => {
                this.props.confirmContext.toggle();
                this.context.toggle();
                this.addSegments(segmentsToSave);
              },
              () => {
                this.props.confirmContext.toggle();
              },
            );
          } else {
            this.addSegments(segmentsToSave);
          }
        })
        .catch((fileName) => {
          this.context.toggle();
          if (this.props.notification) {
            this.props.notification.add("Segmentdatei: " + fileName + " konnte nicht geladen werden!", true);
          }
        });
    }
  }
  addSegments(toSave) {

    addSegments(this.props.pContext.project.id, toSave, (status, body) => {
      let msg, isError;
      if (status !== 200) {
        msg = body.message;
        isError = true;
      } else {
        if (this.props.pContext) {
          this.props.pContext.refresh(body);
          msg = "Upload erfolgreich!";
          isError = false;
        }
      }
      this.context.toggle();
      if (this.props.notification) {
        this.props.notification.add(msg, isError);
      }
    });
  }
  setSelected(s) {
    this.context.toggle();
    getSegment(this.props.pContext.project.id, s.id, (status, body) => {
      if (status === 200) {
        this.setState({ selectedSegment: body, selectedDate: 1 }, this.context.toggle);
      } else {
        if (this.props.notification) {
          this.props.notification.add(body.message, true);
        }
      }
    });
  }
  getCo60() {
    this.context.toggle();
    getCo60Segments(
      this.props.pContext.project.id,
      validate(
        (body) => {
          this.context.toggle();
    
          const list = body.map(x => {
            let unit = null ;
            this.props.pContext.project.segments.forEach(element => {
                if(element.name === x.segment){
                
                  unit = element.unit
                }
            });
            return {segment: x.segment, co60activity: x.co60activity, unit: unit}
          })
      
          this.setState({ showCo60: true, co60: list });
        },
        (error) => {
          this.context.toggle();
          if (this.props.notification) {
            this.props.notification.add(error.message, true);
          }
        },
      ),
    );
  }
  setSortyBy(key, asc) {
    this.setState({ sortBy: key, asc: asc });
  }
  exportCo60() {
    if (this.state.co60) {
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += "Segment;Co-60 Aktivitaet [Bq/kg]\r\n";
      this.state.co60.forEach(function (x) {
        let row = [x.segment, x.co60activity.getPretty(2)].join(";");
        csvContent += row + "\r\n";
      });
      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", this.props.pContext.project.name + "_Co-60.csv");
      document.body.appendChild(link); // Required for FF
      link.click(); // This will download the data file named "my_data.csv".
    }
  }
  toggleSelect(id) {
    let tmp;
    if (this.state.selected.includes(id)) {
      tmp = this.state.selected.filter((x) => x !== id);
    } else {
      tmp = this.state.selected;
      tmp.push(id);
    }
    this.setState({ selected: tmp });
  }

  render() {
    let segments = [];

    if (this.props.pContext && this.props.pContext.project) {
      segments = this.props.pContext.project.segments;
      segments = segments.filter((segment) => segment.name.toLocaleUpperCase().includes(this.state.filter.toLocaleUpperCase()));
    }
    if (this.state.sortBy) {
      segments = segments.sort((a, b) => {
        let value = 0;
        if (typeof a[this.state.sortBy] === "number") {
          value = a[this.state.sortBy] - b[this.state.sortBy];
        } else {
          value = a[this.state.sortBy] > b[this.state.sortBy] ? 1 : -1;
        }
        if (!this.state.asc) {
          value = value * -1;
        }
        return value;
      });
    }

    let component = null;
    if (this.state.selectedSegment) {
      let dates = Array.from(Object.keys(this.state.selectedSegment.activities));

      let nuclides = [];
     
      let unit = this.state.selectedSegment.unit
      if (this.state.selectedSegment.activities && this.state.selectedDate && this.state.selectedSegment.activities[this.state.selectedDate]) {
        nuclides = this.state.selectedSegment.activities[this.state.selectedDate].nuclideActivities;
        nuclides = nuclides.sort((a, b) => a.nuclide.localeCompare(b.nuclide));
      }

      component = (
        <div className="flex flex-col gap-2">
          <div className="flex justify-between ">
            <SButton onClick={() => this.setState({ selectedSegment: null })}>Zurück</SButton>
            <ConfirmDialogContext.Consumer>
              {(ctx) => (
                <SButton
                  onClick={() => {
                    ctx.toggle(
                      "Wollen Sie das Segment " + this.state.selectedSegment.name + " unwiederruflich löschen?",
                      () => {
                        this.props.pContext.removeSegment(this.state.selectedSegment.id);
                        ctx.toggle();
                        this.setState({ selectedSegment: null });
                      },
                      () => {
                        ctx.toggle();
                      },
                    );
                  }}
                  className="w-fit"
                >
                  <FontAwesomeIcon size="lg" className="text-red-600 p-1" icon={faTrash}></FontAwesomeIcon>
                </SButton>
              )}
            </ConfirmDialogContext.Consumer>
          </div>
          <div className="flex flex-col gap-4">
            <table className="d-table ">
              <tbody className="d-tbody">
                <tr className="d-tr">
                  <td className="d-td text-left pl-2 font-bold">Name: </td>
                  <td className="d-td">{this.state.selectedSegment.name}</td>
                </tr>
                <tr className="d-tr">
                  <td className="d-td text-left pl-2 font-bold">{unit === "MASS" ? "Masse in kg:" : "Masse in cm²"} </td>
                  <td className="d-td">{this.state.selectedSegment.mass.getPretty()}</td>
                </tr>
                <tr className="d-tr">
                  <td className="d-td text-left pl-2  font-bold">Erstell-Datum: </td>
                  <td className="d-td">{this.state.selectedSegment.creationTimestamp}</td>
                </tr>
                <tr className="d-tr">
                  <td className="d-td text-left pl-2 font-bold">{unit ===  "MASS" ? 'massenspezifische Gesamtaktivität Bq/kg:' :'massenspezifische Gesamtaktivität Bq/cm²:'} </td>
                  <td className="d-td">
                    {this.state.selectedDate && this.state.selectedSegment.activities[this.state.selectedDate]
                      ? this.state.selectedSegment.activities[this.state.selectedDate].sumAct.getPretty(2)
                      : "Kein Ref.-Zeitpunkt gewählt"}
                  </td>
                </tr>
                <tr className="d-tr">
                  <td className="d-td text-left pl-2 font-bold">Ref.-Zeitpunkt: </td>
                  <td className="d-td">
                    <select
                      value={this.state.selectedDate}
                      className="p-1 border border-gray-400 rounded-lg bg-gray-300"
                      onChange={(evt) => {
                        this.setState({ selectedDate: evt.target.value });
                      }}
                    >
                      <option disabled value={1}>
                        Ref.-Zeitpunkt wählen
                      </option>
                      {dates.map((date) => (
                        <option key={date} value={date}>
                          {new Date(Number.parseFloat(date)).toLocaleDateString("de-DE")}
                        </option>
                      ))}
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
            <table className="d-table">
              <thead className="d-thead">
                <tr className="d-tr">
                  <th className="d-th">Name</th>
                  <th className="d-th">{unit === "MASS" ? 'massenspezifische Aktivität Bq/kg' : 'massenspezifische Aktivität Bq/cm²'}</th>
                </tr>
              </thead>
              <tbody className="d-tbody">
                {nuclides.map((nuclide) => (
                  <tr className="d-tr" key={nuclide.nuclide}>
                    <td className="d-td">{nuclide.nuclide}</td>
                    <td className="d-td">{nuclide.value.getPretty(2)}</td>
                   
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      );
    } else {
      component = (
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-1">
            <label htmlFor="upload" className={"border border-gray-400 rounded-lg px-2 bg-gray-300 hover:bg-orange-200 "}>
              Datei hochladen
            </label>
            <FontAwesomeIcon
              size="lg"
              onClick={() => this.setState({ showHelp: true })}
              className="text-gray-500 hover:text-opacity-70"
              icon={faInfoCircle}
            ></FontAwesomeIcon>
            <input id="upload" className="hidden" type="file" multiple onChange={this.fileChange} />
          </div>
          <div>
            <SButton onClick={this.getCo60}>Co-60 Aktivitäten</SButton>
          </div>
          <ConfirmDialogContext.Consumer>
            {(ctx) => (
              <Table
                disableEdit={true}
                rows={segments.map((segment) => [
                  segment.name,
                  segment.creationTimestamp,
                  segment.unit === "MASS" ? segment.mass.getPretty(2).replace(".", ","): "---",
                  segment.unit === "SURFACE" ? segment.mass.getPretty(2).replace(".", ","): "---",
                  <FontAwesomeIcon
                    size="lg"
                    className={segment.used ? "text-green-600" : "text-red-600"}
                    icon={segment.used ? faCheck : faX}
                  ></FontAwesomeIcon>,
                  <button onClick={() => this.setSelected(segment)}>
                    <FontAwesomeIcon size="lg" className="text-gray-600 cursor-pointer hover:text-gray-500" icon={faInfoCircle}></FontAwesomeIcon>
                  </button>,
                ])}
                header={header}
                options={[
                  {
                    label: <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>,
                    onClick: (rows) => {
                      let names = rows.map((x) => x[0]);
                      let ids = this.props.pContext.project.segments.filter((x) => {
                        return names.includes(x.name);
                      });
                      ids = ids.map((x) => x.id);
                      ctx.toggle(
                        "Wollen Sie die augewählten Segmente unwiederruflich löschen?",
                        () => {
                          this.props.pContext.removeSegments(ids);
                          ctx.toggle();
                        },
                        () => {
                          ctx.toggle();
                        },
                      );
                    },
                  },
                ]}
              ></Table>
            )}
          </ConfirmDialogContext.Consumer>
        </div>
      );
    }
    return (
      <div className="p-2 flex flex-col gap-2">
        <h1 className="text-lg font-bold">Aktivitäts-Segmente</h1>
        {component}
        {this.state.showHelp ? (
          <InfoModal close={() => this.setState({ showHelp: false })}>
            <p className="mb-6">Aktuell werden die Formate KKE-Aktiv und folgendes Format unterstützt:</p>

            <Table
              disableSearch={true}
              disableSelect={true}
              disableEdit={true}
              header={["Name", " "]}
              rows={[
                [
                  "Datum",
                  ""
                  
                ],
                [
                  "Nuklid",
                  "Bq/kg oder Bq/cm²"
                  
                ],
                [
                  "H-3",
                 "2,51E-01"
                ],
                [
                  "Be10",
                 "2,53E-09"
                ],
                [
                  "...",
                 "..."
                ],
              ]}
            ></Table>
          </InfoModal>
        ) : null}
        {this.state.showCo60 ? (
          <InfoModal close={() => this.setState({ showCo60: false })}>
            <div className="flex flex-col items-center gap-1">
              <h1 className="font-semibold text-lg">Co-60 Aktivitäten aller Segmente</h1>
              <SButton onClick={this.exportCo60}>Daten exportieren</SButton>
              <Table
                header={co60Header}
                disableSearch={true}
                disableSelect={true}
                rows={this.state.co60.map((x) => {
                  return [x.segment, x.unit === "SURFACE" ?"---": x.co60activity.getPretty(2),  x.unit === "MASS" ?"---": x.co60activity.getPretty(2)];
                })}
              ></Table>
            </div>
          </InfoModal>
        ) : null}
      </div>
    );
  }
}

export default SegmentView;
