import React, { Component } from "react";
class Navbar extends Component {
  constructor(props) {
    super(props);
  }
  state = {};
  render() {
    return (
      <div className="flex gap-2 p-2 bg-main   text-white uppercase">
        {this.props.logo ? this.props.logo : null}
        <div className="flex gap-2 items-center justify-around  w-full px-2 ">{this.props.children}</div>
      </div>
    );
  }
}

export default Navbar;
