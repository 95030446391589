import { faCheck, faFileExport, faInfoCircle, faTrash, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { addCutPiece } from "../api";
import { ConfirmDialogContext } from "../context/ConfirmDialogContext";
import SButton from "../styledComponents/SButton";
import InfoModal from "../Utility/InfoModal";
import { Parser } from "../Utility/Parser";
import Table from "../utilityComponents/Table";
import { getNumberCompare, getStringArrayFilter, getStringCompare, getStringFilter } from "../Utility/Utility";
import { LoadingContext } from "../context/LoadingContext";
const header = [
  {
    text: "Komponente",
    filter: getStringArrayFilter(),
    compare: getStringCompare(),
  },
  {
    text: "Schnittstück-ID",
    filter: getStringFilter(),
    compare: getStringCompare(),
  },
  {
    text: "Masse [kg]",
    compare: getNumberCompare(),
  },
  {
    text: "Schnittstueck-Oberflaeche [cm²]",
    compare: getNumberCompare(),
  },
  { text: "Aktivitäts-Segmente" },
  {
    text: "Gesamtaktivität [Bq]",
    compare: getNumberCompare(),
  },
  {
    text: "Co-60 Aktivität [Bq]",
    compare: getNumberCompare(),
  },
  {
    text: "Co-60 Aktivität [Bq/kg]",
    compare: getNumberCompare(),
  },
  {
    text: "Co-60 Aktivität [Bq/cm²]",
    compare: getNumberCompare(),
  },
  { text: "nuklidspez. Aktivitäten" },
  { text: "Verpackt", key: "used" },
  { text: "Status" },
];
class CutPieceView extends Component {
  static contextType = LoadingContext;
  constructor(props) {
    super(props);
    this.fileChange = this.fileChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.setSortyBy = this.setSortyBy.bind(this);
    this.exportData = this.exportData.bind(this);
    this.addCutPiece = this.addCutPiece.bind(this)
    this.exportCutpieces = this.exportCutpieces.bind(this);
    this.hyphenRef = React.createRef();
  }
  state = { selectedVector: null, selectedSegments: null, sortBy: header[0].key, asc: true, filter: "" };
  fileChange(evt) {
    if (evt.target.files.length > 0) {
      let cutpiecesToSave = [];
      let promises = [];

      this.context.toggle();
      for (let file of evt.target.files) {
        let promise = new Promise(async (resolve, reject) => {
          Parser.parseCutPieces(file, (cutpieces) => {
            if (cutpieces == null) {
              reject(file.name)
            } else {
              cutpiecesToSave.push(cutpieces)
              resolve()
            }
          })
        })
        promises.push(promise)
      }
      const proccesFiles = async () => {
        await Promise.all(promises);
      };
      proccesFiles()
        .then(() => {

          let alreadyExsists = this.props.pContext.project.cutPieces.filter((x) => {
            return (
              cutpiecesToSave[0].filter((y) => {

                if (y.name === x.name) {
                  return true
                }
                return false;
              }).length > 0
            );
          })

          if (alreadyExsists.length > 0 && this.props.confirmContext) {

            this.context.toggle();
            this.props.confirmContext.toggle(
              <span>
                <p>Entsprechende Daten für folgende Schnittstücke sind bereits vorhanden: </p>
                <p>{alreadyExsists.map((x) => x.name + " ")}</p>
                <p>
                  Sollen diese Daten <span className="font-bold">ÜBERSCHRIEBEN</span> werden?
                </p>
              </span>,
              () => {
                this.props.confirmContext.toggle();
                this.context.toggle();
                this.addCutPiece(cutpiecesToSave);
              },
              () => {
                this.props.confirmContext.toggle();
              },
            );

          }
          else {
            this.addCutPiece(cutpiecesToSave);
          }
        }).catch((fileName) => {
          this.context.toggle();
          if (this.props.notification) {
            this.props.notification.add("schnittstückDatei: " + fileName + " konnte nicht geladen werden!", true);
          }
        });
    }
    evt.target.value = ""
  }

  addCutPiece(toSave) {
    addCutPiece(this.props.pContext.project.id, toSave[0], (status, body) => {
      let msg, isError;
      if (status !== 200) {
        msg = body.message;
        isError = true;
      } else {
        if (this.props.pContext) {
          this.props.pContext.refresh(body);
          isError = false;
          msg = "Upload erfolgreich!";
        }
      }
      if (this.props.notification) {
        this.props.notification.add(msg, isError);
      }

      this.context.toggle();
    });
  }
  exportCutpieces() {
    let cutpieces = [];
    if (this.props.pContext && this.props.pContext.project) {
      cutpieces = this.props.pContext.project.cutPieces;
    }
    let csvContent = "data:text/csv;charset=utf-8,";
    csvContent += "Komponenten;Schnittstueck-ID;Masse [kg];Oberfläche [cm²];Aktivitäts-Segmente;Gesamtaktivitaet [Bq];Gesamtaktivitaet [Bq/kg];Gesamtaktivitaet [Bq/cm²];Co-60 Aktivität [Bq];Co-60 Aktivitaet [Bq/kg]; Co-60 Aktivitaet [Bq/cm²]\r\n";
    cutpieces.forEach((x) => {
      let row = [
        x.components,
        x.name,
        x.mass.toFixed(2).replace(".", ","),
        x.surface.toFixed(2).replace(".", ","),
        this.segmentNames(x.segments, true),
        x.activities ? x.activities.sumAct.getPretty(2) : "---",
        x.activitiesMass ? x.activitiesMass.sumAct.getPretty(2) : "---",
        x.activitiesSurface ? x.activitiesSurface.sumAct.getPretty(2) : "---",
        x.activities
          ? x.activities.nuclideActivities.find((a) => a.nuclide === "Co-60")
            ? (x.activities.nuclideActivities.find((a) => a.nuclide === "Co-60").value).getPretty(2)
            : "---"
          : "---",
        x.activitiesMass
          ? x.activitiesMass.nuclideActivities.find((a) => a.nuclide === "Co-60")
            ? (x.activitiesMass.nuclideActivities.find((a) => a.nuclide === "Co-60").value).getPretty(2)
            : "---"
          : "---",
        x.activitiesSurface
          ? x.activitiesSurface.nuclideActivities.find((a) => a.nuclide === "Co-60")
            ? (x.activitiesSurface.nuclideActivities.find((a) => a.nuclide === "Co-60").value).getPretty(2)
            : "---"
          : "---",
      ].join(";");
      csvContent += row + "\r\n";
    });
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", this.props.pContext.project.name + "_Schnittstuecke.csv");
    document.body.appendChild(link); // Required for FF
    link.click(); // This will download the data file named "my_data.csv".
  }
  exportData() {
    if (this.state.selectedVector) {
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += "Nuklid;Aktivitaet [Bq];Aktivitaet [Bq/kg]; Aktivitaet [Bq/cm²]\r\n";
      this.state.selectedVector.forEach((x) => {
        let nuclide = x.nuclide;
        if (this.hyphenRef.current.checked) {
          nuclide = nuclide.replace("-", "");
        }

        let row = [nuclide, x.value.getPretty(2),
          this.state.selectedCutPiece.activitiesMass.nuclideActivities.length !== 0 ?
            (this.state.selectedCutPiece.activitiesMass.nuclideActivities.find((a) => a.nuclide === x.nuclide) !== undefined ?
              (this.state.selectedCutPiece.activitiesMass.nuclideActivities.find((a) => a.nuclide === x.nuclide).value).getPretty(2)
              : "---") :
            "---",

          this.state.selectedCutPiece.activitiesSurface.nuclideActivities.length !== 0 ? (this.state.selectedCutPiece.activitiesSurface.nuclideActivities.find((a) => a.nuclide === x.nuclide) !== undefined ? (this.state.selectedCutPiece.activitiesSurface.nuclideActivities.find((a) => a.nuclide === x.nuclide).value).getPretty(2) : '---') : "---"].join(";");
        csvContent += row + "\r\n";
      });
      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", this.state.selectedCutPiece.name + "_Aktivitaeten.csv");
      document.body.appendChild(link); // Required for FF
      link.click(); // This will download the data file named "my_data.csv".
    }
  }
  setSortyBy(key, asc) {
    this.setState({ sortBy: key, asc: asc });
  }
  closeModal() {
    this.setState({ selectedVector: null, selectedCutPiece: null });
  }
  segmentNames(segments, full = false) {
    let string = "";
    for (let id in segments) {
      if (string.length > 15 && !full) {
        string += "...";
        break;
      }
      string += this.props.pContext.project.segments.find((y) => y.id === Number.parseInt(id)).name + " ";
    }
    return string;
  }
  render() {
    let cutpieces = [];
    if (this.props.pContext && this.props.pContext.project) {
      cutpieces = this.props.pContext.project.cutPieces;
    }
    if (this.state.filter !== "") {
      cutpieces = cutpieces.filter((cp) => cp.name.toLocaleUpperCase().includes(this.state.filter.toLocaleUpperCase()));
    }
    if (this.state.sortBy) {
      cutpieces = cutpieces.sort((a, b) => {
        let value = 0;
        if (typeof a[this.state.sortBy] === "number") {
          value = a[this.state.sortBy] - b[this.state.sortBy];
        } else {
          value = a[this.state.sortBy] > b[this.state.sortBy] ? 1 : -1;
        }
        if (!this.state.asc) {
          value = value * -1;
        }
        return value;
      });
    }
    return (
      <div className="p-2 flex flex-col gap-3">
        <h1 className="text-lg font-bold">Schnittprodukte</h1>
        <div className="flex gap-2 items-center">
          <label htmlFor="upload" className={"border border-gray-400 rounded-lg px-2 bg-gray-300 hover:bg-orange-200 "}>
            CSV hochladen
          </label>
          <input className="hidden" id="upload" type="file" onChange={this.fileChange} />
          <FontAwesomeIcon
            size="lg"
            onClick={() => this.setState({ showHelp: true })}
            className="text-gray-500 hover:text-opacity-70"
            icon={faInfoCircle}
          ></FontAwesomeIcon>
        </div>
        <SButton className="w-fit" onClick={this.exportCutpieces}>
          CSV Download
        </SButton>
        <ConfirmDialogContext.Consumer>
          {(ctx) => (
            <Table
              disableEdit={true}
              header={header}
              rows={cutpieces.map((c) => [
                c.components,
                c.name,
                c.mass.toFixed(2).replace(".", ","),
                c.surface === undefined ? "---" : c.surface.toFixed(2).replace(".", ","),
                <div className="d-td  hover:text-white hover:bg-gray-400 hover:cursor-pointer" onClick={() => this.setState({ selectedSegments: c.segments })}>
                  {this.segmentNames(c.segments)}
                </div>,
                !c.error ? c.activities ? c.activities.sumAct.getPretty(2) : "---" : "---",
                !c.error ? c.activities
                  ? c.activities.nuclideActivities.find((a) => a.nuclide === "Co-60")
                    ? c.activities.nuclideActivities.find((a) => a.nuclide === "Co-60").value.getPretty(2)
                    : "---"
                  : "---" : "---",
                !c.error ? c.activitiesMass
                  ? c.activitiesMass.nuclideActivities.find((a) => a.nuclide === "Co-60")
                    ? (c.activitiesMass.nuclideActivities.find((a) => a.nuclide === "Co-60").value).getPretty(2)
                    : "---"
                  : "---" : "---",
                !c.error ? c.activitiesSurface
                  ? c.activitiesSurface.nuclideActivities.find((a) => a.nuclide === "Co-60")
                    ? (c.activitiesSurface.nuclideActivities.find((a) => a.nuclide === "Co-60").value).getPretty(2)
                    : "---"
                  : "---" : "---",
                <SButton
                  disabled={c.error !== null}
                  className={c.error !== null ? "" : " hover:bg-gray-50 border border-gray-400 bg-gray-200"}
                  onClick={() => {
                    this.setState({ selectedCutPiece: c, selectedVector: c.activities.nuclideActivities.sort((a, b) => a.nuclide.localeCompare(b.nuclide)), });
                  }}
                >
                  Anzeigen
                </SButton>,
                <FontAwesomeIcon size="lg" className={c.used ? "text-green-600" : "text-red-600"} icon={c.used ? faCheck : faX}></FontAwesomeIcon>,

                <FontAwesomeIcon title={c.error ? c.error.errMsg : "Kein Fehler 😀"} icon={faInfoCircle} size="lg"></FontAwesomeIcon>,
              ])}
              options={[
                {
                  label: <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>,
                  onClick: (rows) => {
                    let names = rows.map((x) => x[1]);
                    let ids = this.props.pContext.project.cutPieces.filter((x) => {
                      return names.includes(x.name);
                    });
                    ids = ids.map((x) => x.id);
                    ctx.toggle(
                      "Wollen Sie die augewählten Schnittstücke unwiederruflich löschen?",
                      () => {
                        this.props.pContext.removeCutpieces(ids);
                        ctx.toggle();
                      },
                      () => {
                        ctx.toggle();
                      },
                    );
                  },
                },
                {
                  label: <FontAwesomeIcon icon={faFileExport}></FontAwesomeIcon>,
                  onClick: (rows) => {
                    let names = rows.map((x) => x[1]);
                    let ids = this.props.pContext.project.cutPieces.filter((x) => {
                      return names.includes(x.name);
                    });
                    ids = ids.map((x) => x.id);
                    this.props.pContext.exportCutpieceSegments(ids);
                  },
                },
              ]}
            ></Table>
          )}
        </ConfirmDialogContext.Consumer>

        {this.state.selectedVector ? (
          <div className={"absolute flex top-0 left-0 justify-center items-center backdrop-blur-sm w-full h-full z-30"}>
            <div className={"flex flex-col  bg-gray-100 p-2 border border-black rounded-lg h-5/6  w-1/2  "}>
              <div className="overflow-auto grow ">
                <article className="flex gap-2">
                  <p className="font-bold">Ref.-Zeitpunkt: </p>
                  <p>{this.props.pContext.project.refTimestamp.split("T")[0]}</p>
                </article>

                <table className="d-table">
                  <thead className="d-thead">
                    <tr className="d-tr">
                      <th className="d-th" scope="col">
                        Nuklid
                      </th>
                      <th className="d-th" scope="col">
                        Aktivität [Bq]
                      </th>
                      <th className="d-th" scope="col">
                        Aktivität [Bq/kg]
                      </th>
                      <th className="d-th" scope="col">
                        Aktivität [Bq/cm²]
                      </th>
                    </tr>
                  </thead>
                  <tbody className="d-tbody">
                    {this.state.selectedVector.map((x) => (
                      <tr className="d-tr" key={x.nuclide}>
                        <td className="d-td">{x.nuclide}</td>
                        <td className="d-td">{x.value.getPretty(2)}</td>
                        <td className="d-td">{this.state.selectedCutPiece.activitiesMass.nuclideActivities.length !== 0 ? (this.state.selectedCutPiece.activitiesMass.nuclideActivities.find((a) => a.nuclide === x.nuclide) !== undefined ? (this.state.selectedCutPiece.activitiesMass.nuclideActivities.find((a) => a.nuclide === x.nuclide).value).getPretty(2) : "---") : "---"}</td>
                        <td className="d-td">{this.state.selectedCutPiece.activitiesSurface.nuclideActivities.length !== 0 ? (this.state.selectedCutPiece.activitiesSurface.nuclideActivities.find((a) => a.nuclide === x.nuclide) !== undefined ? (this.state.selectedCutPiece.activitiesSurface.nuclideActivities.find((a) => a.nuclide === x.nuclide).value).getPretty(2) : "---") : "---"}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="flex justify-evenly pt-3">
                <div className="flex gap-1">
                  <SButton onClick={this.exportData}>Daten Export</SButton>
                  <div className="flex gap-1">
                    <input ref={this.hyphenRef} type="checkbox" />
                    <label>Nuklide ohne Bindestrich</label>
                  </div>
                </div>
                <SButton onClick={this.closeModal}>Schließen</SButton>
              </div>
            </div>
          </div>
        ) : null}
        {this.state.selectedSegments ? (
          <div className="absolute w-screen  h-screen top-0 left-0  backdrop-blur-sm flex  items-center justify-center z-40">
            <div className="bg-gray-200 p-5 border  max-h-screen  border-black shadow-lg rounded-lg flex flex-col gap-1 ">
              <div className="h-3/4 overflow-auto">
                <table className="border border-black">
                  <thead className="font-semibold sticky">
                    <tr>
                      <th className="border border-black px-2">Segment</th>
                      <th className="border border-black px-2">Masse [kg]</th>
                      <th className="border border-black px-2">Oberfläche [cm²]</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(this.state.selectedSegments).map((x) => {
                      return (
                        <tr key={x}>
                          <td className="border border-black">
                            {
                              this.props.pContext.project.segments.find((y) => {

                                return y.id === Number.parseInt(x);
                              }).name
                            }
                          </td>
                          <td className="border border-black">
                            {this.props.pContext.project.segments.find((y) => {
                              
                              return y.id === Number.parseInt(x);
                            }).unit === "MASS" ?


                              this.state.selectedSegments[x].toFixed(2).replace(".", ",") : '---'}</td>
                          <td className="border border-black">
                            {this.props.pContext.project.segments.find((y) => {
                              
                              return y.id === Number.parseInt(x);
                            }).unit === "SURFACE" ?


                              this.state.selectedSegments[x].toFixed(2).replace(".", ",") : '---'}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <SButton
                className="h-16"
                onClick={() => {
                  this.setState({ selectedSegments: null });
                }}
              >
                Schließen
              </SButton>
            </div>
          </div>
        ) : null}
        {this.state.showHelp ? (
          <InfoModal close={() => this.setState({ showHelp: false })}>
            <h1>Beispiel für eine Schnittprodukt-datei</h1>
            <Table
              disableSearch={true}
              disableSelect={true}
              disableEdit={true}
              header={["Komponenten", "ID", "Schnittstueck-Oberflaeche [cm²]", "Segment 1", "Segment 2", "Segment 3", "Segment ..."]}
              rows={[
                [
                  "Komponente 1, Komponente 2",
                  "Meine ID",
                  "Oberfläche 1",
                  "[Masse von Segment 1 in kg]",
                  "[Masse von Segment 2 in kg]",
                  "[Masse von Segment 3 in kg]",
                  "[Masse von Segment ... in kg]",
                ],
                [
                  "Komponente 3",
                  "Meine ID 2",
                  "Oberfläche 2",
                  "[Masse von Segment 1 in kg]",
                  "[Masse von Segment 2 in kg]",
                  "[Masse von Segment 3 in kg]",
                  "[Masse von Segment ... in kg]",
                ],
                ["...", "...", "...", "...", "...", "...", "..."],
              ]}
            ></Table>
<p className="mt-6"></p>
            <Table
              disableSearch={true}
              disableSelect={true}
              disableEdit={true}
              isEd
              header={["Komponenten", "ID", "Segment 1", "Segment 2", "Segment 3", "Segment ..."]}
              rows={[
                [
                  "Komponente 1, Komponente 2",
                  "Meine ID",
                  
                  "[Oberfläche/Masse von Segment 1 in cm²/kg]",
                  "[Oberfläche/Masse von Segment 2 in cm²/kg]",
                  "[Oberfläche/Masse von Segment 3 in cm²/kg]",
                  "[Oberfläche/Masse von Segment ... in cm²/kg]",
                ],
                [
                  "Komponente 3",
                  "Meine ID 2",
                  
                  "[Oberfläche/Masse von Segment 1 in cm²/kg]",
                  "[Oberfläche/Masse von Segment 2 in cm²/kg]",
                  "[Oberfläche/Masse von Segment 3 in cm²/kg]",
                  "[Oberfläche/Masse von Segment ... in cm²/kg]",
                ],
                ["...", "...", "...", "...", "...", "..."],
              ]}
            ></Table>
          </InfoModal>
        ) : null}
      </div>
    );
  }
}

export default CutPieceView;
