import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faCheck, faX } from "@fortawesome/free-solid-svg-icons";
import Setting from "./Setting";
import SettingButton from "./SettingButton";
import SettingCategory from "./SettingCategory";
import SettingLabel from "./SettingLabel";
import SettingsHeader from "./SettingsHeader";
import SButton from "../styledComponents/SButton";
import { removeProject, updateSettings, updateName } from "../api";
import { LoadingContext } from "../context/LoadingContext";
import { ConfirmDialogContext } from "../context/ConfirmDialogContext";
import MemberManager from "../utilityComponents/MemberManager";
import EnrichmentSelect from "../utilityComponents/EnrichmentSelect";
import { validate } from "../Utility/ResponseConsumer";
import { NotificationContext } from "../context/NotificationContext";
import SInput from "../styledComponents/SInput";

class SettingView extends Component {
  static contextType = LoadingContext;
  constructor(props) {
    super(props);
    this.state = {
      editProjectName: false,
      newProjectNamee: { name: "" },
      timeStamp: new Date(this.props.pContext.project.refTimestamp).getTime(),
      u233: this.props.pContext.project.u233Enrichment,
      u235: this.props.pContext.project.u235Enrichment,
      showDialog: false,
      projectSettings: this.props.pContext.project.settings.reduce(
        (obj, setting) => Object.assign(obj, { [setting.setting.name]: setting.value ? setting.value.value : 0 }),
        {},
      ),
      changed: false,
    };
    this.save = this.save.bind(this);
    this.saveProjectSettings = this.saveProjectSettings.bind(this);
  }
  save() {
    let settings = {};
    if (this.state.u233 !== this.props.pContext.project.u233Enrichment) {
      settings.u233Enrichment = this.state.u233;
    }
    if (this.state.u235 !== this.props.pContext.project.u235Enrichment) {
      settings.u235Enrichment = this.state.u235;
    }
    if (this.state.timeStamp !== this.props.pContext.project.refTimestamp) {
      settings.refTimestamp = this.state.timeStamp;
    }
    if (Object.keys(settings).length > 0) {
      this.context.toggle();
   
      updateSettings(
        this.props.pContext.project.id,
        settings,
        validate(
          (body) => {
            this.context.toggle();
            if (this.props.notification) {
              this.props.notification.add("Einstellungen gespeichert!");
            }
            this.props.pContext.refresh(body);
          },
          (body) => {
            this.context.toggle();
            if (this.props.notification) {
              this.props.notification.add(body.message, true);
            }
          },
        ),
      );
    }
  }
  saveProjectSettings() {
    if (Object.keys(this.state.projectSettings).length > 0) {
      this.context.toggle();
      let arr = Object.keys(this.state.projectSettings).map((x) => {
        return { name: x, value: this.state.projectSettings[x] };
      });
      this.props.pContext.updateProjectSettings(this.props.pContext.project.id, arr, this.context.toggle);
    }
  }
  getCommonDates() {
    if (!this.props.pContext || this.props.pContext.project.segments.length === 0) {
      return [];
    }
    let dates = this.props.pContext.project.segments[0].dates;
    for (let segment of this.props.pContext.project.segments) {
      for (let date of segment.dates) {
        if (!dates.includes(date)) {
          dates.push(date);
        }
      }
    }
    return dates;
  }

  editProjectName = (para) => {
    if(para === 'save'){
      this.context.toggle();
      updateName(
        this.props.pContext.project.id,
     this.state.newProjectNamee.name.replace(/"/g, ''),
        validate(
          (body) => {
            this.context.toggle();
            if (this.props.notification) {
              this.props.notification.add("Einstellungen gespeichert!");
            }
            this.props.pContext.refresh(body);
          },
          (body) => {
            this.context.toggle();
            if (this.props.notification) {
              this.props.notification.add(body.message, true);
            }
          },
        ),
      );
    }

    this.setState({editProjectName: !this.state.editProjectName, })
  }
  render() {
    let commonDates = this.getCommonDates();
    let refDateAsMS = new Date(this.props.pContext.project.refTimestamp).getTime();
    return (
      <div className="m-2">
        {this.state.editProjectName ? <div className="flex items-center"><h1 className="text-lg font-semibold uppercase ">Einstellungen vom Project: </h1>
          <SInput
            className="text-lg font-semibold uppercase text-center"
            type="text"
            onChange={(e) => {
              this.setState((prevState) => ({
                newProjectNamee: {
                  name: e.target.value,
                },
              }));
            }} />
          <FontAwesomeIcon className="ml-4 w-auto" icon={faCheck} onClick={() => this.editProjectName('save')}></FontAwesomeIcon>
          <FontAwesomeIcon className="ml-4 w-auto" icon={faX} onClick={() => this.editProjectName()}></FontAwesomeIcon>
          </div> :
          <div className="flex items-center"><h1 className="text-lg font-semibold uppercase ">Einstellungen vom Project: {this.props.pContext.project.name}</h1>
            <FontAwesomeIcon className="ml-4 w-auto" icon={faPencil} onClick={() => this.editProjectName()}></FontAwesomeIcon></div>}
        <NotificationContext.Consumer>
          {(notification) => (
            <ConfirmDialogContext.Consumer>
              {(ctx) => (
                <div className="flex flex-col gap-2">
                  <SettingCategory>
                    <SettingsHeader>Mitglieder</SettingsHeader>
                    <MemberManager notification={this.props.notification ? this.props.notification : null}></MemberManager>
                  </SettingCategory>
                  <SettingCategory>
                    <SettingsHeader>Daten</SettingsHeader>
                    <Setting>
                      <SettingLabel>Ref.-Zeitpunkt</SettingLabel>
                      <select className="p-1 rounded-md " value={this.state.timeStamp} onChange={(evt) => this.setState({ timeStamp: evt.target.value })}>
                        {commonDates.includes(refDateAsMS) ? null : <option value={this.props.pContext.project.refTimestamp}>---</option>}
                        {commonDates.map((date) => (
                          <option key={date} value={date}>
                            {new Date(date).toLocaleDateString("de-DE")}
                          </option>
                        ))}
                      </select>
                    </Setting>
                    <Setting>
                      <SettingLabel>Anreicherung U-233</SettingLabel>
                      <EnrichmentSelect
                        onChange={(evt) => {
                          this.setState({ u233: evt.target.value });
                        }}
                        value={this.state.u233}
                      ></EnrichmentSelect>
                    </Setting>
                    <Setting>
                      <SettingLabel>Anreicherung U-235</SettingLabel>
                      <EnrichmentSelect
                        onChange={(evt) => {
                          this.setState({ u235: evt.target.value });
                        }}
                        value={this.state.u235}
                      ></EnrichmentSelect>
                    </Setting>
                    <SButton onClick={this.save}>Speichern</SButton>
                  </SettingCategory>
                  <SettingCategory>
                    <SettingsHeader>Verwalten</SettingsHeader>
                    <Setting>
                      <SettingLabel>Projekt löschen</SettingLabel>

                      <SettingButton
                        onClick={() => {
                          ctx.toggle(
                            "Wollen Sie das Projekt " + this.props.pContext.project.name + " unwiederruflich löschen?",
                            () => {
                              removeProject(
                                this.props.pContext.project.id,
                                validate(
                                  (body) => {
                                    this.props.pContext.deselect();
                                    ctx.toggle();
                                  },
                                  (error) => {
                                    ctx.toggle();
                                    notification.add(error.message, true);
                                  },
                                ),
                              );
                            },
                            () => {
                              ctx.toggle();
                            },
                          );
                        }}
                      >
                        LÖSCHEN
                      </SettingButton>
                    </Setting>
                  </SettingCategory>
                  <SettingCategory>
                    <SettingsHeader>Sonstige Einstellungen</SettingsHeader>
                    {this.props.pContext.project.settings
                      ? this.props.pContext.project.settings.map((x, idx) => {
                        return (
                          <Setting key={idx}>
                            <SettingLabel>{x.setting.name}</SettingLabel>
                            <select
                              onChange={(evt) => {
                                this.state.projectSettings[x.setting.name] = evt.target.value;
                                this.setState({ projectSettings: this.state.projectSettings, changed: true });
                              }}
                              value={this.state.projectSettings[x.setting.name]}
                              className="p-1 rounded-md w-fit"
                            >
                              {x.setting.options.map((o) => (
                                <option key={o.value} value={o.value}>
                                  {o.label}
                                </option>
                              ))}
                            </select>
                          </Setting>
                        );
                      })
                      : null}
                    <SButton onClick={this.saveProjectSettings}>Speichern</SButton>
                  </SettingCategory>
                </div>
              )}
            </ConfirmDialogContext.Consumer>
          )}
        </NotificationContext.Consumer>
      </div>
    );
  }
}

export default SettingView;
