let a1 =
  "Radionuklid;Abfallproduktgruppe 01;Abfallproduktgruppe 02;Abfallproduktgruppe 03;Abfallproduktgruppe 04;Abfallproduktgruppe 05;Abfallproduktgruppe 06 \r\n\r\r\n\n\r\n\
Na-22;2,3E+09;8,0E+10;2,9E+11;7,3E+11;2,3E+12;2,3E+12\r\n\r\r\n\n\r\n\
Cl-36;6,0E+09;6,0E+09;6,0E+09;6,0E+09;6,0E+09;6,0E+09\r\n\
Co-60;5,0E+09;1,7E+11;6,1E+11;1,6E+12;5,0E+12;5,0E+12\r\n\
Se-79;7,0E+08;2,4E+10;8,7E+10;2,1E+11;7,0E+11;7,0E+11\r\n\
Rb-87;3,4E+09;1,2E+11;4,1E+11;1,1E+12;3,4E+12;3,4E+12\r\n\
Sr-90;8,6E+08;3,0E+10;1,1E+11;2,7E+11;8,6E+11;8,6E+11\r\n\
Nb-94;1,1E+09;3,9E+10;1,4E+11;3,6E+11;1,1E+12;1,1E+12\r\n\
Ag-108m;9,6E+08;3,4E+10;1,2E+11;3,0E+11;9,6E+11;9,6E+11\r\n\
Cd-113m;7,3E+08;2,6E+10;9,1E+10;2,1E+11;7,3E+11;7,3E+11\r\n\
I-125;2,1E+10;2,1E+10;2,1E+10;2,1E+10;2,1E+10;2,1E+10\r\n\
Sn-126;7,3E+08;2,6E+10;9,1E+10;2,1E+11;7,3E+11;7,3E+11\r\n\
I-129;4,3E+08;4,3E+08;4,3E+08;4,3E+08;4,3E+08;4,3E+08\r\n\
Cs-137;5,1E+09;1,9E+11;6,4E+11;1,7E+12;5,1E+12;5,1E+12\r\n\
Eu-152;4,4E+09;1,6E+11;5,4E+11;1,4E+12;4,4E+12;4,4E+12\r\n\
Pb-210;1,4E+08;5,0E+09;1,7E+10;4,3E+10;1,4E+11;1,4E+11\r\n\
Ac-227;5,1E+07;2,6E+09;6,4E+09;1,6E+10;5,1E+10;5,1E+10\r\n\
Ra-228;7,3E+08;2,7E+10;9,1E+10;2,1E+11;7,3E+11;7,3E+11\r\n\
Am-242m;7,0E+08;3,6E+10;8,7E+10;2,1E+11;7,0E+11;7,0E+11\r\n\
Ra-226;6,3E+07;2,1E+09;7,9E+09;2,0E+10;6,3E+10;6,3E+10\r\n\
Th-228;7,0E+08;3,6E+10;8,7E+10;2,1E+11;7,0E+11;7,0E+11\r\n\
Pa-231;6,0E+07;3,0E+09;7,4E+09;1,9E+10;6,0E+10;6,0E+10\r\n\
Th-232;1,4E+08;5,1E+09;1,7E+10;4,3E+10;1,4E+11;1,4E+11\r\n\
U-232;3,1E+08;1,6E+10;4,0E+10;9,9E+10;3,1E+11;3,1E+11\r\n\
Np-237;2,1E+08;7,9E+09;2,7E+10;6,9E+10;2,1E+11;2,1E+11\r\n\
Pu-239;8,3E+08;4,1E+10;1,0E+11;2,6E+11;8,3E+11;8,3E+11\r\n\
Am-241;7,6E+08;3,7E+10;9,3E+10;2,3E+11;7,6E+11;7,6E+11\r\n\
Am-243;7,6E+08;3,7E+10;9,3E+10;2,3E+11;7,6E+11;7,6E+11\r\n\
Cm-245;7,3E+08;3,6E+10;9,1E+10;2,1E+11;7,3E+11;7,3E+11\r\n\
Cm-246;7,6E+08;3,7E+10;9,3E+10;2,3E+11;7,6E+11;7,6E+11\r\n\
Cm-248;1,3E+08;6,4E+09;1,6E+10;4,0E+10;1,3E+11;1,3E+11\r\n\
H-3;2,1E+14;2,1E+14;2,1E+14;2,1E+14;4,4E+14;2,1E+14\r\n\
Be-10;8,6E+11;4,3E+13;1,1E+14;2,7E+14;8,6E+14;8,6E+14\r\n\
C-14;1,0E+12;1,0E+12;1,0E+12;1,0E+12;2,1E+15;2,1E+15\r\n\
S-35;2,4E+11;8,7E+12;3,1E+13;7,7E+13;2,4E+14;2,4E+14\r\n\
Ar-39;2,9E+16;2,9E+16;2,9E+16;2,9E+16;2,9E+16;2,9E+16\r\n\
Ca-41;1,6E+10;5,4E+11;2,0E+12;5,0E+12;1,6E+13;1,6E+13\r\n\
Ca-45;1,1E+11;3,7E+12;1,3E+13;3,4E+13;1,1E+14;1,1E+14\r\n\
Sc-46;1,3E+11;4,7E+12;1,7E+13;4,1E+13;1,3E+14;1,3E+14\r\n\
V-49;7,0E+13;2,4E+15;8,7E+15;2,1E+16;7,0E+16;7,0E+16\r\n\
Cr-51;1,2E+13;4,3E+14;1,6E+15;3,7E+15;1,2E+16;1,2E+16\r\n\
Mn-54;8,6E+10;3,0E+12;1,1E+13;2,7E+13;8,6E+13;8,6E+13\r\n\
Fe-55;1,4E+13;4,9E+14;1,7E+15;4,3E+15;1,4E+16;1,4E+16\r\n\
Co-57;7,0E+11;2,4E+13;8,7E+13;2,1E+14;7,0E+14;7,0E+14\r\n\
Co-58;3,1E+11;1,1E+13;3,9E+13;9,7E+13;3,1E+14;3,1E+14\r\n\
Fe-59;4,1E+11;1,4E+13;5,1E+13;1,3E+14;4,1E+14;4,1E+14\r\n\
Ni-59;7,6E+11;2,7E+13;9,3E+13;2,3E+14;7,6E+14;7,6E+14\r\n\
Ni-63;7,0E+11;2,4E+13;8,7E+13;2,1E+14;7,0E+14;7,0E+14\r\n\
Zn-65;2,3E+10;8,0E+11;2,9E+12;7,3E+12;2,3E+13;2,3E+13\r\n\
Kr-85;2,1E+16;2,1E+16;2,1E+16;2,1E+16;2,1E+16;2,1E+16\r\n\
Sr-89;3,9E+11;1,4E+13;4,9E+13;1,2E+14;3,9E+14;3,9E+14\r\n\
Mo-93;7,6E+10;2,6E+12;9,4E+12;2,3E+13;7,6E+13;7,6E+13\r\n\
Nb-93m;4,7E+11;1,7E+13;5,9E+13;1,4E+14;4,7E+14;4,7E+14\r\n\
Zr-93;2,4E+11;8,9E+12;3,1E+13;7,9E+13;2,4E+14;2,4E+14\r\n\
Zr-95;2,1E+11;7,4E+12;2,7E+13;6,7E+13;2,1E+14;2,1E+14\r\n\
Nb-95;5,7E+11;2,1E+13;7,1E+13;1,9E+14;5,7E+14;5,7E+14\r\n\
Tc-99;5,4E+10;2,0E+12;6,9E+12;1,7E+13;5,4E+13;5,4E+13\r\n\
Ru-103;1,2E+12;4,1E+13;1,6E+14;3,7E+14;1,2E+15;1,2E+15\r\n\
Ru-106;2,4E+11;8,9E+12;3,1E+13;7,7E+13;2,4E+14;2,4E+14\r\n\
Pd-107;1,1E+12;3,6E+13;1,4E+14;3,6E+14;1,1E+15;1,1E+15\r\n\
Cd-109;9,3E+10;3,6E+12;1,2E+13;2,9E+13;9,3E+13;9,3E+13\r\n\
Ag-110m;2,4E+10;8,9E+11;3,1E+12;7,7E+12;2,4E+13;2,4E+13\r\n\
Sb-125;3,6E+10;1,2E+12;4,6E+12;1,1E+13;3,6E+13;3,6E+13\r\n\
Te-125m;2,6E+11;9,3E+12;3,3E+13;8,0E+13;2,6E+14;2,6E+14\r\n\
H-3;2,1E+14;2,1E+14;2,1E+14;2,1E+14;4,4E+14;2,1E+14\r\n\
Be-10;8,6E+11;4,3E+13;1,1E+14;2,7E+14;8,6E+14;8,6E+14\r\n\
C-14;1,0E+12;1,0E+12;1,0E+12;1,0E+12;2,1E+15;2,1E+15\r\n\
S-35;2,4E+11;8,7E+12;3,1E+13;7,7E+13;2,4E+14;2,4E+14\r\n\
Ar-39;2,9E+16;2,9E+16;2,9E+16;2,9E+16;2,9E+16;2,9E+16\r\n\
Ca-41;1,6E+10;5,4E+11;2,0E+12;5,0E+12;1,6E+13;1,6E+13\r\n\
Ca-45;1,1E+11;3,7E+12;1,3E+13;3,4E+13;1,1E+14;1,1E+14\r\n\
Sc-46;1,3E+11;4,7E+12;1,7E+13;4,1E+13;1,3E+14;1,3E+14\r\n\
V-49;7,0E+13;2,4E+15;8,7E+15;2,1E+16;7,0E+16;7,0E+16\r\n\
Cr-51;1,2E+13;4,3E+14;1,6E+15;3,7E+15;1,2E+16;1,2E+16\r\n\
Mn-54;8,6E+10;3,0E+12;1,1E+13;2,7E+13;8,6E+13;8,6E+13\r\n\
Fe-55;1,4E+13;4,9E+14;1,7E+15;4,3E+15;1,4E+16;1,4E+16\r\n\
Co-57;7,0E+11;2,4E+13;8,7E+13;2,1E+14;7,0E+14;7,0E+14\r\n\
Co-58;3,1E+11;1,1E+13;3,9E+13;9,7E+13;3,1E+14;3,1E+14\r\n\
Fe-59;4,1E+11;1,4E+13;5,1E+13;1,3E+14;4,1E+14;4,1E+14\r\n\
Ni-59;7,6E+11;2,7E+13;9,3E+13;2,3E+14;7,6E+14;7,6E+14\r\n\
Ni-63;7,0E+11;2,4E+13;8,7E+13;2,1E+14;7,0E+14;7,0E+14\r\n\
Zn-65;2,3E+10;8,0E+11;2,9E+12;7,3E+12;2,3E+13;2,3E+13\r\n\
Kr-85;2,1E+16;2,1E+16;2,1E+16;2,1E+16;2,1E+16;2,1E+16\r\n\
Sr-89;3,9E+11;1,4E+13;4,9E+13;1,2E+14;3,9E+14;3,9E+14\r\n\
Mo-93;7,6E+10;2,6E+12;9,4E+12;2,3E+13;7,6E+13;7,6E+13\r\n\
Nb-93m;4,7E+11;1,7E+13;5,9E+13;1,4E+14;4,7E+14;4,7E+14\r\n\
Zr-93;2,4E+11;8,9E+12;3,1E+13;7,9E+13;2,4E+14;2,4E+14\r\n\
Zr-95;2,1E+11;7,4E+12;2,7E+13;6,7E+13;2,1E+14;2,1E+14\r\n\
Nb-95;5,7E+11;2,1E+13;7,1E+13;1,9E+14;5,7E+14;5,7E+14\r\n\
Tc-99;5,4E+10;2,0E+12;6,9E+12;1,7E+13;5,4E+13;5,4E+13\r\n\
Ru-103;1,2E+12;4,1E+13;1,6E+14;3,7E+14;1,2E+15;1,2E+15\r\n\
Ru-106;2,4E+11;8,9E+12;3,1E+13;7,7E+13;2,4E+14;2,4E+14\r\n\
Pd-107;1,1E+12;3,6E+13;1,4E+14;3,6E+14;1,1E+15;1,1E+15\r\n\
Cd-109;9,3E+10;3,6E+12;1,2E+13;2,9E+13;9,3E+13;9,3E+13\r\n\
Ag-110m;2,4E+10;8,9E+11;3,1E+12;7,7E+12;2,4E+13;2,4E+13\r\n\
Sb-125;3,6E+10;1,2E+12;4,6E+12;1,1E+13;3,6E+13;3,6E+13\r\n\
Te-125m;2,6E+11;9,3E+12;3,3E+13;8,0E+13;2,6E+14;2,6E+14\r\n\
Ba-133;1,4E+10;4,9E+11;1,7E+12;4,3E+12;1,4E+13;1,4E+13\r\n\
Cs-134;1,9E+10;6,6E+11;2,3E+12;5,7E+12;1,9E+13;1,9E+13\r\n\
Cs-135;9,1E+10;3,3E+12;1,1E+13;2,9E+13;9,1E+13;9,1E+13\r\n\
Ce-144;4,1E+11;1,6E+13;5,1E+13;1,3E+14;4,1E+14;4,1E+14\r\n\
Pm-147;6,4E+12;2,7E+14;8,0E+14;2,1E+15;6,4E+15;6,4E+15\r\n\
Sm-151;1,2E+13;4,3E+14;1,4E+15;3,7E+15;1,2E+16;1,2E+16\r\n\
Eu-154;6,3E+09;2,1E+11;7,9E+11;2,0E+12;6,3E+12;6,3E+12\r\n\
Eu-155;2,1E+11;7,1E+12;2,6E+13;6,4E+13;2,1E+14;2,1E+14\r\n\
Hf-175;5,1E+11;1,9E+13;6,4E+13;1,6E+14;5,1E+14;5,1E+14\r\n\
Hf-181;1,9E+11;6,4E+12;2,1E+13;5,7E+13;1,9E+14;1,9E+14\r\n\
Ta-182;8,6E+10;3,1E+12;1,1E+13;2,7E+13;8,6E+13;8,6E+13\r\n\
Hg-203;4,1E+11;1,6E+13;5,1E+13;1,3E+14;4,1E+14;4,1E+14\r\n\
Po-210;7,0E+09;2,4E+11;8,7E+11;2,1E+12;7,0E+12;7,0E+12\r\n\
Ra-223;1,3E+10;4,4E+11;1,7E+12;4,0E+12;1,3E+13;1,3E+13\r\n\
Th-227;1,1E+10;5,6E+11;1,4E+12;3,6E+12;1,1E+13;1,1E+13\r\n\
Th-230;8,3E+08;4,1E+10;1,0E+11;2,6E+11;8,3E+11;8,3E+11\r\n\
Pa-233;3,6E+12;1,2E+14;4,6E+14;1,1E+15;3,6E+15;3,6E+15\r\n\
U-233;2,1E+09;1,1E+11;2,7E+11;6,9E+11;2,1E+12;2,1E+12\r\n\
Th-234;2,1E+12;7,6E+13;2,6E+14;6,6E+14;2,1E+15;2,1E+15\r\n\
U-234;2,3E+09;1,2E+11;3,0E+11;7,4E+11;2,3E+12;2,3E+12\r\n\
U-235;2,4E+09;1,3E+11;3,1E+11;7,9E+11;2,4E+12;2,4E+12\r\n\
U-236;2,3E+09;1,2E+11;3,0E+11;7,4E+11;2,3E+12;2,3E+12\r\n\
Pu-236;2,3E+09;1,2E+11;2,9E+11;7,3E+11;2,3E+12;2,3E+12\r\n\
U-238;2,4E+09;1,3E+11;3,1E+11;7,9E+11;2,4E+12;2,4E+12\r\n\
Pu-238;8,9E+08;4,4E+10;1,1E+11;2,7E+11;8,9E+11;8,9E+11\r\n\
Pu-240;8,3E+08;4,1E+10;1,0E+11;2,6E+11;8,3E+11;8,3E+11\r\n\
Pu-241;1,7E+10;8,6E+11;2,1E+12;5,3E+12;1,7E+13;1,7E+13\r\n\
Pu-242;8,6E+08;4,3E+10;1,1E+11;2,7E+11;8,6E+11;8,6E+11\r\n\
Cm-242;2,1E+10;1,0E+12;2,4E+12;6,4E+12;2,1E+13;2,1E+13\r\n\
Cm-243;1,2E+09;5,9E+10;1,4E+11;3,6E+11;1,2E+12;1,2E+12\r\n\
Pu-244;8,6E+08;4,3E+10;1,1E+11;2,7E+11;8,6E+11;8,6E+11\r\n\
Cm-244;1,4E+09;6,9E+10;1,7E+11;4,3E+11;1,4E+12;1,4E+12\r\n\
Cm-247;8,6E+08;4,3E+10;1,1E+11;2,7E+11;8,6E+11;8,6E+11";
let a2 =
  "Radionuklid;Abfallproduktgruppe 01-06\r\n\
Na-22;5,7E+13\r\n\
Cl-36;1,4E+11\r\n\
Co-60;1,2E+14\r\n\
Se-79;1,7E+13\r\n\
Rb-87;8,4E+13\r\n\
Sr-90;2,1E+13\r\n\
Nb-94;;2,7E+13\r\n\
Ag-108m;2,3E+13\r\n\
Cd-113m;1,9E+13\r\n\
I-125;5,1E+11\r\n\
Sn-126;1,9E+13\r\n\
I-129;1,1E+10\r\n\
Cs-137;1,3E+14\r\n\
Eu-152;1,1E+14\r\n\
Pb-210;3,4E+12\r\n\
Ac-227;1,3E+12\r\n\
Ra-228;1,9E+13\r\n\
Am-242m;1,7E+13\r\n\
Ra-226;1,6E+12\r\n\
Th-228;1,7E+13\r\n\
Pa-231;1,4E+12\r\n\
Th-232;3,4E+12\r\n\
U-232;7,9E+12\r\n\
Np-237;5,4E+12\r\n\
Pu-239;2,1E+13\r\n\
Am-241;1,9E+13\r\n\
Am-243;1,9E+13\r\n\
Cm-245;1,9E+13\r\n\
Cm-246;1,9E+13\r\n\
Cm-248;3,3E+12\r\n\
H-3;5,4E+16\r\n\
Be-10;2,1E+16\r\n\
C-14;1,7E+14\r\n\
S-35;6,1E+15\r\n\
Ar-39;7,1E+17\r\n\
Ca-41;4,0E+14\r\n\
Ca-45;2,7E+15\r\n\
Sc-46;3,3E+15\r\n\
V-49;1,7E+18\r\n\
Cr-51;3,0E+17\r\n\
Mn-54;2,1E+15\r\n\
Fe-55;3,4E+17\r\n\
Co-57;1,7E+16\r\n\
Co-58;7,9E+15\r\n\
Fe-59;1,0E+16\r\n\
Ni-59;1,9E+16\r\n\
Ni-63;1,7E+16\r\n\
Zn-65;5,7E+14\r\n\
Kr-85;5,6E+17\r\n\
Sr-89;9,7E+15\r\n\
Mo-93;1,9E+15\r\n\
Nb-93m;1,2E+16\r\n\
Zr-93;6,3E+15\r\n\
Zr-95;5,4E+15\r\n\
Nb-95;1,4E+16\r\n\
Tc-99;1,3E+15\r\n\
Ru-103;3,0E+16\r\n\
Ru-106;6,1E+15\r\n\
Pd-107;2,7E+16\r\n\
Cd-109;2,3E+15\r\n\
Ag-110m;6,1E+14\r\n\
Sb-125;9,1E+14\r\n\
Te-125m;6,4E+15\r\n\
Ba-133;3,4E+14\r\n\
Cs-134;4,6E+14\r\n\
Cs-135;2,1E+15\r\n\
Ce-144;1,0E+16\r\n\
Pm-147;1,6E+17\r\n\
Sm-151;3,0E+17\r\n\
Eu-154;1,6E+14\r\n\
Eu-155;5,0E+15\r\n\
Hf-175;1,3E+16\r\n\
Hf-181;4,6E+15\r\n\
Ta-182;2,1E+15\r\n\
Hg-203;1,0E+16\r\n\
Po-210;1,7E+14\r\n\
Ra-223;3,3E+14\r\n\
Th-227;2,7E+14\r\n\
Th-230;2,1E+13\r\n\
Pa-233;9,1E+16\r\n\
U-233;5,4E+13\r\n\
Th-234;5,1E+16\r\n\
U-234;5,9E+13\r\n\
U-235;6,3E+13\r\n\
U-236;5,9E+13\r\n\
Pu-236;5,7E+13\r\n\
U-238;6,3E+13\r\n\
Pu-238;2,1E+13\r\n\
Pu-240;2,1E+13\r\n\
Pu-241;4,3E+14\r\n\
Pu-242;2,1E+13\r\n\
Cm-242;5,0E+14\r\n\
Cm-243;2,9E+13\r\n\
Pu-244;2,1E+13\r\n\
Cm-244;3,4E+13\r\n\
Cm-247;2,1E+13";
export function getNoLimitNuclides() {
  return [
    "Bi-211",
    "At-217",
    "Y-90",
    "In-111",
    "Tl-207",
    "Fr-223",
    "o-211",
    "Rn-219",
    "Nb-95m",
    "Sb-126m",
    "Tl-208",
    "U-237",
    "Po-212",
    "Rn-220",
    "Mo-99",
    "I-131",
    "Tl-209",
    "U-240",
    "Po-213",
    "Fr-221",
    "Tc-99m",
    "Ba-137m",
    "Pb-209",
    "Np-238",
    "Po-214",
    "Ac-225",
    "Rh-103m",
    "Pr-144",
    "Pb-211",
    "Np-239",
    "Po-215",
    "Rh-106",
    "Pr-144m",
    "Pb-212",
    "Np-240m",
    "Po-216",
    "Ag-108",
    "Hg-197",
    "Bi-212",
    "Am-242",
    "Po-218",
    "Ag-110",
    "Tl-201",
    "Bi-213",
    "Nd-144",
    "Sm-147",
    "Sm-148",
    "Gd-152",
  ];
}
export function getTabelle10() {
  return [
    "Be-7",
    "Tc-95m",
    "Pr-143",
    "Au-195",
    "Al-26",
    "Tc-97",
    "Sm-145",
    "Tl-204",
    "Si-32",
    "Rh-101",
    "Pm-145",
    "Bi-205",
    "P-32",
    "Rh-102",
    "Pm-146",
    "Bi-207",
    "P-33",
    "Rh-102m",
    "Nd-147",
    "Bi-208",
    "Ar-37",
    "Sn-113",
    "Pm-148m",
    "Po-208",
    "K-40",
    "In-114m",
    "Gd-153",
    "Bi-210m",
    "Ti-44",
    "Cd-115m",
    "Eu-156",
    "Ra-225",
    "V-48",
    "Sn-117m",
    "Tb-160",
    "Th-229",
    "Mn-53",
    "Sn-119m",
    "Ho-166m",
    "Np-236",
    "Co-56",
    "Sn-121m",
    "Yb-169",
    "Cm-241",
    "Ge-68",
    "Te-121m",
    "Tm-170",
    "Pu-246",
    "As-73",
    "Te-123m",
    "Tm-171",
    "Bk-249",
    "Se-75",
    "Sn-123",
    "Hf-172",
    "Cf-249",
    "Kr-81",
    "Sb-124",
    "Lu-173",
    "Cm-250",
    "Sr-82",
    "Sb-126",
    "Lu-174",
    "Cf-250",
    "Rb-83",
    "Te-127m",
    "Lu-176",
    "Cf-251",
    "Rb-84",
    "Te-129m",
    "Hf-178m",
    "Cf-252",
    "Sr-85",
    "Xe-131m",
    "Ta-179",
    "Cf-253",
    "Rb-86",
    "Cs-136",
    "W-181",
    "Es-253",
    "Y-88",
    "Ce-139",
    "W-185",
    "Es-254",
    "Y-91",
    "Ba-140",
    "Ir-192",
    "Cf-254",
    "Nb-92",
    "Ce-141",
    "Pt-193",
  ];
}
export function generateFromStrings() {
  let map = new Map();
  let containerType1 = new Map();
  let containerType2 = new Map();
  map.set(1, containerType1);
  map.set(2, containerType2);
  let containerType1WasteGroup1 = new Map();
  let containerType1WasteGroup2 = new Map();
  let containerType1WasteGroup3 = new Map();
  let containerType1WasteGroup4 = new Map();
  let containerType1WasteGroup5 = new Map();
  let containerType1WasteGroup6 = new Map();
  containerType1.set(1, containerType1WasteGroup1);
  containerType1.set(2, containerType1WasteGroup2);
  containerType1.set(3, containerType1WasteGroup3);
  containerType1.set(4, containerType1WasteGroup4);
  containerType1.set(5, containerType1WasteGroup5);
  containerType1.set(6, containerType1WasteGroup6);
  let containerType2AllWasteGroups = new Map();
  containerType2.set(1, containerType2AllWasteGroups);
  containerType2.set(2, containerType2AllWasteGroups);
  containerType2.set(3, containerType2AllWasteGroups);
  containerType2.set(4, containerType2AllWasteGroups);
  containerType2.set(5, containerType2AllWasteGroups);
  containerType2.set(6, containerType2AllWasteGroups);

  let lines = a1.split(/\r?\n/);
  for (let i = 1; i < lines.length; i++) {
    let splittedLine = lines[i].split(/;/);
    for (let j = 1; j < splittedLine.length; j++) {
      map
        .get(1)
        .get(j)
        .set(splittedLine[0], Number.parseFloat(splittedLine[j].replaceAll(",", ".")));
    }
  }
  lines = a2.split(/\r?\n/);
  for (let i = 1; i < lines.length; i++) {
    let splittedLine = lines[i].split(/;/);
    for (let j = 1; j < splittedLine.length; j++) {
      map
        .get(2)
        .get(j)
        .set(splittedLine[0], Number.parseFloat(splittedLine[j].replaceAll(",", ".")));
    }
  }
  return map;
}
