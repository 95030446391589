import React, { Component } from "react";
class SettingButton extends Component {
  constructor(props) {
    super(props);
  }
  state = {};
  render() {
    let { children, ...rest } = this.props;
    return (
      <button {...rest} className="px-2 border-gray-800 border rounded-lg hover:bg-opacity-80 bg-gray-300">
        {children}
      </button>
    );
  }
}

export default SettingButton;
