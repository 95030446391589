import React, { Component } from "react";
class ProjectCard extends Component {
  state = {};
  render() {
    return (
      <div {...this.props} className="p-5 bg-white bg-opacity-60 hover:bg-opacity-75 rounded-md border-2 border-gray-400   shadow-lg hover:cursor-pointer">
        {this.props.children}
      </div>
    );
  }
}

export default ProjectCard;
