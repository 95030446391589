import { Activity, NuclideActivities, Value } from "../data/Activity";
import { WastePackage } from "../data/WastePackage";
import { CutPiece } from "../data/CutPiece";
import { Segment } from "../data/Segment";
import { Container } from "../data/Container";
import { faCommentsDollar } from "@fortawesome/free-solid-svg-icons";
const jschardet = require("jschardet");
export class Parser {
  static linesOffsetCutPiece = 1;
  static linesOffset = 12;
  static dateLine = 10;
  static unitLine = 11;
  static infoLine = 3;
  static nameCol = 2;
  static dateCol = 7;
  static massLine = 4;
  static massCol = 2;
  static cutOffLine = 5;
  static cutOffCol = 2;
  static sumActLineFromBottom = 4;
  static wpCPOffset = 3;



  static loadSegmentNewFormat = function (evt, callback) {
    try {

      let content = evt.target.result;
      let lines = content.split(/\r?\n/);
      let name = lines[0].split(/;/)[0];
      let date = lines[1].split(/;/)[0];
      //masse muss manuell berechnet werden



      let unit = lines[2].split(/;/)[1] === "Bq/kg" ? 'MASS' : 'SURFACE';

      let segment = new Segment(name, date + "      00:00:00", unit, 0, 0, null);

      let dates = lines[1].split(/;/);



      let datesAsMs = [];
      let actMap = new Map();

      for (let date of dates) {
        var parts = date.match(/(\d+)/g);
        if (parts === null || parts.length !== 3) {
          continue;
        }
        let d = new Date(parts[2], parts[1] - 1, parts[0]);
        let millis = d.getTime();
        datesAsMs.push(millis);
        let a = new Activity(millis, new Value(0, []));
        actMap.set(millis, a);
      }

      for (let x = 3; x < lines.length; x++) {
        let splittedLine = lines[x].split(/;/);

        let nuclide = splittedLine[0].trim();

        if (nuclide === "" || datesAsMs[0] === undefined) {
          //nur Angaben in Bq pro kg werden eingelesen
          continue;
        }
        var nucObj = new NuclideActivities(nuclide, Number.parseFloat(splittedLine[1].replaceAll(",", ".")));
        actMap.get(datesAsMs[0]).value.addNuclideActivity(nucObj);

        //ueber spalten gehen und gesamt aktivitaet auslesen
        actMap.get(datesAsMs[0]).value.sumAct = null
      }
      for (var value of actMap.values()) {
        segment.activities.push(value);
      }
      callback(segment);
    } catch (error) {
      callback(null);
    }
  }

  static loadSegment = function (evt, callback) {
    try {
      let content = evt.target.result;
      let lines = content.split(/\r?\n/);
      let name = lines[Parser.infoLine].split(/;/)[Parser.nameCol];
      let date = lines[Parser.infoLine].split(/;/)[Parser.dateCol].replaceAll('"', "");
      let mass = lines[Parser.massLine].split(/;/)[Parser.massCol];
      let sumAct = lines[Parser.massLine].split(/;/)[Parser.massCol];
      let cutoff = lines[Parser.cutOffLine].split(/;/)[Parser.cutOffCol];
      let segment = new Segment(name, date, "MASS", mass, cutoff, sumAct);
      let dates = lines[Parser.dateLine].split(/;/);
      let units = lines[Parser.unitLine].split(/;/);
      let datesAsMs = [];
      let actMap = new Map();
      for (let date of dates) {
        var parts = date.match(/(\d+)/g);
        if (parts === null || parts.length !== 3) {
          continue;
        }
        let d = new Date(parts[2], parts[1] - 1, parts[0]);
        let millis = d.getTime();
        datesAsMs.push(millis);
        let a = new Activity(millis, new Value(0, []));
        actMap.set(millis, a);
      }
      for (let x = Parser.linesOffset; x < lines.length; x++) {
        if (lines[x].trim() === ";") {
          //Ende der Nuklidliste
          break;
        }
        let splittedLine = lines[x].split(/;/);

        let nuclide = splittedLine[0].trim();

        for (let j = 1; j < splittedLine.length; j++) {
          if (units[j] !== "Bq/kg" || nuclide === "" || datesAsMs[j] === undefined) {
            //nur Angaben in Bq pro kg werden eingelesen
            continue;
          }
          var nucObj = new NuclideActivities(nuclide, Number.parseFloat(splittedLine[j].replaceAll(",", ".")));
          actMap.get(datesAsMs[j - 1]).value.addNuclideActivity(nucObj);
        }

        //ueber spalten gehen und gesamt aktivitaet auslesen
        let sumActSplitted = lines[lines.length - Parser.sumActLineFromBottom].split(/;/);
        for (let y = 1; y < sumActSplitted.length; y++) {
          if (units[y] !== "Bq/kg" || datesAsMs[y] === undefined) {
            //nur Angaben in Bq pro kg werden eingelesen
            continue;
          }
          actMap.get(datesAsMs[y - 1]).value.sumAct = Number.parseFloat(sumActSplitted[y].replaceAll(",", "."));
        }
      }
      for (var value of actMap.values()) {
        segment.activities.push(value);
      }
      callback(segment);
    } catch (error) {
      callback(null);
    }
  };
  static parseSegment(file, callback) {
    if (file.name.includes(".csv")) {
      //immer neuer reader weil sich das callback aendert

      this.getEncoding(file).then((encoding) => {
        let reader = new FileReader();
        reader.onload = (evt) => {
          try {
            let content = evt.target.result;
            let lines = content.split(/\r?\n/);
            let date = lines[1].split(/;/)[0];
            let datePattern = /^\d{2}\.\d{2}\.\d{4}$/;
            if (datePattern.test(date)) {
              Parser.loadSegmentNewFormat(evt, callback);
            } else {

              Parser.loadSegment(evt, callback)
            }
          } catch (error) {

            Parser.loadSegment(evt, callback);
          }

        };
        reader.readAsText(file, encoding);
      });
    } else {
      callback(null);
    }
  }
  static loadNewCutPieces = function (evt, callback) {
    try {
      let content = evt.target.result;
      let lines = content.split(/\r?\n/);
      let pieces = [];
      let header = lines[0].split(";");

      for (let i = Parser.linesOffsetCutPiece; i < lines.length; i++) {
        if (lines[i] === "") {
          continue;
        }
        let splittedLine = lines[i].split(/;/);
        let seg = [];
        for (let j = 2; j < splittedLine.length; j++) {
          let mass;
          if (splittedLine[j] === "100%") {
            // wenn das gesamte Gewicht eines Segmentes genutzt werden soll wird -1 übergeben!
            mass = -1;
          } else {
            mass = Number.parseFloat(splittedLine[j].replaceAll(",", "."));
          }

          if (mass === 0 || isNaN(mass)) {
            continue;
          }
          seg.push({ segment: header[j], mass: mass });
        }
        let cp = new CutPiece(splittedLine[1], splittedLine[0].split(","), null, seg);
        pieces.push(cp);
      }

      callback(pieces);
    } catch (error) {
      console.error(error);
      callback(null);
    }

  }

  static loadCutPieces = function (evt, callback) {
    try {
      let content = evt.target.result;
      let lines = content.split(/\r?\n/);
      let pieces = [];
      let header = lines[0].split(";");

      for (let i = Parser.linesOffsetCutPiece; i < lines.length; i++) {
        if (lines[i] === "") {
          continue;
        }
        let splittedLine = lines[i].split(/;/);
        let seg = [];
        for (let j = 3; j < splittedLine.length; j++) {
          let mass;
          if (splittedLine[j] === "100%") {
            // wenn das gesamte Gewicht eines Segmentes genutzt werden soll wird -1 übergeben!
            mass = -1;
          } else {
            mass = Number.parseFloat(splittedLine[j].replaceAll(",", "."));
          }

          if (mass === 0 || isNaN(mass)) {
            continue;
          }
          seg.push({ segment: header[j], mass: mass });
        }
        let cp = new CutPiece(splittedLine[1], splittedLine[0].split(","), Number.parseFloat(splittedLine[2]), seg);
        pieces.push(cp);
      }

      callback(pieces);
    } catch (error) {
      console.error(error);
      callback(null);
    }
  };
  static parseCutPieces(file, callback) {
    //immer neuer reader weil sich das callback aendert
    if (file.name.includes(".csv")) {
      this.getEncoding(file).then((encoding) => {
        let reader = new FileReader();
        reader.onload = (evt) => {
          try {
            //hier bin ich stehen geblieben 
            let content = evt.target.result;
            let lines = content.split(/\r?\n/);

            let header = lines[0].split(";");
            if (header[2].startswith("Schnittstueck-Oberflaeche")) {
              
              Parser.loadCutPieces(evt, callback);
            } else {
            
              Parser.loadNewCutPieces(evt, callback);
            }
          } catch (error) {
           
            Parser.loadCutPieces(evt, callback);
          }
        };
        reader.readAsText(file, encoding);
      });
    } else {
      callback(null);
    }
  }
static loadNewWastePAckages = function (evt, callback){
  try {
    
  } catch (error) {
    
  }
}

  static loadWastePackages = function (evt, callback) {
    try {
      let content = evt.target.result;
      let lines = content.split(/\r?\n/);
      let container = [];
      for (let i = 1; i < lines.length; i++) {
        let splittedLine = lines[i].split(/;/);
        if (splittedLine.length < 5) {
          continue;
        }
        let cutpieces = [];
        for (let j = 4; j < splittedLine.length; j++) {
          if (splittedLine[j] !== "") {
            cutpieces.push(splittedLine[j]);
          }
        }
        let isSafe = splittedLine[3].toLocaleLowerCase() === "ja";
        let c = new WastePackage(splittedLine[0], splittedLine[1], splittedLine[2], isSafe, cutpieces);
        container.push(c);
      }

      callback(container);
    } catch (error) {
      console.error(error);
      callback(null);
    }
  };
  static parseWastePackages(file, callback) {
    //immer neuer reader weil sich das callback aendert
    if (file.name.includes(".csv")) {
      this.getEncoding(file).then((encoding) => {
        let reader = new FileReader();
        reader.onload = (evt) => {
          Parser.loadWastePackages(evt, callback);
        };
        reader.readAsText(file, encoding);
      });
    } else {
      callback(null);
    }
  }

  static loadContainer = function (evt, callback) {
    let names = new Set();
    try {
      let content = evt.target.result;
      let lines = content.split(/\r?\n/);
      let container = [];
      for (let i = 1; i < lines.length; i++) {
        let splittedLine = lines[i].split(/;/);
        if (splittedLine.length < 4) {
          continue;
        }
        if (names.has(splittedLine[0])) {
          throw new Error("Container kommt mehrfach vor !!!!!");
        }
        let c = new Container(splittedLine[0], splittedLine[1], splittedLine[2], splittedLine[3], splittedLine[7] ? splittedLine[7] : null);
        container.push(c);
        names.add(splittedLine[0]);
      }

      callback(container);
    } catch (error) {
      console.error(error);
      callback(null);
    }
  };
  static parseContainer(file, callback) {
    //immer neuer reader weil sich das callback aendert
    if (file.name.includes(".csv")) {
      this.getEncoding(file).then((encoding) => {
        let reader = new FileReader();
        reader.onload = (evt) => {
          Parser.loadContainer(evt, callback);
        };
        reader.readAsText(file, encoding);
      });
    } else {
      callback(null);
    }
  }
  static getEncoding(file) {
    let reader = new FileReader();
    return new Promise((resolve) => {
      reader.onload = () => {
        let buffer = reader.result;
        let detectedBuffer = jschardet.detect(buffer);
        resolve(detectedBuffer.encoding);
      };
      reader.readAsBinaryString(file);
    });
  }
}
