import React, { Component } from "react";
class Expandable extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
  }
  state = { expanded: false };
  toggle() {
    this.setState({ expanded: !this.state.expanded });
  }
  render() {
    let { className } = this.props;
    return (
      <div className="flex flex-col w-full border border-gray-400 rounded-md">
        <div onClick={this.toggle} className="cursor-pointer rounded-lg overflow-hidden bg-gray-50 bg-opacity-50 hover:bg-gray-100 ">
          {this.props.text ? this.props.text : "Ausklappen"}
        </div>
        {this.state.expanded ? <div className={"overflow-x-auto " + className}>{this.props.children}</div> : null}
      </div>
    );
  }
}

export default Expandable;
