import React, { Component } from "react";
class SButton extends Component {
  state = {};
  render() {
    const { className, children, disabled, ...rest } = this.props;

    return (
      <button
        disabled={disabled}
        className={
          "border border-gray-400 rounded-lg px-2 overflow-hidden bg-gray-300 hover:bg-orange-200 hover:disabled:bg-white disabled:bg-white " + className
        }
        {...rest}
      >
        {children}
      </button>
    );
  }
}

export default SButton;
