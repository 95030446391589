import React, { Component } from "react";
class NavbarOption extends Component {
  constructor(props) {
    super(props);
  }
  state = {};
  render() {
    const { children, ...rest } = this.props;
    return <div className={"z-20"} {...rest}>{children}</div>;
  }
}

export default NavbarOption;
