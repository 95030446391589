export class Segment {
  constructor(name, date, unit, mass, cutoff) {
    this.name = name;
   
    let splitted = date.split(" ");
    
    let d = splitted[0];
    
    let t = splitted[6];
   
    let dSplitted = d.split(".");
    
    let newDate = dSplitted[2] + "-" + dSplitted[1] + "-" + dSplitted[0];
    
    this.creationTimestamp = newDate + "T" + t;
    
    if(mass !== 0){
      this.mass = Number.parseFloat(mass.replaceAll(",", "."));
    }else{
      this.mass =0;
    }
    this.unit = unit
    
    if(cutoff !== 0){
      this.cutoff = Number.parseFloat(cutoff.replaceAll(",", "."));
    }else{
      this.cutoff =0;
    }
   
    this.activities = [];
  }
}
