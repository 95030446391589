import React, { Component } from "react";
class SLabel extends Component {
  constructor(props) {
    super(props);
  }
  state = {};
  render() {
    const { className, ...rest } = this.props;
    return (
      <label className={"font-semibold " + className} {...rest}>
        {this.props.children}
      </label>
    );
  }
}

export default SLabel;
