import { validate } from "./Utility/ResponseConsumer";
var controller = new AbortController();
var tokenExpiredCallback;
export function login(username, password, callback) {
  controller = new AbortController();
  fetchData("/api/login", METHOD.POST, callback, { email: username, password: password });
}
export function getProjects(callback) {
  fetchData("/api/project/", METHOD.GET, callback);
}
export function getProject(id, callback) {

  fetchData("/api/project/" + id, METHOD.GET, callback);
}
export function addSegments(projectId, segments, callback) {
  fetchData("/api/project/" + projectId + "/segment", METHOD.POST, callback, segments);
}
export function addContainer(container, callback) {
  fetchData("/api/container", METHOD.POST, callback, container);
}
export function deleteContainer(id, callback) {
  fetchData("/api/container/" + id, METHOD.DELETE, callback);
}

export function deleteAllContainer(ids, callback) {
  fetchData("/api/container/all", METHOD.DELETE, callback, ids);
}
export function addCutPiece(projectId, cutpieces, callback) {
  fetchData("/api/project/" + projectId + "/cutpiece", METHOD.POST, callback, cutpieces);
}
export function getSegment(projectId, segmentId, callback) {
  fetchData("/api/project/" + projectId + "/segment/" + segmentId, METHOD.GET, callback);
}
export function getCo60Segments(projectId, callback) {
  fetchData("/api/project/" + projectId + "/segments/co60", METHOD.GET, callback);
}
export function createProject(name, desc, callback) {
  fetchData("/api/project/new", METHOD.POST, callback, { name: name, description: desc });
}
export function changeProjectOwner(projectId, id, callback){
  fetchData("/api/project/"+ projectId +"/owner/"+id, METHOD.PUT, callback);
}

export function setTokenExpiredCallback(callback) {
  tokenExpiredCallback = callback;
}
export function addWastePackage(projectId, wp, callback) {
  fetchData("/api/project/" + projectId + "/wastepackage", METHOD.POST, callback, wp);
}
export function logout(callback) {
  fetchData("/api/logout", METHOD.POST, callback);
}
export function getMyUser(callback) {
  fetchData("/api/me", METHOD.GET, callback);
}
export function setRefDate(projectId, timeInLong, callback) {
  fetchData("/api/project/" + projectId + "/refTimestamp", METHOD.POST, callback, timeInLong);
}
export function removeSegment(projectId, segmentId, callback) {
  fetchData("/api/project/" + projectId + "/segment/" + segmentId, METHOD.DELETE, callback);
}
export function removeSegments(projectId, segments, callback) {
  fetchData("/api/project/" + projectId + "/segment", METHOD.DELETE, callback, segments);
}
export function removeWastePackage(projectId, wpId, callback) {
  fetchData("/api/project/" + projectId + "/wastepackage/" + wpId, METHOD.DELETE, callback);
}
export function removeWastePackages(projectId, wps, callback) {
  fetchData("/api/project/" + projectId + "/wastepackage", METHOD.DELETE, callback, wps);
}
export function removeCutpiece(projectId, cpId, callback) {
  fetchData("/api/project/" + projectId + "/cutpiece/" + cpId, METHOD.DELETE, callback);
}
export function removeCutpieces(projectId, cps, callback) {
  fetchData("/api/project/" + projectId + "/cutpiece", METHOD.DELETE, callback, cps);
}
export function removeProject(projectId, callback) {
  fetchData("/api/project/" + projectId, METHOD.DELETE, callback);
}
export function getUser(callback) {
  fetchData("/api/user", METHOD.GET, callback);
}
//TODO dfsd
export function renameUser(userId, newUsername, callback){
  fetchData("/api/rename/" +  userId, METHOD.POST, callback,newUsername,CONTENT_TYPE.TEXT);
}
export function getContainer(callback) {
  fetchData("/api/container", METHOD.GET, callback);
}
export function getLimits(callback) {
  fetchData("/api/limits", METHOD.GET, callback );
}
export function getThermalLimits(callback) {
  fetchData("/api/limits/thermal", METHOD.GET, callback);
}
export function getCriticalityLimits(callback) {
  fetchData("/api/limits/criticality", METHOD.GET, callback);
}
export function updateMember(projectId, member, callback) {
  fetchData("/api/project/" + projectId + "/member", METHOD.PUT, callback, member);
}
export function getEnrichmentoptions(callback) {
  fetchData("/api/project/enrichmentoptions", METHOD.GET, callback);
}
export function changePassword(password, callback) {
  fetchData("/api/password", METHOD.POST, callback, password, CONTENT_TYPE.TEXT);
}
export function resetPassword(id, password, callback) {
  fetchData("/api/password/" + id, METHOD.POST, callback, password, CONTENT_TYPE.TEXT);
}

export function getTable10Nuclides(callback){
  fetchData("/api/Nuclid/table10", METHOD.GET, callback);
}
export function createUser(user, callback) {
  fetchData("/api/register", METHOD.POST, callback, user);
}
export function changeUserState(id, callback) {
  fetchData("/api/lock/" + id, METHOD.POST, callback);
}
export function updateSettings(projectId, settings, callback) {
  fetchData("/api/project/" + projectId + "/settings", METHOD.POST, callback, settings, CONTENT_TYPE.JSON);
}
export function updateName(projectId, name, callback) {
  fetchData("/api/project/" + projectId + "/name", METHOD.POST, callback, name, CONTENT_TYPE.JSON);
}
export function refreshAccesstoken(callback) {
  fetchData("/api/refresh", METHOD.POST, callback);
}
export function deleteUser(userId, callback){
  fetchData("/api/delete/" +userId, METHOD.DELETE, callback, CONTENT_TYPE.TEXT)
}

export function cutpieceToSegements(projectId, cps, callback) {
  fetchData("/api/project/" + projectId + "/cutpieces/segmentexport", METHOD.POST, callback, cps, CONTENT_TYPE.JSON);
}
export function wastepackageToSegments(projectId, wps, withoutHypen, callback) {
  fetchData("/api/project/" + projectId + "/wastepackages/segmentexport?hyphen=" + !withoutHypen, METHOD.POST, callback, wps, CONTENT_TYPE.JSON);
}
export function wastepackageToSegmentsZIP(projectId, wps, withoutHypen, callback) {
  fetchData("/api/project/" + projectId + "/wastepackages/segmentexport/selected?hyphen=" + !withoutHypen, METHOD.POST, callback, wps, CONTENT_TYPE.JSON);
}
export function exportResultForWastePackages(projectId, wps, cutoff, exportWithoutHyphen, callback) {
  fetchData(
    "/api/project/" + projectId + "/wastepackages/result?cutoff=" + cutoff + "&hyphen=" + !exportWithoutHyphen,
    METHOD.POST,
    callback,
    wps,
    CONTENT_TYPE.JSON,
  );
}
export function getBackups(callback) {
  fetchData("/api/backups", METHOD.GET, callback);
}
export function getBackup(name, callback) {
  fetchData("/api/backup/" + name, METHOD.GET, callback);
}
export function startBackup(callback) {
  fetchData("/api/backup", METHOD.POST, callback);
}
export function cancelBackup(callback) {
  fetchData("/api/backup/cancel", METHOD.POST, callback);
}
export function isBackupRunning(callback) {
  fetchData("/api/backup/running", METHOD.GET, callback);
}
export function updateProjectSettings(projectId, settings, callback) {
  fetchData("/api/project/" + projectId + "/projectsettings", METHOD.POST, callback, settings, CONTENT_TYPE.JSON);
}
export function restore(callback, multipartfile) {
  fetchData("/api/restore", METHOD.PUT, callback, multipartfile, CONTENT_TYPE.FORMDATA);
}
function fetchData(url, method, callback, body, contentType = CONTENT_TYPE.JSON) {
  let options = { signal: controller.signal };
  options.method = method;
  if (body) {
    if (contentType === CONTENT_TYPE.JSON) {
      options.body = JSON.stringify(body);
    } else {
      options.body = body;
    }
    options.headers = {};
    if (contentType !== CONTENT_TYPE.FORMDATA) {
      options.headers[HEADER.CONTENT_TYPE] = contentType;
    }
  }
  fetch(url, options)
    .then((x) => {
      let contentType = x.headers.get(HEADER.CONTENT_TYPE);
      contentType = contentType ? contentType.split(";")[0] : null;
      if (x.status === 200) {
        if (contentType === CONTENT_TYPE.JSON) {
          x.json()
            .then((json) => {
              callback(x.status, json);
            })
            .catch((error) => {
              console.error(error);
            });
        } else if (contentType === CONTENT_TYPE.TEXT) {
          x.text()
            .then((text) => {
              callback(x.status, text);
            })
            .catch((error) => {
              console.error(error);
            });
        } else {
          callback(x.status);
        }
      } else if (x.status === 401) {
        x.json().then((json) => {
          if (json.errorCode === 1) {
            refreshAccesstoken(
              validate(
                () => {
                  fetchData(url, method, callback, body, options.headers ? options.headers[HEADER.CONTENT_TYPE] : CONTENT_TYPE.JSON);
                },
                () => {
                  if (tokenExpiredCallback) {
                    controller.abort();
                    tokenExpiredCallback();
                    return;
                  }
                },
              ),
            );
          }
        });
      } else if (x.status === 403) {
        if (tokenExpiredCallback) {
          controller.abort();
          tokenExpiredCallback();
          return;
        }
      } else if (contentType === CONTENT_TYPE.JSON) {
        x.json().then((json) => callback(x.status, json));
      } else {
        x.text().then((text) => callback(x.status, text));
      }
    })
    .catch((error) => {
      console.error(error.message);
    });
}
const METHOD = {
  POST: "POST",
  GET: "GET",
  DELETE: "DELETE",
  PUT: "PUT",
};
const HEADER = {
  CONTENT_TYPE: "Content-Type",
};
const CONTENT_TYPE = {
  JSON: "application/json",
  TEXT: "text/plain",
  FORMDATA: "multipart/form-data",
};
