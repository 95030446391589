export class Activity {
  constructor(date, value) {
    this.date = date;
    this.value = value;
  }
}
export class Value {
  constructor(sumAct, nuclideActivities) {
    this.sumAct = sumAct;
    this.nuclideActivities = nuclideActivities;
  }
  addNuclideActivity(x) {
    this.nuclideActivities.push(x);
  }
}
export class NuclideActivities {
  constructor(nuclide, value) {
    this.nuclide = nuclide;
    this.value = value;
  }
}
