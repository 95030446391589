import React, { Component } from "react";
class LoadingFilter extends Component {
  state = {};
  render() {
    return (
      <div className="absolute z-50 flex items-center justify-center backdrop-blur-lg h-full w-full">
        <div className="bg-white py-2 flex flex-col items-center rounded-xl border-2 border-gray-400 shadow-lg bg-opacity-70 font-semibold">
          <p className=" px-5 text-lg">Daten werden verarbeitet</p>

          <div className="w-16">
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xlinkHref="http://www.w3.org/1999/xlink"
              viewBox="0 20 100 60"
              enableBackground="new 0 0 0 0"
              xmlSpace="preserve"
            >
              <path fill="gray" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" />
              </path>
            </svg>
          </div>
        </div>
      </div>
    );
  }
}

export default LoadingFilter;
