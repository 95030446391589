import React, { Component } from "react";
class Setting extends Component {
  constructor(props) {
    super(props);
  }
  state = {};
  render() {
    return (
      <div className="flex gap-2  py-2 items-center rounded-lg ">
        {this.props.children.map((child, idx) => (
          <div key={idx} className="grow max-w-[50%] min-w-fit">
            {child}
          </div>
        ))}
      </div>
    );
  }
}

export default Setting;
