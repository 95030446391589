import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import ActivityLimitsView from "./ActivityLimits/ActivityLimitView";
import AdminPanel from "./AdminPanel/AdminPanel";
import {
  createUser,
  getContainer,
  getCriticalityLimits,
  getEnrichmentoptions,
  getLimits,
  getMyUser,
  getThermalLimits,
  getUser,
  logout,
  setTokenExpiredCallback,
} from "./api";
import ConatinerView from "./Container/ContainerView";
import { AuthContext } from "./context/AuthContext";
import { ConfirmDialogContext } from "./context/ConfirmDialogContext";
import { ContainerContext } from "./context/ContainerContext";
import { EnrichmentContext } from "./context/EnrichmentContext";
import { LimitContext } from "./context/LimitContext";
import { LoadingContext } from "./context/LoadingContext";
import { NotificationContext } from "./context/NotificationContext";
import { UserContext } from "./context/UserContext";
import Login from "./Login";
import Menu from "./Menu/Menu";
import NavbarOption from "./Menu/NavbarOption";
import ProjectView from "./Project/ProjectView";
import UserSettings from "./User/UserSettings";
import LoadingFilter from "./Utility/LoadingFilter";
import { validate } from "./Utility/ResponseConsumer";
import ConfirmDialog from "./utilityComponents/ConfirmDialog";
import NotificationManager from "./utilityComponents/NotificationManager";
import AppConfig from "./constants";
const OPTIONS = [
  {
    text: "Projekte",
    component: <NotificationContext.Consumer>{(ctx) => <ProjectView notification={ctx}></ProjectView>}</NotificationContext.Consumer>,
    admin: false,
  },
  { text: "Grenzwerte", component: <ActivityLimitsView />, admin: false },
  {
    text: "Abfallbehälter",
    component: <NotificationContext.Consumer>{(ctx) => <ConatinerView confirmContext={ctx} pContext={ctx} notification={ctx}></ConatinerView>}</NotificationContext.Consumer>,
    admin: false,
  },
  {
    text: "Administration",
    component: <NotificationContext.Consumer>{(ctx) => <AdminPanel notification={ctx}></AdminPanel>}</NotificationContext.Consumer>,
    admin: true,
  },
  {
    text: <FontAwesomeIcon size="lg" icon={faUserCircle}></FontAwesomeIcon>,
    component: null,
    admin: false,
    children: [
      { text: "Einstellungen", component: <UserSettings /> },
      { text: <AuthContext.Consumer>{(ctx) => <p onClick={ctx.logout}>Logout</p>}</AuthContext.Consumer> },
    ],
  },
  {
    text: "Version " + AppConfig.version,
    admin: false,
  },
  
];

class Main extends Component {
  static contextType = NotificationContext;
  constructor() {
    super();
    this.state = {
      isLoading: false,
      isLoggedIn: false,
      isConfirmDialogShown: false,
      confirmDialogState: {},
      userContext: [],
      selectedOption: OPTIONS[0],
      container: [],
      limits: {},
    };
    this.tokenExpired = this.tokenExpired.bind(this);
    this.toggle = this.toggle.bind(this);
    this.logout = this.logout.bind(this);
    this.loginSuccessful = this.loginSuccessful.bind(this);
    this.getCurrentUser = this.getCurrentUser.bind(this);
    this.toggleConfirmDialog = this.toggleConfirmDialog.bind(this);
    this.fetchUserData = this.fetchUserData.bind(this);
    this.fetchContainer = this.fetchContainer.bind(this);
    this.fetchLimits = this.fetchLimits.bind(this);
    this.addContainer = this.addContainer.bind(this);
    this.fetchEnrichmentoptions = this.fetchEnrichmentoptions.bind(this);
    this.addUser = this.addUser.bind(this);
    this.toggleUserState = this.toggleUserState.bind(this);
    setTokenExpiredCallback(this.tokenExpired);
  }
  componentDidMount() {
    let isLoggedIn = localStorage.getItem("isLoggedIn") === "true";
    this.setState({ isLoggedIn: isLoggedIn });
    if (isLoggedIn) {
      this.initData();
    }
  }
  addContainer(container) {
    let current = this.state.container;
    container.forEach((c) => current.push(c));
    this.setState({ container: current });
  }
  fetchUserData() {
    getUser((status, json) => {
      if (status === 200) {
        this.setState({ userContext: json });
      }
    });
  }
  toggle() {
    this.setState({ isLoading: !this.state.isLoading });
  }
  logout() {
    logout(() => {
      localStorage.removeItem("isLoggedIn");
      this.setState({ isLoggedIn: false, currentUser: null, container: [], limits: {}, selectedOption: OPTIONS[0], enrichmentoptions: [] });
    });
  }

  tokenExpired() {
    localStorage.removeItem("isLoggedIn");
    this.setState({ isLoggedIn: false, currentUser: null, container: [], limits: {}, selectedOption: OPTIONS[0], isLoading: false });
    this.context.add("Sitzung abgelaufen!", true);
  }
  loginSuccessful() {
    this.setState({ isLoggedIn: true });
    localStorage.setItem("isLoggedIn", "true");
    this.initData();
  }
  fetchLimits() {
    getLimits(
      validate(
        (body) => {
          let tmp = this.state.limits;
          tmp.incident = body;
          this.setState({ limits: tmp });
        },
        (error) => {},
      ),
    );
    getThermalLimits(
      validate((body) => {
        let tmp = this.state.limits;
        tmp.thermal = body;
        this.setState({ limits: tmp });
      }),
    );
    getCriticalityLimits(
      validate((body) => {
        let tmp = this.state.limits;
        tmp.criticality = body;
        this.setState({ limits: tmp });
      }),
    );
  }
  fetchEnrichmentoptions() {
    getEnrichmentoptions(
      validate((body) => {
        this.setState({ enrichmentoptions: body });
      }),
    );
  }
  initData() {
    this.getCurrentUser();
    this.fetchUserData();
    this.fetchContainer();
    this.fetchLimits();
    this.fetchEnrichmentoptions();
  }
  getCurrentUser() {
    getMyUser((status, body) => {
      if (status === 200) {
        this.setState({ currentUser: body });
      }
    });
  }
  fetchContainer() {
    getContainer(
      validate(
        (body) => this.setState({ container: body }),
        () => {
          this.context.add("Fehler beim Laden der Enlagerbehälter!", true);
        },
      ),
    );
  }
  toggleConfirmDialog(text, confirm, cancel, confirmText, cancelText) {
    if (this.state.isConfirmDialogShown) {
      this.setState({ isConfirmDialogShown: false });
    } else {
      this.setState({
        isConfirmDialogShown: true,
        confirmDialogState: { text: text, cancel: cancel, confirm: confirm, confirmText: confirmText, cancelText: cancelText },
      });
    }
  }
  addUser(user) {
    createUser(
      user,
      validate(
        (body) => {
          this.state.userContext.push(body);
          this.setState({ userContext: this.state.userContext });
        },
        (error) => this.context.add(error.message, true),
      ),
    );
  }
  toggleUserState(id) {
    let usr = this.state.userContext.find((x) => x.id === id);
    usr.locked = !usr.locked;
    this.setState({ userContext: this.state.userContext });
  }
  render() {
    if (this.state.isLoggedIn !== true) {
      return <Login loginSuccessful={this.loginSuccessful}></Login>;
    }
    let options;
    if (this.state.currentUser) {
      options = OPTIONS.filter((x) => (this.state.currentUser.role === "ADMIN" ? true : !x.admin));
    } else {
      options = OPTIONS.filter((x) => !x.admin);
    }
    options = options.map((option, idx) => {
      let selected = this.state.selectedOption === option;
      let childSelected = option.children && option.children.find((child) => child === this.state.selectedOption);
     
      let isVersion = false
      if(typeof option.text === "string"){
        isVersion = option.text.includes("Version")
      }
      return (
        <NavbarOption
          className={ "" +(isVersion ? "text-lg " : " hover:text-gray-300 hover:cursor-pointer text-xl " )+ "font-semibold flex group " + (selected || childSelected ? "text-green-500" : null)}
          onClick={() => {
            if (option.component) {
              this.setState({ selectedOption: option });
            }
          }}
          key={idx}
        >
          {option.text}
          {option.children ? (
            <div className="flex flex-col items-center align-middle gap-1 ">
              <span className="absolute bg-main translate-y-8 group-hover:border invisible group-hover:visible shadow-md shadow-black overflow-hidden rounded-lg border-white transition-all max-h-0 duration-500 group-hover:max-h-screen">
                {option.children.map((child, idx) => {
                  let selected = this.state.selectedOption === child;
                  return (
                    <NavbarOption
                      className={"hover:bg-blue-300 text-base font-normal normal-case p-2  " + (selected ? " text-green-500" : "text-white")}
                      onClick={() => {
                        if (child.component) {
                          this.setState({ selectedOption: child });
                        }
                      }}
                      key={idx}
                    >
                      {child.text}
                    </NavbarOption>
                  );
                })}
              </span>
            </div>
          ) : null}
        </NavbarOption>
      );
    });
    return (
      <LoadingContext.Provider
        value={{
          isLoading: this.state.isLoading,
          toggle: this.toggle,
        }}
      >
        <AuthContext.Provider value={{ isLoggedIn: this.state.isLoggedIn, logout: this.logout, user: this.state.currentUser }}>
          <ConfirmDialogContext.Provider value={{ toggle: this.toggleConfirmDialog }}>
            <UserContext.Provider value={{toggle: this.toggleConfirmDialog, user: this.state.userContext, add: this.addUser, toggleUserState: this.toggleUserState }}>
              <ContainerContext.Provider value={{toggle: this.toggleConfirmDialog, container: this.state.container, add: this.addContainer, refresh: this.fetchContainer }}>
                <LimitContext.Provider value={this.state.limits}>
                  <EnrichmentContext.Provider value={this.state.enrichmentoptions}>
                    <>
                      {this.state.isLoading ? <LoadingFilter></LoadingFilter> : null}
                      {this.state.isConfirmDialogShown ? (
                        <ConfirmDialog
                          text={this.state.confirmDialogState.text}
                          confirm={this.state.confirmDialogState.confirm}
                          cancel={this.state.confirmDialogState.cancel}
                          confirmText={this.state.confirmDialogState.confirmText}
                          cancelText={this.state.confirmDialogState.cancelText}
                          new={this.state.confirmDialogState.new}
                        ></ConfirmDialog>
                      ) : null}
                      <NotificationManager>
                        <Menu selected={this.state.selectedOption} options={options}></Menu>
                      </NotificationManager>
                    </>
                  </EnrichmentContext.Provider>
                </LimitContext.Provider>
              </ContainerContext.Provider>
            </UserContext.Provider>
          </ConfirmDialogContext.Provider>
        </AuthContext.Provider>
      </LoadingContext.Provider>
    );
  }
}

export default Main;
