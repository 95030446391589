import React, { Component } from "react";
import "./table.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowRotateLeft, faArrowUp, faListCheck, faSearch, faX, faSquareCheck, faEdit, faPencil } from "@fortawesome/free-solid-svg-icons";
import SButton from "../styledComponents/SButton";
import SCheckbox from "../styledComponents/SCheckbox";

class Table extends Component {
  constructor(props) {
    super(props);
    this.setCompareBy = this.setCompareBy.bind(this);
    this.toggleSelect = this.toggleSelect.bind(this);
    this.isSelected = this.isSelected.bind(this);
    this.onEdit= this.onEdit.bind(this)
  }
  state = { selected: [], filter: "", compareBy: { idx: -1, compare: null, asc: true } };
  setCompareBy(idx, compare, asc) {
    this.setState({ compareBy: { idx: idx, compare: compare, asc: asc } });
  }
  toggleSelect(row) {
    
    let tmp = [];
    if (this.state.selected.includes(row)) {
     
      tmp = this.state.selected.filter((x) => row !== x);
    } else {
      tmp = [];
      if (this.state.selected.length === 0){
        tmp.push(row)
      }else{
        let istVorhanden = false
        let index = 0
        for (let r of this.state.selected) {
          if (row[0].props === undefined){
            if (row[0] === r[0]){
              istVorhanden = true
              index = this.state.selected.indexOf(r)
            }
          }
          else{
            if ( r[0].props.children === row[0].props.children ){
              istVorhanden = true
              index = this.state.selected.indexOf(r)
            }
          }
      }
      if (istVorhanden){
        let tmp2 = []
        for (let i = 0; i< this.state.selected.length; i++) {
          if(i !== index){
            tmp2.push(this.state.selected[i])
          }
        }
        tmp = tmp2
      }else {
        tmp = this.state.selected
        tmp.push(row)
      }}
      
          
    }
    this.setState({ selected: tmp });
  }
  isSelected(row) {
    
    let { rows } = this.props;
    
    if (rows === undefined) {
      return false;
    }
    
    
    for (let r of this.state.selected) {
      if ( row[0].props === undefined){
        if (row[0] === r[0]){
          return true
        }
      }
      else if (r[0].props.children === row[0].props.children ){
        return true
      }
    
    }
    return false;
  }
  compareRows(a, b) {
    let cells = a.length;
    for (let i = 0; i < cells; i++) {
      if (typeof a[i] === "object" && a[i] !== null) {
        if (a[i].props && a[i].props.children) {
          if (a[i].props.children !== b[i].props.children) {
            return false;
          }
        }
        continue;
      }
      if (a[i] !== b[i]) {
        return false;
      }
    }
    return true;
  }
  componentDidUpdate(prevProps) {
    //check if selected is still in project
    
    /*if (this.props.rows === prevProps.rows) {
      return;
    }

    let stillSelected = [];
    let { rows } = this.props;
    if (rows === undefined) {
      return;
    }
    this.state.selected.forEach((x) => {
      for (let row of rows) {
        if (this.compareRows(row, x)) {
          stillSelected.push(x);
          break;
        }
      }
    });
    this.setState({ selected: stillSelected });*/

  }

  onEdit(row){
    this.props.EditAbfallsgebinde(row);
  }
  render() {
    let { rows, header } = this.props;
    
    let copy;
    if (rows === undefined) {
      
      copy = [];
    } else {
      
      copy = [...rows];
    }
    if (header === undefined) {
      header = [];
    }
    if (this.state.filter !== "") {
      let filteredRows = [];
      //filter all header
      header.forEach((h, idx) => {
        //if header is object
        if (typeof h === "object" && h !== null) {
          //if header got filter method
          if (h.filter) {
            //actually filter of cell
            for (let row of copy) {
              if (row[idx] && h.filter(this.state.filter, row[idx])) {
                if (!filteredRows.includes(row)) {
                  filteredRows.push(row);
                }
              }
            }
          }
        }
      });
      copy = filteredRows;
    }
    if (this.state.compareBy.idx !== -1) {
      copy.sort((a, b) =>
        this.state.compareBy.asc
          ? this.state.compareBy.compare(a[this.state.compareBy.idx], b[this.state.compareBy.idx])
          : -this.state.compareBy.compare(a[this.state.compareBy.idx], b[this.state.compareBy.idx]),
      );
    }
    return (
      <div className={"flex flex-col gap-2 " + this.props.className}>
        {this.props.disableSearch ? null : (
          <div className="flex justify-center gap-5 bg-white bg-opacity-50 w-fit self-center p-2 rounded-lg">
            <div className="border-2 rounded-lg h-fit border-gray-400">
              <input type="text" value={this.state.filter} className="rounded-l-lg p-2" onChange={(evt) => this.setState({ filter: evt.target.value })} />
              <span className="bg-gray-200 p-2 rounded-r-lg border-l border-gray-300">
                <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
              </span>
            </div>
            {!this.props.disableSelect ? (
              <div className="flex items-baseline self-center gap-1">
                <p> Anzahl ausgewählter Elemente: {this.state.selected.length}</p>
                <SButton title="Alle auswählen" onClick={() => this.setState({ selected: copy })}>
                  <FontAwesomeIcon icon={faListCheck} />
                </SButton>
                <SButton title="Auswahl entfernen" onClick={() => this.setState({ selected: [] })}>
                  <FontAwesomeIcon icon={faX} />
                </SButton>
                <SButton title="Sortierung entfernen" onClick={() => this.setState({ filter: "", compareBy: { idx: -1, compare: null, asc: true } })}>
                  <FontAwesomeIcon icon={faArrowRotateLeft} />
                </SButton>
                {this.props.options
                  ? this.props.options.map((option, idx) => {
                      return (
                        <SButton key={idx} onClick={() => (option.onClick ? option.onClick(this.state.selected) : null)}>
                          {option.label}
                        </SButton>
                      );
                    })
                  : null}
              </div>
            ) : null}
          </div>
        )}

        <table className="basic-t">
          <thead>
            <tr>
              {header.map((h, idx) => {
                if (typeof h === "object" && h !== null) {
                  return (
                    <th key={idx}>
                      <div className="flex gap-1 w-full items-baseline justify-center">
                        <p>{h.text}</p>
                        {h.compare ? (
                          <>
                            <FontAwesomeIcon
                              className={"hover:scale-125 " + (this.state.sortBy === header.key && this.state.asc === true ? " text-black" : "text-gray-400")}
                              onClick={() => this.setCompareBy(idx, h.compare, true)}
                              icon={faArrowDown}
                            ></FontAwesomeIcon>
                            <FontAwesomeIcon
                              className={"hover:scale-125 " + (this.state.sortBy === header.key && this.state.asc === false ? " text-black" : "text-gray-400")}
                              onClick={() => this.setCompareBy(idx, h.compare, false)}
                              icon={faArrowUp}
                            ></FontAwesomeIcon>
                          </>
                        ) : null}
                      </div>
                    </th>
                  );
                }
                
                return <th key={idx}>{h}</th>;
              })}
              {this.props.disableEdit? null: <th><FontAwesomeIcon icon={faEdit}></FontAwesomeIcon></th>}
              {this.props.disableSelect ? null : <th><FontAwesomeIcon icon={faSquareCheck}></FontAwesomeIcon></th>}
              
            </tr>
          </thead>
          <tbody>
            {copy.map((row, idx) => (
              <tr key={idx}>
                {row.map((cell, idx) => (
                  <td key={idx}>{cell}</td>
                ))}
                {this.props.disableEdit? null: <td><FontAwesomeIcon onClick={()=> this.onEdit(idx)} icon={faPencil}></FontAwesomeIcon></td>}
                {!this.props.disableSelect ? (
                  <td>
                    <SCheckbox onChange={() => this.toggleSelect(row)} checked={this.isSelected(row)} />
                  </td>
                ) : null}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default Table;
