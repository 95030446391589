import { getTable10Nuclides } from "../api";
import { validate } from "./ResponseConsumer";
const table10Nuclides = getTable10Nuclides(
  validate((body) => {
    return body
  }),
);
export function toExponential(number, digits = 2) {
  let string = number.toExponential(digits);
  if (string.length === 7) {
    string = string.substring(0, string.length - 1) + "0" + string[6];
  }
  return string;
}
const EXPORT_MUST_HAVE_NUCLIDES = ["H-3", "C-14", "Kr-85", "I-129", "Ra-226", "Pu-241", "Th-232", "U-236", "U-238", "Pu-239"];
export function calcSumExpo(container, cutoff = 0) {
  let keys = Object.keys(container.incidentValues);
  let sum = 0;
  for (let key of keys) {
    let val = container.incidentValues[key];
    if (val >= cutoff || EXPORT_MUST_HAVE_NUCLIDES.includes(key)) {
      sum += val;
    }
  }

  return sum;
}

export function specialCalcSumExpo(container, cutoff = 0, table10 = []) {
  let keys = Object.keys(container.incidentValues);
  let sum = 0;
  
  for (let key of keys) {
    let auschöpfung = container.incidentValues[key];
    if (auschöpfung >= cutoff || EXPORT_MUST_HAVE_NUCLIDES.includes(key)) {




      if (table10.includes(key)) {

        //aktivität des nuclides bekommen 
        let aktivität
        for (const element of container.activities.nuclideActivities) {
          if (element.nuclide === key) {
            aktivität = element.value;
            break;
          }
        }
      
        //limit berechnen 
        let limit = aktivität * auschöpfung

        
        limit = limit * 1000

        //neue ausschöpfung berechnen 
        auschöpfung = aktivität / limit
        
      }
      
      sum += auschöpfung

    }
  }

  return sum;
}

export function calcSumForTable10(nuklid, table10 = [], auschöpfung){
 
  if(table10.includes(nuklid.nuclide)){
    let aktivität = nuklid.value
    let limit = (aktivität/auschöpfung) * 0.0001;
    return aktivität/limit
  }else {
    return auschöpfung
  }
 
}

export function isTable10Nuklid(nuklid , table10 = []){
    
    return table10.includes(nuklid.nuclide)
}

export function specialCalcSumThermal(container, cutoff = 0, table10 = []) {
  let keys = Object.keys(container.thermalValues);
  let sum = 0;
  for (let key of keys) {
    let auschöpfung = container.thermalValues[key];
    if (auschöpfung >= cutoff || EXPORT_MUST_HAVE_NUCLIDES.includes(key)) {




      if (table10.includes(key)) {

        //aktivität des nuclides bekommen 
        let aktivität
        for (const element of container.activities.nuclideActivities) {
          if (element.nuclide === key) {
            aktivität = element.value;
            break;
          }
        }

        //limit berechnen 
        let limit = aktivität * auschöpfung
        limit = limit * 1000

        //neue ausschöpfung berechnen 
        auschöpfung = aktivität / limit
      }
      sum += auschöpfung

    }
  }
  return sum;
}


export function calcSumThermal(container, cutoff = 0) {

  let keys = Object.keys(container.thermalValues);
  let sum = 0;
  for (let key of keys) {
    let val = container.thermalValues[key];
    if (val >= cutoff || EXPORT_MUST_HAVE_NUCLIDES.includes(key)) {
      sum += val;
    }
  }
  return sum;
}


export function calcSumCriticality(container, cutoff = 0) {
  let keys = Object.keys(container.criticalityValues);
  let sum = 0;
  for (let key of keys) {
    let val = container.criticalityValues[key];
    if (val >= cutoff || EXPORT_MUST_HAVE_NUCLIDES.includes(key)) {
      sum += val;
    }
  }
  return sum;
}
const regex = /^(?=[\x21-\x7E]*[0-9])(?=[\x21-\x7E]*[A-Z])(?=[\x21-\x7E]*[a-z])(?=[\x21-\x7E]*[\x21-\x2F|\x3A-\x40|\x5B-\x60|\x7B-\x7E])[\x21-\x7E]{8,}$/;
const usernameRegex = /(?!.*[.\-_]{2,})^[a-zA-Z0-9.\-_]{3,24}$/;
const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
export function passwordValid(password) {
  return password.match(regex);
}
export function usernameValid(username) {
  return username.match(usernameRegex) || username.match(emailRegex);
}
export function getStringFilter() {
  return (filter, x) => x.toLocaleUpperCase().includes(filter.toLocaleUpperCase());
}
export function getStringArrayFilter() {
  return (filter, x) => {
    for (let y of x) {
      let match = y.toLocaleUpperCase().includes(filter.toLocaleUpperCase());
      if (match) {
        return true;
      }
    }
  };
}
export function getStringCompare() {
  return (a, b) => {
    return a.toString().localeCompare(b);
  };
}
export function getNumberCompare() {
  return (a, b) => {
    let c = parseFloat(a.replace(",", "."));
    let d = parseFloat(b.replace(",", "."));
    return c - d;
  };
}
